import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IActionEvent } from 'src/app/components';
import { ERole, IBusinessLine, ITribe, IUser } from 'src/app/models/user';
import { AlertService } from 'src/app/services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api/api.service';
import { UserService } from 'src/app/services/user/user.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  constructor (
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private alertService: AlertService,
    private userService: UserService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.api.getBusinessLines().subscribe((businessLines) => {
      this.businessLines = businessLines;
    });

    const f = this.userService.getSetting("userList_filters", true);
    if (f) {
      this.filterForm.patchValue(JSON.parse(f));
      this.filterForm.markAllAsTouched();
    }

    const p = this.userService.getSetting("userList_pageData", true);
    if (p) this.pageData = JSON.parse(p);

    this.getQueryParamFilters().then((editedFilters) => {
      if (editedFilters) return this.changePage(0);
      this.changePage();
    });
  }
  
  getQueryParamFilters() {
    return firstValueFrom(this.route.queryParams).then((params) => {
      if (params['filters']) {
        this.resetForm(false);
        try {
          this.filterForm.patchValue(JSON.parse(params['filters']));
        } catch (e) { 
          console.log("tried to patch value of filters form", params['filters']);
        }
        return true;
      } 
      return false;
    });
  }

  onActionClicked(action: IActionEvent) {
    switch (action.actionKey) {
      case 'toggleFilters': 
        this.showFilters = !action.status;
        break;
    }
  }

  //#region filters
  showFilters = true;
  filterForm = this.fb.group({
    email: [''],
    businessLine: [null as string | null],
    tribe: [{ value: null as string | null, disabled: true }],
    isActive: [ 0 as (boolean | 0)],
  }, {
    updateOn: 'submit',
  });

  businessLines: IBusinessLine[] = [];
  tribes: ITribe[] = [];

  onBuinessLineChange(value: number | null) {
    this.filterForm.controls.tribe.setValue(null);

    if (value) {
      this.filterForm.controls.tribe.enable();
      this.tribes = this.businessLines.find(b => b.id === value)?.tribes || [];
    } else {
      this.filterForm.controls.tribe.disable();
      this.filterForm.controls.tribe.setValue(null);
    }
  }

  resetForm(changePage = true) {
    this.filterForm.reset({
      email: '',
      businessLine: null,
      tribe: null,
      isActive: 0,
    });
    this.onBuinessLineChange(null);


    if (changePage) this.changePage(0);
    this.userService.deleteSetting("userList_filters", true);
  }

  //#endregion

  //#region table
  isLoading = true;
  pageData = {
    index: 0,
    size: 10,
    totalElements: 0,
    sort: null as Sort | null,
  }
  displayedColumns: string[] = [ 
    'isActive', 
    'email',
    'tribe',
    'role',
    'actions' 
  ];

  dataSource = new MatTableDataSource<IUser>([]);
  changePage(pageNumber?: number, pageSize?: number, sort?: Sort) {
    this.isLoading = true;

    if (pageSize != null) this.pageData.size = pageSize;
    if (pageNumber != null) this.pageData.index = pageNumber;
    if (sort) this.pageData.sort = sort;
    
    const fc = this.filterForm.value;
    const filters: any = {};
    
    if (fc.email) filters['email'] = fc.email;
    if (fc.businessLine) filters['businessLine'] = fc.businessLine;
    if (fc.tribe) filters['tribe'] = fc.tribe;
    if (fc.isActive !== 0) filters['isActive'] = fc.isActive;
    
    if (this.filterForm.touched) this.userService.saveSetting("userList_filters", JSON.stringify(this.filterForm.value), true);
    this.userService.saveSetting("userList_pageData", JSON.stringify(this.pageData), true);

    this.api.getPagedUsers(filters, this.pageData.index, this.pageData.size, this.pageData.sort).subscribe({
      next: (users) => {
        this.dataSource.data = users.content;
        this.pageData.totalElements = users.totalElements;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  deleteUserClicked(user: IUser) {
    this.alertService.showAlert({
      type: "warning",
      acceptText: "common.actions.delete",
      message: this.translateService.instant('users.alerts.deleteUser', { user: user.email }),
      onConfirm: () => {
        // TODO: call API to delete user

        this.alertService.dismissAlert();
      },
    });
  }

  addUserClicked() {
    this.router.navigate(['/backoffice/add-user']);
  }

  editUserClicked(user: IUser) {
    this.router.navigate(['/backoffice/user/', user.id]);
  }

  //#endregion
}

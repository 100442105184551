import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatMenuPanel } from '@angular/material/menu';
import { ActivatedRoute, EventType, Router } from '@angular/router';
import { ERole } from 'src/app/models/user';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserService } from 'src/app/services/user/user.service';

export interface IAction {
  key: string;
  icon: string;
  name: string;
  activeIcon?: string;
  activeName?: string;
  menuRef?: MatMenuPanel<any>;
}

export interface IBottomAction {
  key: string;
  side: "left" | "right";
  icon?: string;
  disabled?: boolean;
  name: string;
  style?: "primary" | "accent" | "warn";
}

export interface IActionEvent { 
  actionKey: string, 
  status: boolean 
};

interface IDefaultAction extends IAction { 
  onClick: (status: boolean) => void 
}

@Component({
  selector: 'app-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss']
})
export class PageContainerComponent implements OnInit {

  selectedRoute: string = '';
  showActions = false;
 
  @Input() pageTitleParts: string[] = [ "A page title", "And another" ];
  
  activeActions: string[] = [];

  @Input() actions: IAction[] = [];

  @Input() condensed = false;

  @Input() direction: "column" | "row" = "column";
  @Input() wrap: "wrap" | "nowrap" = "nowrap";

  @Input() headerContent: TemplateRef<any> | null = null;

  leftBottomActions: IBottomAction[] = [];
  rightBottomActions: IBottomAction[] = [];
  @Input() set bottomActions(action: IBottomAction[]) {
    this.leftBottomActions = [];
    this.rightBottomActions = [];
    
    if (!action) return;
    action.forEach(a => {
      if (a.side === "left") {
        this.leftBottomActions.push(a);
      } else {
        this.rightBottomActions.push(a);
      }
    });
  }

  defaultActions: Array<IDefaultAction> = [
    { key: "fullscreen", icon: "fullscreen.svg", name: "common.actions.goFullscreen", activeIcon: "fullscreen_exit.svg", activeName: "common.actions.disableFullscreen", onClick: (status) => {
      this.sharedService.isFullScreen = status;
      window.dispatchEvent(new Event('resize'));
      this.userService.saveSetting("sideBarOpen", status ? "true" : "");
    }},
  ];
  
  @Output() actionClicked = new EventEmitter<IActionEvent>();
  @Output() titleClick = new EventEmitter<number>();

  constructor(
    private activatedRoute: ActivatedRoute,
    public sharedService: SharedService,
    private userService: UserService,
    private location: Location,
  ) { } 

  ngOnInit(): void {
    this.sharedService.isFullScreen = this.userService.getSetting("sideBarOpen") === "true";
    if (this.sharedService.isFullScreen) {
      this.activeActions.push("fullscreen");
    }

    this.activatedRoute.data.subscribe(data => {
      if (data && (data["allowedRoles"] && !data["allowedRoles"].some((r: ERole) => this.userService.role == r))) {
        console.log("Unauthorized access to url " + this.activatedRoute.snapshot.url.join("/"));
        this.location.back();
      }
    });
  }

  emitTitleClick(i: number) {
    this.titleClick.emit(i);
  }

  toggleAction(action: IAction | IBottomAction) {
    if (action.hasOwnProperty("activeName")) {
      const index = this.activeActions.indexOf(action.key);
      if (index > -1) {
        this.activeActions = this.activeActions.filter(a => a !== action.key);;
        this.actionClicked.emit({ actionKey: action.key, status: false });
        return;
      }
      this.activeActions.push(action.key);
      this.actionClicked.emit({ actionKey: action.key, status: true });
      return;
    }

    this.actionClicked.emit({ actionKey: action.key, status: true });
  }

  toggleDefaultAction(action: IDefaultAction) {
    if (action.activeName) {
      const index = this.activeActions.indexOf(action.key);
      if (index > -1) {
        this.activeActions.splice(index, 1);
        action.onClick(false);
        return;
      } 

      this.activeActions.push(action.key);
      action.onClick(true);
      return;
    }

    action.onClick(true);
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-rating-view',
  templateUrl: './rating-view.component.html',
  styleUrls: ['./rating-view.component.scss']
})
export class RatingViewComponent {

  stars: ("full" | "half" | "empty")[] = Array(5).fill("empty");
  
  @Input() set rating(rating: number | null) {

    if (rating == null) {

      this.stars = [];
      return;
    }

    this.stars = Array(5).fill("empty");

    if (rating > 5) {
      rating = 5;
    }
    if (rating < 0) {
      rating = 0;
    }

    let fullStars = Math.floor(rating);
    for (let i = 0; i < fullStars; i++) {
      this.stars[i] = "full";
    }
    if (Math.round(rating - fullStars)) {
      this.stars[fullStars] = "half";
    }
  }

  @Input() ratingsNumber: number;

  @Input() showStars: boolean = true;
  @Input() showText: boolean = true;

  @Output() onStarCick = new EventEmitter<number>();
  clickedStar(index: number) {
    this.onStarCick.emit(index);
  }

}

<img [src]="image" (click)="openFullScreenImage()">

<ng-template #fullScreenImage>
  <div class="modal-body"> 
    <div class="toolbar">
      <mat-button-toggle-group [value]="zoomModeControl" aria-label="status">
        <mat-button-toggle value="in" (click)="clickedZoom('in')">
          <img src="assets/icons/zoom-in.svg">
        </mat-button-toggle>
        <mat-button-toggle value="out" (click)="clickedZoom('out')">
          <img src="assets/icons/zoom-out.svg">
        </mat-button-toggle>
      </mat-button-toggle-group>
      <button mat-button (click)="reset()">
        <img src="assets/icons/zoom-reset.svg">
      </button>
      <div class="divider"></div>
      <button mat-mini-fab color="secondary" class="close-button" (click)="dismissAlert()">
        <img class="white-icon" src="assets/icons/close.svg">
      </button>
    </div>
    <img [src]="image" #imageElement [class.zoomed]="scale !== 1" (dragstart)="$event.preventDefault()">
  </div>
</ng-template>
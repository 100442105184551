import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';

import * as PAGES from './pages/index';
import { ERole } from './models/user';


const canDeactivateDefaultFn = function (component: { onNavigationOut: CanDeactivateFn<any> } , currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) {
  if (nextState.url.includes("error")) return true;
  return component.onNavigationOut(component, currentRoute, currentState, nextState);
}

export const ROUTES: Routes = [
  {
    path: 'error/:code',
    component: PAGES.ErrorComponent,
    data: { hidden: true, hideSideMenu: true },
  },
  {
    path: 'login',
    component: PAGES.LoginComponent,
    data: { hidden: true, hideSideMenu: true },
  },
  {
    path: 'login/:logout',
    component: PAGES.LoginComponent,
    data: { hidden: true, hideSideMenu: true },
  },
  {
    path: 'home',
    component: PAGES.HomeComponent,
    data: { allowedRoles: [ ERole.Admin, ERole.CS, ERole.TechSourcing, ERole.Tribe ] }
  },
  {
    path: 'procedures',
    component: PAGES.ProceduresComponent,
    data: { }
  },
  {
    path: 'projectRequests',
    children: [
      {
        path: 'request-wizard',
        component: PAGES.RequestWizardComponent,
        canDeactivate: [canDeactivateDefaultFn],   
      },
      {
        path: 'requests',
        component: PAGES.RequestsListComponent,
      },
      {
        path: 'request-summary/:id',
        component: PAGES.RequestSummaryComponent,
        data: { hidden: true },
      },
    ]
  },
  {
    path: 'projectsAndSuppliers',
    children: [
      {
        path: 'suppliers',
        component: PAGES.SuppliersListComponent,
      },
      {
        path: 'supplier-panel/:id',
        component: PAGES.SupplierPanelComponent, 
        data: { hidden: true },
      },
      {
        path: 'project-wizard',
        component: PAGES.ProjectWizardComponent,
        canDeactivate: [canDeactivateDefaultFn],   
        data: { hidden: true },
      },
      {
        path: 'project-wizard/:id',
        component: PAGES.ProjectWizardComponent,
        canDeactivate: [canDeactivateDefaultFn],   
        data: { hidden: true },
      },
      {
        path: 'project-edit/:id',
        component: PAGES.ProjectEditComponent,
        canDeactivate: [canDeactivateDefaultFn],   
        data: { hidden: true },
      },
      {
        path: 'project-panel/:id',
        component: PAGES.ProjectPanelComponent,
        data: { hidden: true },
      },
      {
        path: 'add-rating/:id',
        canDeactivate: [canDeactivateDefaultFn],   
        component: PAGES.RatingComponent,
        data: { hidden: true },
      },
      {
        path: 'projects',
        component: PAGES.ProjectsListComponent,
      }
    ],
  },
  {
    path: 'backoffice',
    children: [
      {
        path: 'manage-users',
        component: PAGES.UsersListComponent,
        data: { allowedRoles: [ ERole.Admin ], }
      },
      {
        path: 'user/:id',
        component: PAGES.UserEditComponent,
        canDeactivate: [canDeactivateDefaultFn],   
        data: { hidden: true, allowedRoles: [ ERole.Admin ] },
      },
      {
        path: 'add-user',
        component: PAGES.UserEditComponent,
        canDeactivate: [canDeactivateDefaultFn],   
        data: { hidden: true, allowedRoles: [ ERole.Admin ] },
      },
      {
        path: 'manage-suppliers',
        component: PAGES.SuppliersListComponent,
        data: { allowedRoles: [ ERole.Admin, ERole.CS, ERole.TechSourcing ] }
      },
      {
        path: 'supplier/:id',
        component: PAGES.SupplierEditComponent,
        canDeactivate: [canDeactivateDefaultFn],   
        data: { hidden: true, allowedRoles: [ ERole.Admin, ERole.CS, ERole.TechSourcing ] },
      },
      {
        path: 'supplier-panel/:id',
        component: PAGES.SupplierPanelComponent, 
        data: { hidden: true, allowedRoles: [ ERole.Admin, ERole.CS, ERole.TechSourcing ] },
      },
      {
        path: 'supplier-panel/:id/edit',
        component: PAGES.SupplierEditComponent,
        canDeactivate: [canDeactivateDefaultFn],   
        data: { hidden: true, allowedRoles: [ ERole.Admin, ERole.CS, ERole.TechSourcing ] },
      },
      {
        path: 'add-supplier',
        component: PAGES.SupplierEditComponent,  
        canDeactivate: [canDeactivateDefaultFn],   
        data: { hidden: true, allowedRoles: [ ERole.Admin, ERole.CS, ERole.TechSourcing ] },
      }
    ],
  },
  {
    path: 'dashboard',
    component: PAGES.DashboardComponent,
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
    data: { hidden: true },
  }
]; 

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, {
    useHash: true,
    onSameUrlNavigation: 'ignore',

  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


import { getNumberOfCurrencyDigits } from "@angular/common";
import { ISODateString } from "../types/ISODateString";
import { IPreferredSuppliers, IServiceCatalog, ISupplier } from "./supplier";
import { IUser } from "./user";

export enum ERequestStatus {
  "In progress" = "IPR",
  "Canceled" = "CAN",
  "Closed" = "CLS",
}
 

export interface IRequest {
  id: number;
  title: string;
  description: string;
  status: ERequestStatus;
  catalogId: number;
  catalog: string;
  jobId: number;
  job: string;
  creationDate: ISODateString;
  startDate: ISODateString;
  endDate: ISODateString;
  serviceTypeId: number,
  serviceType: string,
  seniorityId: number;
  seniority: string;
  rate: number;
  projectTypeId: number;
  projectType: string;
  user: IUser;
  businessLineId: number;
  businessLine: string;
  tribeId: number;
  tribe: string;
  leadId: number;
  lead: string;
  notes: string;
  suppliers: IPreferredSuppliers[];
}

export interface IEditRequest {
  id?: number,
  title: string,
  serviceTypeId: number,
  description: string,
  catalogId: number,
  jobId: number,
  startDate: ISODateString,
  endDate: ISODateString,
  seniorityId: number,
  rate: number,
  projectTypeId: number,
  tribeId: number,
  notes: string,
  suppliers: IPreferredSuppliers[]
}
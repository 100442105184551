import { Component, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { IAction, IActionEvent } from '../page-container/page-container.component';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  animations: [
    trigger('panelStateHorizontal', [
      state('open', style({
        width: '*',
        "flex-grow": '*',
        opacity: 1,
      })),
      state('closed', style({
        "flex-grow": 0,
        width: "0px",
      })),
      transition('open <=> closed', [
        animate('0.3s ease-in-out')
      ]),
    ]),
    trigger('panelStateVertical', [
      state('open', style({
        height: '*',
        "flex-grow": '*',
        opacity: 1,
      })),
      state('closed', style({
        "flex-grow": 0,
        height: "0px",
      })),
      transition('open <=> closed', [
        animate('0.3s ease-in-out')
      ]),
    ])
  ],
})
export class PanelComponent {

  @HostBinding('@panelStateVertical') get getToggleDrawerVertical(): string {
    return !this.open && this.openDirection === 'vertical' ?  'closed' : 'open';
  }

  @HostBinding('@panelStateHorizontal') get getToggleDrawerHorizontal(): string {
    return !this.open && this.openDirection === 'horizontal' ?  'closed' : 'open';
  }

  @Input() title: string;

  @Input() isLoading: boolean = false;

  @Input() style: "default" | "secondary" = "default";

  @Input() open: boolean = true;

  @Input() openDirection: 'horizontal' | 'vertical' = 'vertical';

}

<app-page-container
  [pageTitleParts]="[ 'request-wizard.title' ]"
  [bottomActions]="contextActions"
  [headerContent]="stepsTemplate"
  (actionClicked)="onActionClicked($event)"
>
  <mat-stepper [linear]="false" [(selectedIndex)]="selectedIndex">
    <mat-step>
      <app-panel [style]="'default'">
        <span class="step-description">{{ 'request-wizard.step1.description' | translate }}</span>
        <form [formGroup]="step1Form">
          <span *ngIf="step1Form.touched && !step1Form.valid" class="error-label full-width mat-mdc-form-field-error mat-mdc-form-field-bottom-align">{{ "request-wizard.errors.serviceTypeRequired" | translate }}</span>
          <div class="mat-form-field full-width">
            <mat-button-toggle-group class="service-type-button-container" formControlName="serviceType" aria-label="serviceType" (change)="serviceTypeChanged()">
              <mat-button-toggle [value]="'shared'">
                <div class="icon-container">
                  <img src="/assets/icons/people.svg" alt="shared">
                  <h4>Shared</h4>
                </div>
                <div class="divider"></div>
                <ul>
                  <li>
                    {{ "request-wizard.step1.sharedDescription.description1" | translate }}
                  </li>
                  <li>
                    {{ "request-wizard.step1.sharedDescription.description2" | translate }}
                  </li>
                  <li>
                    {{ "request-wizard.step1.sharedDescription.description3" | translate }}
                  </li>
                </ul>
              </mat-button-toggle>
              <mat-button-toggle [value]="'specific'">
                <div class="icon-container">
                  <img src="/assets/icons/person.svg" alt="specific">
                  <h4>Specific</h4>
                </div>
                <div class="divider"></div>
                <ul>
                  <li>
                    {{ "request-wizard.step1.specificDescription.description1" | translate }}
                  </li>
                  <li>
                    {{ "request-wizard.step1.specificDescription.description2" | translate }}
                  </li>
                  <li>
                    {{ "request-wizard.step1.specificDescription.description3" | translate }}
                  </li>                   
                </ul>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </form>
      </app-panel>
    </mat-step>
    <mat-step>
      <app-panel [style]="'default'">
        <span class="step-description">{{ 'request-wizard.step2.description' | translate }}</span>
        <form [formGroup]="step2Form">
          <mat-form-field class="span-4">
            <mat-label>{{ "request.fields.title" | translate }}</mat-label>
            <input matInput formControlName="title" [placeholder]="'request.fields.title' | translate">
            <mat-error>{{ getFieldError('title') | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ "request.fields.startDate" | translate }}</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="startDate">
            <mat-hint>{{ internationalizationService.getDateFormatString() }}</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker1">
              <mat-icon matDatepickerToggleIcon>
                <img src="/assets/icons/calendar.svg" alt="calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker1 [calendarHeaderComponent]="datepickerHeaderComponent">
            </mat-datepicker>
            <mat-error>{{ getFieldError('startDate') | translate }}</mat-error>
          </mat-form-field>
      
          <mat-form-field>
            <mat-label>{{ "request.fields.endDate" | translate }}</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="endDate" [min]="step2Form.controls.startDate.value">
            <mat-hint>{{ internationalizationService.getDateFormatString() }}</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker2">
              <mat-icon matDatepickerToggleIcon>
                <img src="/assets/icons/calendar.svg" alt="calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker2 [calendarHeaderComponent]="datepickerHeaderComponent"></mat-datepicker>
            <mat-error>{{ getFieldError('endDate') | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>{{ "request.fields.tribe" | translate }}</mat-label>
            <mat-select formControlName="tribeId" [placeholder]="'request.fields.tribe' | translate">
              <mat-option [value]="null">
                {{ "common.labels.empty" | translate }}
              </mat-option>
              <mat-option *ngFor="let tribe of tribes" [value]="tribe.tribeId">
                {{ tribe.businessLine }} - {{ tribe.tribe }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getFieldError('tribeId') | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>{{ "request.fields.catalog" | translate }}</mat-label>
            <mat-select formControlName="catalogId" [placeholder]="'request.fields.catalog' | translate" (opened)="catalogsFilter.value = ''" >
              <div class="select-search">
                <mat-form-field>
                  <mat-label>{{ "common.labels.search" | translate }}</mat-label>
                  <input matInput [placeholder]="'common.labels.search' | translate" #catalogsFilter>
                </mat-form-field>
              </div>
              <mat-option [value]="null">
                {{ "common.labels.empty" | translate }}
              </mat-option>
              <mat-option *ngFor="let catalog of catalogs" [value]="catalog.id" [attr.data-hidden]="!catalog.value.toLowerCase().includes(catalogsFilter.value.toLowerCase())" >
                {{ catalog.value }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getFieldError('catalogId') | translate }}</mat-error>
          </mat-form-field>
          
          <mat-form-field class="full-width">
            <mat-label>{{ "request.fields.job" | translate }}</mat-label>
            <mat-select formControlName="jobId" [placeholder]="'request.fields.job' | translate" (opened)="jobsFilter.value = ''" >
              <div class="select-search">
                <mat-form-field>
                  <mat-label>{{ "common.labels.search" | translate }}</mat-label>
                  <input matInput [placeholder]="'common.labels.search' | translate" #jobsFilter>
                </mat-form-field>
              </div>
              <mat-option [value]="null">
                {{ "common.labels.empty" | translate }}
              </mat-option>
              <mat-option *ngFor="let job of jobs" [value]="job.id" [attr.data-hidden]="!job.value.toLowerCase().includes(jobsFilter.value.toLowerCase())" >
                {{ job.value }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getFieldError('jobId') | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>{{ "request.fields.description" | translate }}</mat-label>
            <textarea matInput formControlName="description" placeholder="{{ 'request.fields.description' | translate }}"></textarea>
            <mat-error>{{ getFieldError('description') | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ "request.fields.seniority" | translate }}</mat-label>
            <mat-select #seniorityField formControlName="seniorityId" [placeholder]="'request.fields.seniority' | translate">
              <mat-select-trigger >
                {{ getSeniorityFromId(seniorityField.value) }}
              </mat-select-trigger>
              <mat-option [value]="null">
                {{ "common.labels.empty" | translate }}
              </mat-option>
              <mat-option *ngFor="let seniority of seniorities" [value]="seniority.id">
                {{ seniority.value }}
                <div class="value-explanation">
                  {{ seniority.valueExplanation }}
                </div>
              </mat-option>
            </mat-select>
            <mat-error>{{ getFieldError('seniorityId') | translate }}</mat-error>
          </mat-form-field>
        
          <mat-form-field>
            <mat-label>{{ "request.fields.rate" | translate }}</mat-label>
            <input matInput (keydown)="onRateKeyDown($event)" formControlName="rate" type="number" placeholder="0.00">
            <mat-error>{{ getFieldError('rate') | translate }}</mat-error>
          </mat-form-field>
          
          <mat-form-field>
            <mat-label>{{ "request.fields.projectType" | translate }}</mat-label>
            <mat-select formControlName="projectTypeId" [placeholder]="'request.fields.projectType' | translate">
              <mat-option [value]="null">
                {{ "common.labels.empty" | translate }}
              </mat-option>
              <mat-option *ngFor="let projectType of projectTypes" [value]="projectType.id">
                {{ projectType.value }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getFieldError('projectTypeId') | translate }}</mat-error>
          </mat-form-field>
        </form>
      </app-panel>
    </mat-step>
    <mat-step>
      <app-accordion-panel [style]="'default'" [title]="'request-wizard.step3.form-summary' | translate">
        <div class="summary">
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "request.fields.title" | translate }}
            </div>
            <div class="summary-item-value">
              {{ step2Form.controls.title.value || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "request.fields.startDate" | translate }}
            </div>
            <div class="summary-item-value">
              {{ (step2Form.controls.startDate.value | date: internationalizationService.getDateFormatPipeString() ) || "-"  }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "request.fields.endDate" | translate }}
            </div>
            <div class="summary-item-value">
              {{ (step2Form.controls.endDate.value | date: internationalizationService.getDateFormatPipeString() ) || "-"  }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "request.fields.tribe" | translate }}
            </div>
            <div class="summary-item-value">
              {{ getTribeFromId(step2Form.controls.tribeId.value) || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "request.fields.catalog" | translate }}
            </div>
            <div class="summary-item-value">
              {{ getCatalogFromId(step2Form.controls.catalogId.value) }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "request.fields.job" | translate }}
            </div>
            <div class="summary-item-value">
              {{ getJobFromId(step2Form.controls.jobId.value) }}
            </div>
          </div>
          <div class="summary-item column">
            <div class="summary-item-label">
              {{ "request.fields.description" | translate }}
            </div>
            <div class="summary-item-value" style="white-space: pre-warp; padding-bottom: 5px;">
              {{ step2Form.controls.description.value || "-"  }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "request.fields.seniority" | translate }}
            </div>
            <div class="summary-item-value">
              {{ getSeniorityFromId(step2Form.controls.seniorityId.value) || "-"  }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "request.fields.rate" | translate }}
            </div>
            <div class="summary-item-value">
              {{ step2Form.controls.rate.value || "-"  }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "request.fields.projectType" | translate }}
            </div>
            <div class="summary-item-value">
              {{ getProjectFromId(step2Form.value.projectTypeId) || "-"  }}
            </div>
          </div>
        </div>
      </app-accordion-panel>

      <ng-container [ngSwitch]="step1Form.controls.serviceType.value">
        <ng-container *ngSwitchCase="'shared'">
          <ng-container *ngTemplateOutlet="supplierListTemplate; context: { suppliers: getSuppliersList('IntraGroup'), title: 'IntraGroup', name: 'selected-supplier' }"></ng-container>
          <ng-container *ngTemplateOutlet="supplierListTemplate; context: { suppliers: getSuppliersList('SSI'), title: 'SSI', open: false, name: 'selected-supplier' }"></ng-container>
          <ng-container *ngTemplateOutlet="localSupplierListTemplate; context: { suppliers: getSuppliersList('Locals'), title: 'Locals', open: isSuppliersDrawerOpen, name: 'selected-supplier' }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'specific'">
          <app-accordion-panel [style]="'default'" [(open)]="isSuppliersDrawerOpen" title="{{ 'suppliers.title' | translate }}">
            <span class="step-description">{{ 'request-wizard.step3.description' | translate }}</span>

            <form [formGroup]="step3Form">
              <span *ngIf="step3Form.touched && !step3Form.valid" class="error-label full-width mat-mdc-form-field-error mat-mdc-form-field-bottom-align">{{ "request-wizard.errors.noSuppliersSelected" | translate }}</span>
              <mat-form-field class="full-width">
                <mat-label>{{ "request.fields.supplier" | translate }}</mat-label>
                <mat-select formControlName="selectedSupplierId" [placeholder]="'request.fields.supplier' | translate">
                  <div class="select-search">
                    <mat-form-field>
                      <mat-label>{{ "common.labels.search" | translate }}</mat-label>
                      <input matInput [placeholder]="'common.labels.search' | translate" #supplierFilter>
                    </mat-form-field>
                  </div>
                  <mat-option *ngFor="let supplier of fullSuppliersList" [value]="supplier.id" [attr.data-hidden]="!supplier.name.toLowerCase().includes(supplierFilter.value.toLowerCase())">
                    {{ supplier.name }}
                  </mat-option>
                </mat-select>
                <mat-error>{{ getFieldError('projectTypeId') | translate }}</mat-error>
              </mat-form-field>
            </form>
           
          </app-accordion-panel>
        </ng-container>
      </ng-container>
      
    </mat-step>
  </mat-stepper>
</app-page-container>

<ng-template #stepsTemplate >
  <app-stepper-header [steps]="steps" [selectedStep]="selectedIndex" (selectedStepChange)="setStep($event)"></app-stepper-header>
</ng-template>

<ng-template  #supplierListTemplate let-suppliers="suppliers" let-title="title" let-name="name" let-open="open">
  <app-accordion-panel [style]="'default'" [open]="open" title="{{ title }} ({{ 'common.labels.found' | pluralTranslate:suppliers.length | translate: { items: suppliers.length } }})">
    <div class="suppliers-list">
      <div *ngFor="let supplier of suppliers" class="supplier-list-item">
        <div>
          {{ supplier.name }}
        </div>
        <div>
          <app-rating-view [rating]="supplier.rating" [ratingsNumber]="supplier.ratingsNumber"></app-rating-view>
        </div>
      </div>
      <div *ngIf="suppliers.length === 0" class="no-data">
        {{ "common.labels.noData" | translate }}
      </div>
    </div>
  </app-accordion-panel>
</ng-template>

<ng-template #localSupplierListTemplate  let-suppliers="suppliers" let-title="title" let-name="name" let-open="open">
  <app-accordion-panel [style]="'default'" [open]="open" title="{{ title }} ({{ 'common.labels.found' | pluralTranslate:suppliers.length | translate: { items: suppliers.length } }})">
    <span class="step-description">{{ 'request-wizard.step3.description' | translate }}</span>
    
    <span *ngIf="step3Form.touched && !step3Form.valid" class="error-label full-width mat-mdc-form-field-error mat-mdc-form-field-bottom-align">{{ "request-wizard.errors.notEnoughSuppliersSelected" | translate: { min: minSuppliers } }}</span>
    <div *ngIf="selectedSuppliers.length > 0" cdkDropList cdkDropListOrientation="horizontal" class="selected-suppliers" (cdkDropListDropped)="drop($event)">
      <div *ngFor="let supplier of selectedSuppliers; let index = index" cdkDrag class="selected-supplier-item">
        <div class="preferred-icon" [attr.preferred-position]="index + 1">
          <img src="/assets/icons/star/full.svg">
        </div>
        {{ supplier.name }}
        <button mat-icon-button (click)="checkedChange(false, supplier)">
          <img src="/assets/icons/close-secondary.svg" alt="remove">
        </button>
      </div>
    </div>

    <div class="suppliers-list">
      <div *ngFor="let supplier of suppliers" class="supplier-list-item" (click)="checkedChange(!isSupplierSelected(supplier), supplier)">
        <div>
          <mat-checkbox
            (click)="$event.stopPropagation()"
            [checked]="isSupplierSelected(supplier)"
            [indeterminate]="false"
            (change)="checkedChange($event, supplier)"
            color="secondary"
          ></mat-checkbox>
        </div>
        <div [matBadge]="getSelectedSupplierPos(supplier)" matBadgeSize="small" matBadgePosition="before" matBadgeOverlap="false" style="margin-right: 5px">
          {{ supplier.name }}
        </div>
        <div>
          <app-rating-view [rating]="supplier.rating" [ratingsNumber]="supplier.ratingsNumber"></app-rating-view>
        </div>
      </div>
      <div *ngIf="suppliers.length === 0" class="no-data">
        {{ "common.labels.noData" | translate }}
      </div>
    </div>
  </app-accordion-panel>
</ng-template>

<div class="loading" [class.is-loading]="isLoading === true">
  <mat-spinner color="primary" diameter="120"></mat-spinner>
</div>
import { Component, OnInit } from '@angular/core';
import { IActionEvent } from 'src/app/components';
import { InternationalizationService } from 'src/app/services/internationalization/internationalization.service';
import { DatepickerHeaderComponent } from '../../components/index';
import { ApiService } from 'src/app/services/api/api.service';
import { IProjectKpi, IRequestKpi, IScorecardKpi } from 'src/app/models/kpi';
import { Router } from '@angular/router';
import { EDocumentType, EProjectStatus, IProjectDocInfo, IProjectDocInfoKeyed } from 'src/app/models/project';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { retry } from 'rxjs';
import { ERequestStatus } from 'src/app/models/request';
import { UserService } from 'src/app/services/user/user.service';
import { ERole } from 'src/app/models/user';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  datepickerHeaderComponent = DatepickerHeaderComponent 

  constructor(
    public internationalizationService: InternationalizationService,
    private userService: UserService,
    private api: ApiService,
    private router: Router,
  ) {}

  requestKpi: IRequestKpi;
  projectKpi: IProjectKpi;
  scorecardKpi: IScorecardKpi;

  isHoverRequest = false;
  isHoverProject = false;
  isHoverScorecard = false;

  get isCsmUser() {
    return this.userService.role === ERole.CS;
  }

  ngOnInit() {
    this.api.requestsKpi().subscribe((data) => {
      this.requestKpi = data;
    });
    this.api.projectsKpi().subscribe((data) => {
      this.projectKpi = data;
    });
    this.api.scorecardKpi().subscribe((data) => {
      this.scorecardKpi = data;
    });
    this.changeDocumentsPage(0);
  }

  goToRequest(requestId: number) {
    this.router.navigate(['/projectRequests/request-summary', requestId]);
  }
  goToProject(projectId: number) {
    this.router.navigate(['/projectsAndSuppliers/project-panel', projectId]);
  }

  isDocumentsLoading = true;
  documentsDataSource = new MatTableDataSource<IProjectDocInfoKeyed>([]);
  documentsPageData = {
    index: 0,
    size: 5,
    totalElements: 0,
    sort: null as Sort | null,
  }
  documentsDisplayedColumns: string[] = [ 
    "projectTitle",
    "ODS",
    "TREE",
    "ITSR",
    "GDPR",
    "actions",
  ];
  changeDocumentsPage(pageNumber?: number, pageSize?: number, sort?: Sort) {
    this.isDocumentsLoading = true;
    
    if (pageSize != null) this.documentsPageData.size = pageSize;
    if (pageNumber != null) this.documentsPageData.index = pageNumber;
    if (sort) this.documentsPageData.sort = sort;
    
    this.api.getPagedProjectsDocumentsStatus(
      this.documentsPageData.index,
      this.documentsPageData.size,
      this.documentsPageData.sort 
    ).subscribe((docs) => {
      this.isDocumentsLoading = false;
      this.documentsPageData.totalElements = docs.totalElements;
      this.documentsDataSource.data = docs.content.map(doc => {
        return Object.assign(doc, {
          documents: doc.documents.reduce((p, c) => {
            p[c.type] = c.flagComplete;
            return p;
          }, {} as { [key in EDocumentType]: boolean })
        });
      });
    })
  }

  private readonly filters = {
    empty: {},
    inProgress: {
      statusCode: ERequestStatus['In progress'],
    },
    closed: {
      statusCode: ERequestStatus.Closed,
    },
    open: {
      statusCode: EProjectStatus.Open,
    },
    expiring: (() => {
      const date = new Date();
      date.setDate(date.getDate() + 15);
      return {
        statusCode: EProjectStatus.Open,
        endDate: {
          to: date.toISOString(),
        }
      }
    })(),
    toBeInserted: {
      showMissingRating: true,
      statusCode: EProjectStatus.Open,
    },
    missingDocs: {
      showMissingDocument: true,
    }
  }
  kpiClicked(kpiType: 'requests' | 'projects' | 'scorecards', filter?: keyof typeof this.filters) {
    switch (kpiType) {
      case 'requests':
        this.router.navigate(['/projectRequests/requests'], filter ? { queryParams: { filters: JSON.stringify(this.filters[filter]) }} : null);
        break;
      case 'projects':
      case 'scorecards':
        this.router.navigate(['/projectsAndSuppliers/projects'], filter ? { queryParams: { filters: JSON.stringify(this.filters[filter]) }} : null);
        break;
    }
  }
}

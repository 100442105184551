import { Component, Directive, ElementRef, Input, ChangeDetectorRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 1500,
    disableTooltipInteractivity:true 
};

@Directive({
  selector: '[appTooltipNoHover]',
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }
  ],
})
export class TooltipNoHover extends MatTooltip {
  
  @Input() 
  get appTooltipNoHover() {
    return  this.message;
  }
  set appTooltipNoHover(value: string) {
    this.message = value;
  }

}

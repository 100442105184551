import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class InternationalizationService {

  private defaultLang = 'en-GB';

  public currentLang = this.translateService.currentLang;
  public readonly onLangChange = this.translateService.onLangChange;
  public readonly LANGS = [
    { value: "it-IT", viewValue: "Italiano" },
    { value: "en-GB", viewValue: "English" },
  ] as const;

  constructor(
    private translateService: TranslateService,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
  ) {
    this.translateService.setDefaultLang('en-GB');
    this.useLang('en-GB');

    this.translateService.onLangChange.subscribe((event) => {
      this.currentLang = event.lang;
    });
  }

  getDateFormatString(): string {
    switch (this._locale) {
      case 'it-IT':
      case 'en-GB':
      default:
        return 'DD/MM/YYYY';
    }
  }

  getDateFormatPipeString(): string {
    switch (this._locale) {
      case 'it-IT':
      case 'en-GB':
      default:
        return 'dd/MM/yyyy';
    }
  }

  ignoreDateTimezone(value: Date): Date {

    if (!value) {
      return null;
    }

    const date = new Date(value);
    const offset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - offset);

    return date;
  }

  useLang(lang?: typeof this.LANGS[number]['value']) {
    this._locale = lang || this.defaultLang;
    this._adapter.setLocale(this._locale);
    this.translateService.use(lang || this.defaultLang);
  } 
}

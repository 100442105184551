<div class="container">
  <div class="panel">
      <img src="../../../assets/images/logo-large.png" alt="logo" />

      <div>
        <h1>{{ errorCode }}</h1>
        <span>{{ ("errors." + errorCode) | translate }}</span>

        <div class="row" [ngSwitch]="userIsLoggedIn">
          <ng-container *ngSwitchCase="true">
            <button mat-flat-button color="primary" (click)="goBack()">
              {{ "common.actions.goBack" | translate }}
            </button>
            <button mat-flat-button color="primary" (click)="goToHome()">
              {{ "common.actions.goToHome" | translate }}
            </button>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <button mat-flat-button color="primary" (click)="goToLogin()">
              {{ "common.actions.goToLogin" | translate }}
            </button>
          </ng-container>
        </div>
      </div>
  </div>
</div>
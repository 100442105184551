import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { AlertService } from '../alert/alert.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public isFullScreen: boolean = false;
  public isUsingDarkTheme: boolean = false;
  public currentDateFormat: string = 'DD/MM/YYYY';

  constructor(
    private alertService: AlertService,
    private location: Location
  ) { }

  getFormErrors(form: FormGroup) {
    return Object.keys(form.controls).reduce((p, c) => {
      const control = form.get(c) as FormGroup | FormArray | AbstractControl;
      if (control instanceof FormGroup) {
        const errors = this.getFormErrors(control);
        if (Object.keys(errors).length > 0) {
          p[c] = errors;
        }
      }

      if (control instanceof FormArray) {
        const errors = control.controls.reduce((a_p, a_c) => {
          const a_errors = this.getFormErrors(a_c as FormGroup);
          if (Object.keys(a_errors).length > 0) {
            a_p.push(a_errors);
          }

          return a_p;
        }, [] as ValidationErrors[]);

        if (errors.length > 0) {
          p[c] = errors;
        }
      }

      const errors = form.get(c).errors;
      if (errors) p[c] = errors;

      return p;
    }, {} as ValidationErrors);
  }

  public focusOnInvalidInput(form: FormGroup<any>) {
    const keys = Object.keys(form.controls) as (keyof typeof form.controls)[];

    for (const key of keys) {
      const control = form.controls[key];
      if (control.invalid) {
        if ((control as FormArray<any>).controls != null) {
          if ((control as FormArray<any>).controls.some(c => this.focusOnInvalidInput(c as FormGroup<any>))) return true;          
        } else {
          const invalidControl: HTMLInputElement = document.querySelector('[formcontrolname="' + key + '"][aria-invalid="true"]');
          if (invalidControl)
            invalidControl.focus();
          else 
            setTimeout(() => {
              const invalidControl: HTMLInputElement = document.querySelector('[formcontrolname="' + key + '"][aria-invalid="true"]');
              if (invalidControl) invalidControl.focus();
            }, 100);
        
          return true;
        }

      }
    }

    return false;
  }

  
  createDownload(file: IFile, type: string, base64 = true) {
    const link = document.createElement('a');
    if (base64) {
      link.href = `data:${type};base64,${file.fileContent}`;
    } else {
      const blob = new Blob([file.fileContent], {type: type});
      const downloadURL = window.URL.createObjectURL(blob);
      link.href = downloadURL;
    }
    link.download = file.fileName.concat( ".", file.fileExtension);
    link.click();
    link.remove();
  }

  public showBackNavigationAlert(customFunction?: () => void, customAcceptText: string = "common.actions.confirm") {
    this.alertService.showAlert({
      type: "warning",
      message: "common.alerts.backNavigation",
      acceptText: customAcceptText,
      onConfirm: () => {
        this.alertService.dismissAlert();

        if (customFunction) 
          customFunction();
        else
          this.location.back();
      }
    });
  }

  public showBackNavigationAlertAsync(customAcceptText: string = "common.actions.confirm") {
    return this.alertService.showAsyncAlert({
      type: "warning",
      message: "common.alerts.backNavigation",
      acceptText: customAcceptText,
    }).then((result) => {
      this.alertService.dismissAlert();
      return result;
    });
  } 

}


import {FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { IFile } from 'src/app/models/file';

export class TouchedErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return !!(control && control.invalid && (control.dirty || control.touched));
    }
}
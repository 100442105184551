import { ContentObserver } from '@angular/cdk/observers';
import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { concatMap, firstValueFrom } from 'rxjs';
import { DatepickerHeaderComponent, IActionEvent } from 'src/app/components';
import { IListItem } from 'src/app/models/listItem';
import { EProjectStatus, IProject } from 'src/app/models/project';
import { ISupplier } from 'src/app/models/supplier';
import { ERole, IBusinessLine, ITribe } from 'src/app/models/user';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiService } from 'src/app/services/api/api.service';
import { InternationalizationService } from 'src/app/services/internationalization/internationalization.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss']
})
export class ProjectsListComponent implements OnInit {

  datepickerHeaderComponent = DatepickerHeaderComponent;

  constructor (
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private userService: UserService,
    private sharedService: SharedService,
    public internationalizationService: InternationalizationService,
  ) {}

  ngOnInit(): void {
    
    if (this.userService.role === ERole.CS) {
      this.filterForm.controls.flagCsm.setValue(true);
    }

    const f = this.userService.getSetting("projectsList_filters", true);
    if (f) {
      this.filterForm.patchValue(JSON.parse(f));
      this.filterForm.markAllAsTouched();
    } 

    this.supplierFilterChanged(this.filterForm.controls.supplierNameFilter.value);
    
    this.api.getBusinessLines().subscribe((businessLines) => {
      this.businessLines = businessLines;
    });    
    
    const p = this.userService.getSetting("projectsList_pageData");
    if (p) this.pageData = JSON.parse(p);

    this.getQueryParamFilters().then((editedFilters) => {
      if (editedFilters) return this.changePage(0);
      this.changePage();
    });
  }
  
  getQueryParamFilters() {
    return firstValueFrom(this.route.queryParams).then((params) => {
      if (params['filters']) {
        this.resetForm(false);
        try {
          this.filterForm.patchValue(JSON.parse(params['filters']));
        } catch (e) { 
          console.log("tried to patch value of filters form", params['filters']);
        }
        return true;
      } 
      return false;
    });
  }

  onActionClicked(action: IActionEvent) {
    switch (action.actionKey) {
      case 'toggleFilters': 
        this.showFilters = !action.status;
        break;
    }
  }

  //#region filters

  catalogs: IListItem[] = [];
  businessLines: IBusinessLine[] = [];
  projectTypes: IListItem[] = [];
  serviceTypes: IListItem[] = [];
  tribes: ITribe[] = [];
  suppliers: ISupplier[] = [];
  onBuinessLineChange(value: number | null) {
    this.filterForm.controls.tribeId.setValue(null);

    if (value) {
      this.filterForm.controls.tribeId.enable();
      this.tribes = this.businessLines.find(b => b.id === value)?.tribes || [];
    } else {
      this.filterForm.controls.tribeId.disable();
      this.filterForm.controls.tribeId.setValue(null);
    }
  }
  supplierFilterChanged(value: any) {
    this.api.getPagedSuppliers({ name: value }, 0, 4).subscribe((suppliers) => {
      this.suppliers = suppliers.content;
    });
  }

  showFilters = true;
  filterForm = this.fb.group({
    statusCode: [0 as EProjectStatus | 0],
    businessLineId: [null as number | null],
    tribeId: [{ value: null as string | null, disabled: true }],
    startDate: this.fb.group({
      from: [null],
      to: [null],
    }),
    endDate: this.fb.group({
      from: [null],
      to: [null],
    }),
    flagCsm: [0 as boolean | 0],
    showMissingRating: [false],
    showMissingDocument: [false],
    supplierId: [ null as number | null ],
    supplierNameFilter: [ "" ],
  }, {
    updateOn: 'submit',
  });

  resetForm(changePage = true) {
    this.filterForm.reset({
      statusCode: 0,
      businessLineId: null,
      tribeId: null,
      startDate: {
        from: null,
        to: null,
      },
      endDate: {
        from: null,
        to: null,
      },
      flagCsm: 0,
      showMissingRating: false,
      showMissingDocument: false,
      supplierId: null,
      supplierNameFilter: "",
    });
    this.onBuinessLineChange(null);

    if (this.userService.role === ERole.CS) {
      this.filterForm.controls.flagCsm.setValue(true);
    }

    if (changePage) this.changePage(0);
    this.userService.deleteSetting("projectsList_filters", true);
  }

  savedFilters: { [key: string]: any } = {};

  //#endregion

  //#region table

  isLoading = true; 
  pageData = {
    index: 0,
    size: 10,
    totalElements: 0,
    sort: { active: "creationDate", direction: "desc" } as Sort | null,
  }
  displayedColumns: string[] = [ 
    "status",
    "flagDocumentsComplete",
    "creationDate",
    "startDate",
    "endDate",
    "tribe",
    "rating",
    "username",
    "supplier",
    "supplierCategory",
    "contractType",
    "externalFTE",
    "technology",
    "actions",
  ];

  dataSource = new MatTableDataSource<IProject>([]);
  changePage(pageNumber?: number, pageSize?: number, sort?: Sort) {
    this.isLoading = true;

    if (pageSize != null) this.pageData.size = pageSize;
    if (pageNumber != null) this.pageData.index = pageNumber;
    if (sort) this.pageData.sort = sort;
    
    const fc = this.filterForm.value;
    const filters: { [key: string]: string | number | string[] | number[] | boolean } = {};

    if (fc.statusCode !== 0) filters["status"] = fc.statusCode;
    if (fc.businessLineId != null) filters["businessLineId"] = fc.businessLineId;
    if (fc.tribeId != null) filters["tribeId"] = fc.tribeId;
    if (fc.startDate.from != null) filters["startDateFrom"] = this.internationalizationService.ignoreDateTimezone(fc.startDate.from).toISOString();
    if (fc.startDate.to != null) filters["startDateTo"] = this.internationalizationService.ignoreDateTimezone(fc.startDate.to).toISOString();
    if (fc.endDate.from != null) filters["endDateFrom"] = this.internationalizationService.ignoreDateTimezone(fc.endDate.from).toISOString();
    if (fc.endDate.to != null) filters["endDateTo"] = this.internationalizationService.ignoreDateTimezone(fc.endDate.to).toISOString();
    if (fc.flagCsm !== 0) filters["flagCsm"] = fc.flagCsm;
    if (fc.showMissingRating === true) filters["flagScoreCardComplete"] = false;
    if (fc.showMissingDocument === true) filters["flagDocumentsComplete"] = false;
    if (fc.supplierId != null) filters["supplierId"] = fc.supplierId;
    
    if (this.filterForm.touched) {
      this.userService.saveSetting("projectsList_filters", JSON.stringify(this.filterForm.value), true);
    }
    this.userService.saveSetting("projectsList_pageData", JSON.stringify(this.pageData), true);

    this.api.getPagedProjects(filters, this.pageData.index, this.pageData.size, this.pageData.sort || { active: "creationDate", direction: "desc" }).subscribe({ 
      next: (projects) => {
        this.dataSource.data = projects.content;
        this.pageData.totalElements = projects.totalElements;
        this.isLoading = false;
      }, 
      error: () => { 
        this.isLoading = false; 
      }
    });
  }
  exportClicked() {
    this.isLoading = true;

    const fc = this.filterForm.value;
    const filters: { [key: string]: string | number | string[] | number[] | boolean } = {};

    if (fc.statusCode !== 0) filters["status"] = fc.statusCode;
    if (fc.businessLineId != null) filters["businessLineId"] = fc.businessLineId;
    if (fc.tribeId != null) filters["tribeId"] = fc.tribeId;
    if (fc.startDate.from != null) filters["startDateFrom"] = this.internationalizationService.ignoreDateTimezone(fc.startDate.from).toISOString();
    if (fc.startDate.to != null) filters["startDateTo"] = this.internationalizationService.ignoreDateTimezone(fc.startDate.to).toISOString();
    if (fc.endDate.from != null) filters["endDateFrom"] = this.internationalizationService.ignoreDateTimezone(fc.endDate.from).toISOString();
    if (fc.endDate.to != null) filters["endDateTo"] = this.internationalizationService.ignoreDateTimezone(fc.endDate.to).toISOString();
    if (fc.flagCsm !== 0) filters["flagCsm"] = fc.flagCsm;
    if (fc.showMissingRating === true) filters["flagScoreCardComplete"] = false;
    if (fc.showMissingDocument === true) filters["flagDocumentsComplete"] = false;
    if (fc.supplierId != null) filters["supplierId"] = fc.supplierId;

    this.api.exportProjects(filters, this.pageData.sort || { active: "creationDate", direction: "desc" }, this.pageData.totalElements).subscribe({ 
      next: (data) => {
        this.sharedService.createDownload(data, "application/csv");
        this.isLoading = false;
      }, 
      error: () => { 
        this.isLoading = false; 
      }
    });
  }

  areDocumentsComplete(project: IProject) {
    return project.documents.every(d => d.flagComplete);
  }

  infoClicked(project: IProject) {
    this.router.navigate(['projectsAndSuppliers/project-panel', project.id]);
  }

  addClicked() {
    this.router.navigate(['/projectsAndSuppliers/project-wizard']);
  }

  editClicked(project: IProject) {
    this.router.navigate(['/projectsAndSuppliers/project-edit', project.id]);
  }

  goToRequest(project: IProject) {
    this.router.navigate(['/projectRequests/request-summary', project.requestId]);
  }

  //#endregion
}

import { Component, OnInit, Input } from '@angular/core';
import { IProcedure } from 'src/app/models/procedure';

@Component({
  selector: 'app-procedure-view',
  templateUrl: './procedure-view.component.html',
  styleUrl: './procedure-view.component.scss'
})
export class ProcedureViewComponent implements OnInit {

  @Input() procedure: IProcedure;

  constructor() { }

  ngOnInit(): void {
  }

  getParagraphs() {
    if (this.procedure.type === 'composite') 
      return this.procedure.paragraphs;
    
    return [];
  }
}

import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert/alert.service';
import { IAlert, ICustomAlertModel } from 'src/app/models/alert';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('toggleAlert', [
      state('open', style({
        transform: 'scale(1)'
      })),
      state('closed', style({
        transform: 'scale(0)'
      })),
      transition('open <=> closed', [
        animate('0.3s ease-in-out')
      ]),
    ]),
  ]
})
export class AlertComponent implements OnInit {

  public isAnimating = false;
  public showAlert = false;
  
  public customAlertData: ICustomAlertModel;
  public data: IAlert;

  constructor (
    public alertService: AlertService
  ) { }

  ngOnInit() {
    this.alertService.showAlertEvent.subscribe(val => {
      this.isAnimating = true;
      if (this.showAlert = val) {
        this.customAlertData = this.data = this.alertService.alertData as any;
      }
    })
  }

  get alertStatus() {
    return this.showAlert ? 'open' : 'closed'
  }
  animationStart() {
    this.isAnimating = true;
  }
  animationEnd() {
    this.isAnimating = false;

    if (this.showAlert === false) {
      this.customAlertData = null;
      this.data = null;
    }
  }

  public closeAlert() {
    this.alertService.dismissAlert();
  }
}

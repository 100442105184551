import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

export interface IStep {
  title: string;
}

@Component({
  selector: 'app-stepper-header',
  templateUrl: './stepper-header.component.html',
  styleUrls: ['./stepper-header.component.scss'],
  animations: [
    trigger('titleState', [
      state('open', style({
        width: '*',
      })),
      state('closed', style({
        width: "0px",
      })),
      transition('open <=> closed', [
        animate('0.3s ease-in-out')
      ]),
    ])
  ]
})
export class StepperHeaderComponent {

  @Input() steps: IStep[] = []

  selectedStep = 0;
  @Input("selectedStep") set setSelectedStep(step: number) {
    this.selectedStep = step;
    this.selectedStepChange.emit(step);
  }
  @Output() selectedStepChange = new EventEmitter<number>();

  constructor(
    public elementRef: ElementRef<HTMLElement>,
  ) { }

  onStepClick(index: number) {
    this.selectedStepChange.emit(index);
  }

  get underlineLeftPosition() {
    const selectedChild = this.elementRef.nativeElement.children[this.selectedStep] as HTMLElement;
    if (selectedChild) {
      return selectedChild.offsetLeft || 0;
    }
    return 0;
  }

  get underlineWidth() {
    const selectedChild = this.elementRef.nativeElement.children[this.selectedStep] as HTMLElement;
    if (selectedChild) {
      return selectedChild.clientWidth;
    }
    return 0;
  }

}

<div class="loading" [class.is-loading]="isLoading === true">
  <mat-spinner color="primary" diameter="120"></mat-spinner>
</div>

<div class="rating-view-toggle">
  <mat-button-toggle-group [value]="viewRatingGraph" (valueChange)="setViewRatingGraph($event)" aria-label="Status">
    <mat-button-toggle [value]="true">
      <img src="assets/icons/graph.svg" alt="show line graph">
    </mat-button-toggle>
    <mat-button-toggle [value]="false">
      <img src="assets/icons/table.svg" alt="show table">
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div class="inner-table-container"> 
  <table class="mat-table mat-mdc-table mdc-data-table__table cdk-table">
    <thead role="rowgroup">
      <tr class="mat-header-row mat-mdc-header-row mdc-data-table__header-row cdk-header-row">
        <th colspan="4" *ngFor="let year of ratingTableData" role="columnheader" class="text-center mat-header-cell mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell">
          {{ year.year }}
        </th>
      </tr>
      <tr class="mat-header-row mat-mdc-header-row mdc-data-table__header-row cdk-header-row">
        <ng-container *ngFor="let year of ratingTableData; let y_i = index;">
          <th *ngFor="let quarter of year.details; let q_i = index" role="columnheader" [class.currentQuarter]="y_i === 1 && currentQuarter === quarter.quarter" class="text-center mat-header-cell mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell">
            <span>Q{{ quarter.quarter }}</span>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody role="rowgroup" class="mdc-data-table__content">
      <tr class="mat-row mat-mdc-no-hover-row">
        <td colspan="8">
          <div class="rating-table-row" role="row" [@hidden]="(viewRatingGraph).toString()">
            <ng-container *ngFor="let year of ratingTableData">
              <div class="mat-cell text-center rating-value" *ngFor="let quarter of year.details">
                @if (quarter.rating != null) {
                  {{ quarter.rating }}
                  <span class="subtext">/5</span>
                } @else {
                  -
                }
              </div>
            </ng-container>
          </div>
          <div class="mat-mdc-no-hover-row rating-graph-container" [@hidden]="(!viewRatingGraph).toString()">
            <div class="graph-container" >
              <app-charts-line-chart style="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px;"
                [showGridLines]="true"
                [yScaleMin]="0"
                [yScaleMax]="5"
                [yAxisTicks]="[ 0, 1, 2, 3, 4, 5 ]"
                [maxXAxisTickLength]="100"
                [xAxisTickFormatting]="getGraphLabel"
                [yAxisTickFormatting]="removeDecimal"
                [results]="ratingGraphData"
              ></app-charts-line-chart>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
import { Component, Directive, ElementRef, Input, ChangeDetectorRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  standalone: true,
  selector: '[matTooltip]',
  exportAs: 'matTooltip',
  host: {
    class: 'mat-mdc-tooltip-trigger',
  },
})
export class StandaloneMatTooltip extends MatTooltip {

}

@Component({
  selector: 'app-ellipsis',
  templateUrl: './table-cell-ellipsis.component.html',
  styleUrls: ['./table-cell-ellipsis.component.scss'],
  hostDirectives: [
    {
      directive: StandaloneMatTooltip,
    },
  ],
})
export class TableCellEllipsisComponent {

  @Input("text") set setText(text: string) { 
    this.standaloneMatTooltip.message = text;
    setTimeout(() => {
      this.checkIfTooltipIsNecessary();
    }, 50);
  }

  constructor(
    public standaloneMatTooltip: StandaloneMatTooltip,
    private elementRef: ElementRef
  ) { }
  
  checkIfTooltipIsNecessary() {
    const element = this.elementRef.nativeElement as HTMLElement;
    this.standaloneMatTooltip.disabled = element.offsetWidth == 0 || element.offsetWidth >= element.scrollWidth;
  } 
}

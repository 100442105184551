<form [formGroup]="formQuestions"  class="questions-container">
  <ng-container formArrayName="questions">
    <ng-container *ngFor="let question of _QUESTIONS; let index = index" [formGroupName]="index">
      <div class="question-container" [attr.question-type]="question.type">
        <span class="question-text">{{ ('rating.questions.' + question.questionKey) | translate }}
          <span class="error-label no-icon" *ngIf="getformQuestionControl(index).touched && !getformQuestionControl(index).valid">({{ "common.errors.requiredField" | translate }})</span>
        </span>
        <ng-container [ngSwitch]="question.type">
          <mat-form-field *ngSwitchCase="'string'">
            <textarea matInput formControlName="question"></textarea>
          </mat-form-field>
          <mat-form-field *ngSwitchCase="'number'">
            <input type="number" matInput formControlName="question">
          </mat-form-field>
          <div class="mat-form-field" *ngSwitchCase="'boolean'">
            <mat-button-toggle-group formControlName="question">
              <mat-button-toggle [value]="true">{{ "common.labels.yes" | translate }}</mat-button-toggle>
              <mat-button-toggle [value]="false">{{ "common.labels.no" | translate }}</mat-button-toggle>
            </mat-button-toggle-group>  
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</form>
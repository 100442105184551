
<div class="header" (click)="toggleDrawer()">
  <h3 *ngIf="title" class="panel-title">{{ title }}</h3>
  <app-accordion-icon [up]="open"></app-accordion-icon>
</div>
<div [@panelState]="getToggleDrawer" class="container">
  <div [class.secondary]="style === 'secondary'">
    <div class="loading" [class.is-loading]="isLoading === true">
      <mat-spinner color="primary" diameter="120"></mat-spinner>
    </div>
    <ng-content></ng-content>
  </div>
</div>
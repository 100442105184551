import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { BaseChartComponent, ColorHelper, DataItem, LegendOptions, LegendPosition, ScaleType, ViewDimensions, calculateViewDimensions } from '@swimlane/ngx-charts';
import { scaleBand, scaleLinear } from 'd3-scale';

@Component({
  selector: 'app-vertical-bars-chart',
  templateUrl: './vertical-bars-chart.component.html',
  styleUrls: ['./vertical-bars-chart.component.scss']
})
export class VerticalBarsChartComponent extends BaseChartComponent {
  @Input() legend = false;
  @Input() legendTitle: string = 'Legend';
  @Input() legendPosition: LegendPosition = LegendPosition.Right;
  @Input() xAxis: boolean = true;
  @Input() yAxis: boolean = true;
  @Input() showXAxisLabel: boolean;
  @Input() showYAxisLabel: boolean;
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() tooltipDisabled: boolean = false;
  @Input() gradient: boolean;
  @Input() showGridLines: boolean = true;
  @Input() activeEntries: any[] = [];
  @Input() override schemeType: ScaleType;
  @Input() trimXAxisTicks: boolean = true;
  @Input() trimYAxisTicks: boolean = true;
  @Input() rotateXAxisTicks: boolean = true;
  @Input() maxXAxisTickLength: number = 16;
  @Input() maxYAxisTickLength: number = 16;
  @Input() xAxisTickFormatting: any;
  @Input() yAxisTickFormatting: any;
  @Input() xAxisTicks: any[];
  yAxisTicks: any[] = [];
  yAxisMainTicks: any[] = [];
  yAxisMaxTicks: any[] = [];
  @Input() barPadding = 0;
  @Input() barMaxWidth: number = 36;
  @Input() roundDomains: boolean = false;
  @Input() roundEdges: boolean = true;
  @Input() yScaleMax: number;
  @Input() yScaleMin: number;
  @Input() showDataLabel: boolean = false;
  @Input() dataLabelFormatting: any;
  @Input() noBarWhenZero: boolean = true;
  @Input() wrapTicks = false;

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;

  dims: ViewDimensions;
  xScale: any;
  xBarScale: any;
  yScale: any;
  xDomain: any;
  yDomain: any;
  transform: string;
  colors: ColorHelper;
  margin: number[] = [10, 20, 10, 20];
  xAxisHeight: number = 0;
  yAxisWidth: number = 0;
  legendOptions: LegendOptions;
  dataLabelMaxHeight: any = { negative: 0, positive: 0 };

  override update(): void {
    super.update();

    if (!this.showDataLabel) {
      this.dataLabelMaxHeight = { negative: 0, positive: 0 };
    }
    this.margin = [10 + this.dataLabelMaxHeight.positive, 20, 10 + this.dataLabelMaxHeight.negative, 20];

    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    this.formatDates();

    if (this.showDataLabel) {
      this.dims.height -= this.dataLabelMaxHeight.negative;
    }
    this.xScale = this.getXScale();
    this.xBarScale = this.getXBarScale();
    this.yScale = this.getYScale();

    this.yAxisMainTicks = this.getYMainAxisTicks();
    this.yAxisTicks = this.getYAxisTicks();
    this.yAxisMaxTicks = this.getYAxisMaxTicks();

    this.setColors();
    this.legendOptions = this.getLegendOptions();

    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;
  }

  getXScale(): any {
    this.xDomain = this.getXDomain();
    const spacing = this.xDomain.length / (this.dims.width / this.barPadding + 1);
    return scaleBand().range([0, this.dims.width]).paddingInner(spacing).domain(this.xDomain);
  }
  getXBarScale(): any {
    this.xDomain = this.getXDomain();
    if (this.xDomain.length != 0) {
      const spacing = 1 - (this.barMaxWidth * this.xDomain.length) / this.dims.width; 
      return scaleBand().range([0, this.dims.width]).paddingInner(spacing).paddingOuter(spacing / 2).domain(this.xDomain);
    }
    return this.getXScale();
  }
  
  getYAxisMaxTicks() {
    const max = this.results && this.results.length ? Math.max(...this.results.map((r: any) => r.value)) : 0;
    return [ max ];
  }

  yAxisTickFormattingFn = (value: number) => {
    if (this.yAxisTickFormatting) {
      return this.yAxisTickFormatting(value);
    }
    return value.toLocaleString();
  }

  getYMainAxisTicks() {
    this.xDomain = this.getXDomain();
    const max = this.getYAxisMaxTicks()[0];
    
    let gap = Number("1".padEnd(max.toString().length, "0"));

    const arr: number[] = [];
    let items = Math.ceil(max / gap)

    if (this.dims.height / items < 40) {
      gap *= 2;
      items = Math.ceil(max / gap)
    }
    
    if (gap < 2) {
      gap = 2;
      if (max <= 2) return [0];

      items = Math.ceil(max / gap)
    }

    for (let i=0; i < items; ++i) {
      arr.push(gap * i);

    }

    return arr;
  }
  
  getYAxisTicks() {
    const max = this.getYAxisMaxTicks()[0];

    let gap = Number("1".padEnd(max.toString().length, "0"));
    if (this.dims.height / Math.ceil(max / gap) < 40) {
      gap *= 2;
    }

    if (gap < 2) {
      gap = 2;
      if (max <= 2) {
        return (max != 1) ? [1] : [];
      }
    }

    const arr = this.yAxisMainTicks.slice(0, this.yAxisMainTicks.length - 1).reduce((p, c) => { 
      p.push(c + gap / 2);
      return p;
    }, [] as number[]);
    
    //if (this.yAxisMainTicks[this.yAxisMainTicks.length - 1] != max) arr.push(max);
    const lastTick = arr[arr.length - 1] + gap;
    if (lastTick < max) arr.push(lastTick);

    return arr;
  }

  getYScale(): any {
    this.yDomain = this.getYDomain();
    const scale = scaleLinear().range([this.dims.height, 0]).domain(this.yDomain);
    return this.roundDomains ? scale.nice() : scale;
  }

  getXDomain(): any[] {
    return this.results.map((d: any) => d.label);
  }

  getYDomain(): [number, number] {
    const values = this.results.map((d: any) => d.value);

    let min = this.yScaleMin ? Math.min(this.yScaleMin, ...values) : Math.min(0, ...values);
    if (this.yAxisTicks && !this.yAxisTicks.some(isNaN)) {
      min = Math.min(min, ...this.yAxisTicks);
    }

    let max = this.yScaleMax ? Math.max(this.yScaleMax, ...values) : Math.max(0, ...values);
    if (this.yAxisTicks && !this.yAxisTicks.some(isNaN)) {
      max = Math.max(max, ...this.yAxisTicks);
    }
    return [min, max];
  }

  onClick(data: DataItem | string) {
    this.select.emit(data);
  }

  setColors(): void {
    let domain;
    if (this.schemeType === ScaleType.Ordinal) {
      domain = this.xDomain;
    } else {
      domain = this.yDomain;
    }

    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
  }

  getLegendOptions() {
    const opts = {
      scaleType: this.schemeType as any,
      colors: undefined as any,
      domain: [] as any[],
      title: undefined as string,
      position: this.legendPosition
    };
    if (opts.scaleType === ScaleType.Ordinal) {
      opts.domain = this.xDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.yDomain;
      opts.colors = this.colors.scale;
    }
    return opts;
  }

  updateYAxisWidth(prop: { width: number }): void {
    this.yAxisWidth = prop.width;
    this.update();
  }

  updateXAxisHeight(prop: { height: number }): void {
    this.xAxisHeight = prop.height;
    this.update();
  }

  onDataLabelMaxHeightChanged(event: any) {
    if (event.size.negative) {
      this.dataLabelMaxHeight.negative = Math.max(this.dataLabelMaxHeight.negative, event.size.height);
    } else {
      this.dataLabelMaxHeight.positive = Math.max(this.dataLabelMaxHeight.positive, event.size.height);
    }
    if (event.index === this.results.length - 1) {
      setTimeout(() => this.update());
    }
  }

  onActivate(item: any, fromLegend = false) {
    item = this.results.find((d: any) => {
      if (fromLegend) {
        return d.label === item.name;
      } else {
        return d.name === item.name;
      }
    });

    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value && d.series === item.series;
    });
    if (idx > -1) {
      return;
    }

    this.activeEntries = [item, ...this.activeEntries];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(item: any, fromLegend = false) {
    item = this.results.find((d: any) => {
      if (fromLegend) {
        return d.label === item.name;
      } else {
        return d.name === item.name;
      }
    });

    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value && d.series === item.series;
    });

    this.activeEntries.splice(idx, 1);
    this.activeEntries = [...this.activeEntries];

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }
}

<app-page-container
  [pageTitleParts]="canEdit ? ['manage-suppliers.title'] : ['suppliers.title']"
  [actions]="[
    { key: 'toggleFilters', icon: 'eye-closed.svg', name: 'common.actions.hideFilters', activeIcon: 'eye-open.svg', activeName: 'common.actions.showFilters' }
  ]"
  (actionClicked)="onActionClicked($event)"
>
  <app-panel  
    [open]="showFilters === true" 
    [style]="'secondary'"
  >
    <form class="example-form" [formGroup]="filterForm" (ngSubmit)="changePage(0)">
      <div class="panel-actions-container m-bottom">
        <h3 class="panel-title no-line">{{ "common.labels.filters" | translate }}</h3>
        
        <button mat-flat-button color="accent" type="button" (click)="resetForm()">{{ "common.filters.clearFilters" | translate }}</button>
        <button mat-flat-button color="primary" type="submit">{{ "common.filters.filter" | translate }}</button>
      </div>

      <mat-form-field class="span-6">
        <mat-label>{{ 'supplier.fields.name' | translate }}</mat-label>
        <input matInput formControlName="name" autocomplete="off" [placeholder]="'supplier.fields.name' | translate">
      </mat-form-field>
      
      
      <div class="mat-form-field">
        <mat-label class="mat-button-toggle-label">{{ "supplier.fields.isActive" | translate }}</mat-label>
        <mat-button-toggle-group formControlName="isActive" aria-label="Status">
          <mat-button-toggle [value]="0">{{ "common.labels.all" | translate }}</mat-button-toggle>
          <mat-button-toggle [value]="true">{{ "common.labels.active" | translate }}</mat-button-toggle>
          <mat-button-toggle [value]="false">{{ "common.labels.disabled" | translate }}</mat-button-toggle>
        </mat-button-toggle-group>  
      </div>
     
      <div class="mat-form-field">
        <mat-label class="mat-button-toggle-label">{{ "supplier.fields.flagCsm" | translate }}</mat-label>
        <mat-button-toggle-group formControlName="flagCsm" aria-label="flagCsm">
          <mat-button-toggle [value]="0">{{ "common.labels.all" | translate }}</mat-button-toggle>
          <mat-button-toggle [value]="true">{{ "common.labels.yes" | translate }}</mat-button-toggle>
          <mat-button-toggle [value]="false">{{ "common.labels.no" | translate }}</mat-button-toggle>
        </mat-button-toggle-group>  
      </div>

      <mat-form-field>
        <mat-label>{{ "supplier.fields.category" | translate }}</mat-label>
        <mat-select formControlName="categories" [placeholder]="'supplier.fields.category' | translate" multiple>
          <mat-option *ngFor="let category of categories" [value]="category.id">{{ category.value }}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field>
        <mat-label>{{ "supplier.fields.contracts" | translate }}</mat-label>
        <mat-select formControlName="contracts" [placeholder]="'supplier.fields.contracts' | translate" multiple>
          <mat-option *ngFor="let contractType of contractTypes" [value]="contractType.id">{{ contractType.value }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="span-1">
        <mat-label>Min. {{ "supplier.fields.rating" | translate }}</mat-label>
        <mat-select formControlName="minRating" [placeholder]="'supplier.fields.rating' | translate" (valueChange)="onMinRatingChanged($event)">
          <mat-option [value]="0">0</mat-option>
          <mat-option [value]="1">1</mat-option>
          <mat-option [value]="2">2</mat-option>
          <mat-option [value]="3">3</mat-option>
          <mat-option [value]="4">4</mat-option>
        </mat-select>
      </mat-form-field>
      
      <mat-form-field class="span-1">
        <mat-label>Max. {{ "supplier.fields.rating" | translate }}</mat-label>
        <mat-select formControlName="maxRating" [placeholder]="'supplier.fields.rating' | translate" (valueChange)="onMaxRatingChanged($event)">
          <mat-option [value]="1">1</mat-option>
          <mat-option [value]="2">2</mat-option>
          <mat-option [value]="3">3</mat-option>
          <mat-option [value]="4">4</mat-option>
          <mat-option [value]="5">5</mat-option>
        </mat-select>
      </mat-form-field>

    </form>
  </app-panel>
  <app-panel 
    [open]="showFilters === false && filterForm.touched === true"
    [style]="'secondary'"
  >
    <span>{{ "common.filters.activeFilters.0" | translate }} <a (click)="resetForm()">{{ "common.filters.activeFilters.1" | translate }}</a> {{ "common.filters.activeFilters.2" | translate }}</span>
  </app-panel>
 
  <div class="table-container">
    <div class="loading" [class.is-loading]="isLoading === true">
      <mat-spinner color="primary" diameter="120"></mat-spinner>
    </div>

    <div class="table-summary">
      <span> {{ "suppliers.totalElements" | pluralTranslate:pageData.totalElements | translate : { totalElements: pageData.totalElements } }} </span>
      <div class="divider"></div>
      <button mat-flat-button color="primary" (click)="exportClicked()">
        <img class="button-icon white-icon" src="/assets/icons/download.svg">
        {{ "common.actions.export" | translate }}
      </button>
      <button mat-flat-button *ngIf="canEdit === true" color="primary" (click)="addClicked()">
        <img class="button-icon white-icon" src="/assets/icons/add-user.svg">
        {{ "suppliers.actions.addSupplier" | translate }}
      </button>
    </div>

    <div class="inner-table-container"> 
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="changePage(null, null, $event)" style="min-width: max(1000px, 100%);">
      
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>  {{ "supplier.fields.name" | translate }}   </th>
          <td mat-cell *matCellDef="let supplier" [class.not-active]="supplier.isActive === false"> {{supplier.name}} </td>
        </ng-container>
      
        <!-- Country Column -->
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>  {{ "supplier.fields.country" | translate }}   </th>
          <td mat-cell *matCellDef="let supplier" [class.not-active]="supplier.isActive === false"> {{supplier.country}} </td>
        </ng-container>

        <!-- Service Catalog Column 
        <ng-container matColumnDef="serviceCatalog">
          <th mat-header-cell *matHeaderCellDef> {{ "supplier.fields.serviceCatalog" | translate }}  </th>
          <td mat-cell *matCellDef="let supplier" [class.not-active]="supplier.isActive === false"> 
            <app-ellipsis [text]="getServiceText(supplier.servicesCatalog)"></app-ellipsis>
          </td>
        </ng-container> -->
      
        <!-- Category Column -->
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "supplier.fields.category" | translate }} </th>
          <td mat-cell *matCellDef="let supplier" [class.not-active]="supplier.isActive === false"> {{supplier.category}} </td>
        </ng-container>
  
        <!-- Contracts Column -->
        <ng-container matColumnDef="contracts">
          <th mat-header-cell *matHeaderCellDef> {{ "supplier.fields.contracts" | translate }} </th>
          <td mat-cell *matCellDef="let supplier" [class.not-active]="supplier.isActive === false"> {{ getContracts(supplier.contracts) }} </td>
        </ng-container>
      
        <!-- Status Column -->
        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "supplier.fields.isActive" | translate }}  </th>
          <td mat-cell *matCellDef="let supplier" [class.not-active]="supplier.isActive === false">
             <!-- {{ (supplier.isActive ? "common.labels.active" : "common.labels.disabled") | translate }}  -->
            <img src="/assets/icons/eye-{{ supplier.isActive === true ? 'open' : 'closed' }}.svg" 
              [appTooltipNoHover]="('common.labels.' + (supplier.isActive ? 'active' : 'disabled')) | translate"
              matTooltipPosition="above" alt="Active">
          </td>
        </ng-container>
      
        <!-- Rating Column -->
        <ng-container matColumnDef="rating">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{ "supplier.fields.rating" | translate }} </th>
          <td mat-cell *matCellDef="let supplier" [class.not-active]="supplier.isActive === false"> 
            <app-rating-view [rating]="supplier.rating" [ratingsNumber]="supplier.ratingsNumber"></app-rating-view>
          </td>
        </ng-container>

        <!-- Flag CSM Column -->
        <ng-container matColumnDef="flagCsm">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Flag C&S </th>
          <td mat-cell *matCellDef="let supplier" [class.not-active]="supplier.isActive === false"> 
            <!-- {{ supplier.flagCsm ? 'Yes' : 'No' }}  -->
            <img *ngIf="supplier.flagCsm === true" src="/assets/icons/check.svg" alt="Yes">
          </td>
        </ng-container>
        
        <!-- Notes Column -->
        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef>  {{ "supplier.fields.notes" | translate }}   </th>
          <td mat-cell *matCellDef="let supplier" [class.not-active]="supplier.isActive === false"> 
            <app-ellipsis [text]="supplier.notes"></app-ellipsis>
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef  class="sticky-col" [class.expand]="canEdit"></th>
          <td mat-cell *matCellDef="let element" class="sticky-col" [class.expand]="canEdit"> 
            <button mat-icon-button (click)="infoClicked(element)"
              [appTooltipNoHover]="'suppliers.actions.goToPanel' | translate" matTooltipPosition="above"
            >
              <img src="/assets/icons/info.svg" alt="info">
            </button>
            <button mat-icon-button *ngIf="canEdit === true" (click)="editClicked(element)"
              [appTooltipNoHover]="'suppliers.actions.edit' | translate" matTooltipPosition="above"
            >
              <img src="/assets/icons/edit.svg" alt="edit">
            </button>
        <!--     <button mat-icon-button (click)="deleteClicked(element)"
              [appTooltipNoHover]="'suppliers.actions.delete' | translate" matTooltipPosition="above"
            >
              <img src="/assets/icons/delete.svg" alt="delete">
            </button> -->
          </td>
        </ng-container>

        
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell">
            {{ "common.labels.noData" | translate }}
          </td>
        </tr>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator 
      showFirstLastButtons
      [pageSizeOptions]="[10, 20, 50]" 
      [pageSize]="pageData.size" 
      [length]="pageData.totalElements" 
      [pageIndex]="pageData.index"
      (page)="changePage($event.pageIndex, $event.pageSize)"
    ></mat-paginator>
  </div>
</app-page-container>

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { IError } from 'src/app/models/error';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private userService: UserService,
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    const authToken = this.userService.getToken();
    const modifiedReq = req.clone({ 
      headers: authToken ? req.headers.set('x-token', authToken) : req.headers,
    });

    return next.handle(modifiedReq).pipe(
      catchError((error: HttpErrorResponse) => {
          let errBody = error.error;
          errBody.code = error.status;

          try {
            if (errBody.status !== false || errBody.messages.length < 1) throw new Error();
          } catch (err) {
            this.navigate('/error/500');
          }
          

          if (error.status === 401 && errBody.messages) {
            if (errBody.messages.some((m: any) => m.key === "invalid_role") ) {
              this.navigate('/error/401');
              return null;
            }
           
            if (! errBody.messages.some((m: any) => m.key === "invalid_credentials") ) {
              this.userService.logout();
              return null;
            }
          } 
          
          if (error.status === 404) {
            this.navigate('/error/404');
            return null; 
          }

          return throwError(() => { return errBody });
      })
    );
  }

  private navigate(url: string) {
    this.router.navigate([url]);
  }
}
import { Component, OnInit,Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from './services/internationalization/internationalization.service';
import { UserService } from './services/user/user.service';
import { Router } from '@angular/router';
import { SharedService } from './services/shared/shared.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isDarkMode = false;
  private readonly defaultLang = 'en-GB';

  constructor(
    internationalizationService: InternationalizationService,
    userService: UserService,
    router: Router,
    public sharedService: SharedService,
  ) {
    internationalizationService.onLangChange.subscribe((evnt) => {
      window.localStorage.setItem('lang', evnt.lang);
    });

    const userLang = window.localStorage.getItem('lang') as any;
    internationalizationService.useLang( internationalizationService.LANGS.find(l => l.value === userLang) ? userLang : this.defaultLang );

    if (!userService.isLoggedIn) router.navigate(["/login"]);
  } 

  ngOnInit(): void {
  
  }
}

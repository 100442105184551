<app-page-container
  [pageTitleParts]="[ 'manage-users.title' ]"
  [actions]="[
    { key: 'toggleFilters', icon: 'eye-closed.svg', name: 'common.actions.hideFilters', activeIcon: 'eye-open.svg', activeName: 'common.actions.showFilters' }
  ]"
  (actionClicked)="onActionClicked($event)"
>
  <app-panel  
    [open]="showFilters" 
    [style]="'secondary'"
  >
  <form [formGroup]="filterForm" (ngSubmit)="changePage(0)">
      <div class="panel-actions-container m-bottom ">
        <h3 class="panel-title no-line">{{ "common.labels.filters" | translate }}</h3>
        
        <button mat-flat-button color="accent" type="button" (click)="resetForm()">{{ "common.filters.clearFilters" | translate }}</button>
        <button mat-flat-button color="primary" type="submit">{{ "common.filters.filter" | translate }}</button>
      </div>

      <mat-form-field class="span-6">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" autocomplete="off" placeholder="Email">
      </mat-form-field>
      
      <div class="mat-form-field">
        <mat-label class="mat-button-toggle-label">{{ "user.fields.isActive" | translate }}</mat-label>
        <mat-button-toggle-group formControlName="isActive" aria-label="Status">
          <mat-button-toggle [value]="0">{{ "common.labels.all" | translate }}</mat-button-toggle>
          <mat-button-toggle [value]="true">{{ "common.labels.active" | translate }}</mat-button-toggle>
          <mat-button-toggle [value]="false">{{ "common.labels.disabled" | translate }}</mat-button-toggle>
        </mat-button-toggle-group>  
      </div>

      <mat-form-field>
        <mat-label>Business Line</mat-label>
        <mat-select formControlName="businessLine" placeholder="Business Line" (valueChange)="onBuinessLineChange($event)">
          <mat-option [value]="null">{{ "common.labels.all" | translate }}</mat-option>
          <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">{{ businessLine.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="non-view-field">
        <mat-label>Tribe</mat-label>
        <mat-select formControlName="tribe" placeholder="Tribe" (opened)="tribesFilter.value = ''" >
          <div class="select-search">
            <mat-form-field>
              <mat-label>{{ "common.labels.search" | translate }}</mat-label>
              <input matInput [placeholder]="'common.labels.search' | translate" #tribesFilter>
            </mat-form-field>
          </div>
          <mat-option [value]="null">{{ "common.labels.empty" | translate }}</mat-option>
          <mat-option *ngFor="let trib of tribes" 
            [attr.data-hidden]="!trib.name.toLowerCase().includes(tribesFilter.value.toLowerCase())" 
            [value]="trib.id">
            {{ trib.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </app-panel>
  <app-panel 
    [open]="!showFilters && filterForm.touched"
    [style]="'secondary'"
  >
    <span>{{ "common.filters.activeFilters.0" | translate }} <a (click)="resetForm()">{{ "common.filters.activeFilters.1" | translate }}</a> {{ "common.filters.activeFilters.2" | translate }}</span>
  </app-panel>

  
  <div class="table-container">
    <div class="loading" [class.is-loading]="isLoading">
      <mat-spinner color="primary" diameter="120"></mat-spinner>
    </div>
    
    <div class="table-summary">
      <span> {{ "users.totalElements" | pluralTranslate:pageData.totalElements | translate : { totalElements: pageData.totalElements } }} </span>
      <div class="divider"></div>
      <button mat-flat-button color="primary" (click)="addUserClicked()">
        <img class="button-icon white-icon" src="/assets/icons/add-user.svg">
        {{ "users.actions.addUser" | translate }}
      </button>
    </div>

    <div class="inner-table-container"> 
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="changePage(null, null, $event)" style="min-width: max(1000px, 100%);">
      
        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let element" [class.not-active]="element.isActive === false"> {{element.email}} </td>
        </ng-container>

        <!-- Tribe Column -->
        <ng-container matColumnDef="tribe">
          <th mat-header-cell *matHeaderCellDef> Tribe </th>
          <td mat-cell *matCellDef="let element" [class.not-active]="element.isActive === false">
            <ng-container *ngFor="let tribe of element.tribes; let isLast = last"> 
              ({{ tribe.businessLine }}) {{ tribe.tribe }}{{ isLast ? '' : ', ' }}
            </ng-container>
         </td>
        </ng-container>
  
        <!-- Role Column -->
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "user.fields.role" | translate }} </th>
          <td mat-cell *matCellDef="let element" [class.not-active]="element.isActive === false"> {{ ('user.roles.' + element.role) | translate }} </td>
        </ng-container>
  
        <!-- Status Column -->
        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "user.fields.isActive" | translate }}  </th>
          <td mat-cell *matCellDef="let element" [class.not-active]="element.isActive === false"> 
      <!--       {{ (element.isActive ? "common.labels.active" : "common.labels.disabled") | translate }}  -->
          <img src="/assets/icons/eye-{{ element.isActive === true ? 'open' : 'closed' }}.svg" 
          [appTooltipNoHover]="('common.labels.' + (element.isActive ? 'active' : 'disabled')) | translate"
          matTooltipPosition="above" alt="Active">
          </td>
        </ng-container>
    
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="sticky-col"> </th>
          <td mat-cell *matCellDef="let element" class="sticky-col"> 
            <!-- create a material icon button -->
            <button mat-icon-button (click)="editUserClicked(element)"
              [appTooltipNoHover]="'users.actions.edit' | translate" matTooltipPosition="above"
            >
              <img src="/assets/icons/edit.svg" alt="edit">
            </button>
           <!--  <button mat-icon-button (click)="deleteUserClicked(element)"
              [appTooltipNoHover]="'users.actions.delete' | translate" matTooltipPosition="above"
            >
              <img src="/assets/icons/delete.svg" alt="delete">
            </button> -->
          </td>
        </ng-container>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell">
            {{ "common.labels.noData" | translate }}
          </td>
        </tr>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    
    <mat-paginator 
      showFirstLastButtons
      [pageSizeOptions]="[10, 20, 50]" 
      [pageSize]="pageData.size" 
      [length]="pageData.totalElements" 
      [pageIndex]="pageData.index"
      (page)="changePage($event.pageIndex, $event.pageSize)"
    ></mat-paginator>
  </div>
</app-page-container>

<ngx-charts-chart
[view]="[width, height]"
[showLegend]="legend"
[legendOptions]="legendOptions"
[activeEntries]="activeEntries"
[animations]="animations"
(legendLabelClick)="onClick($event)"
(legendLabelActivate)="onActivate($event)"
(legendLabelDeactivate)="onDeactivate($event)"
>
<svg:defs>
  <svg:clipPath [attr.id]="clipPathId">
    <svg:rect
      [attr.width]="dims.width + 10"
      [attr.height]="dims.height + 10"
      [attr.transform]="'translate(-5, -5)'"
    />
  </svg:clipPath>
</svg:defs>
<svg:g [attr.transform]="transform" class="line-chart chart">
  <svg:g
    ngx-charts-x-axis
    *ngIf="xAxis"
    [xScale]="xScale"
    [dims]="dims"
    [showGridLines]="showGridLines"
    [showLabel]="showXAxisLabel"
    [labelText]="xAxisLabel"
    [trimTicks]="trimXAxisTicks"
    [rotateTicks]="rotateXAxisTicks"
    [maxTickLength]="maxXAxisTickLength"
    [tickFormatting]="xAxisTickFormatting"
    [ticks]="xAxisTicks"
    [wrapTicks]="wrapTicks"
    (dimensionsChanged)="updateXAxisHeight($event)"
  ></svg:g>
  <svg:g
    ngx-charts-y-axis
    [yScale]="yScale"
    [dims]="dims"
    [showGridLines]="true"
    [showLabel]="true"
    [labelText]="yAxisLabel"
    [trimTicks]="trimYAxisTicks"
    [maxTickLength]="maxYAxisTickLength"
    [tickFormatting]="yAxisTickFormatting"
    [ticks]="yAxisTicks"
    [referenceLines]="referenceLines"
    [showRefLines]="true"
    [showRefLabels]="true"
    [wrapTicks]="wrapTicks"
    (dimensionsChanged)="updateYAxisWidth($event)"
  ></svg:g>
  <svg:g [attr.clip-path]="clipPath">
    <svg:g *ngIf="!isSSR">
      <svg:g *ngFor="let series of results; trackBy: trackBy" [@animationState]="'active'">
        <svg:g
          app-charts-line-series
          [xScale]="xScale"
          [yScale]="yScale"
          [colors]="colors"
          [data]="series"
          [activeEntries]="activeEntries"
          [scaleType]="scaleType"
          [curve]="curve"
          [rangeFillOpacity]="rangeFillOpacity"
          [hasRange]="hasRange"
          [animations]="animations"
        />
      </svg:g>
    </svg:g>
    <svg:g *ngIf="isSSR">
      <svg:g *ngFor="let series of results; trackBy: trackBy">
        <svg:g
          app-charts-line-series
          [xScale]="xScale"
          [yScale]="yScale"
          [colors]="colors"
          [data]="series"
          [activeEntries]="activeEntries"
          [scaleType]="scaleType"
          [curve]="curve"
          [rangeFillOpacity]="rangeFillOpacity"
          [hasRange]="hasRange"
          [animations]="animations"
        />
      </svg:g>
    </svg:g>

    <svg:g *ngIf="!tooltipDisabled" (mouseleave)="hideCircles()">
      <svg:g
        ngx-charts-tooltip-area
        [dims]="dims"
        [xSet]="xSet"
        [xScale]="xScale"
        [yScale]="yScale"
        [results]="results"
        [colors]="colors"
        [tooltipDisabled]="tooltipDisabled"
        [tooltipTemplate]="seriesTooltipTemplate"
        (hover)="updateHoveredVertical($event)"
      />

      <svg:g *ngFor="let series of results">
        <svg:g
          app-charts-circle-series
          [xScale]="xScale"
          [yScale]="yScale"
          [colors]="colors"
          [data]="series"
          [scaleType]="scaleType"
          [visibleValue]="hoveredVertical"
          [activeEntries]="activeEntries"
          [tooltipDisabled]="true"
          [tooltipTemplate]="tooltipTemplate"
          (select)="onClick($event)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)"
        />
      </svg:g>
    </svg:g>
  </svg:g>
</svg:g>
<svg:g
  ngx-charts-timeline
  *ngIf="timeline && scaleType != 'ordinal'"
  [attr.transform]="timelineTransform"
  [results]="results"
  [view]="[timelineWidth, height]"
  [height]="timelineHeight"
  [scheme]="scheme"
  [customColors]="customColors"
  [scaleType]="scaleType"
  [legend]="legend"
  (onDomainChange)="updateDomain($event)"
>
  <svg:g *ngFor="let series of results; trackBy: trackBy">
    <svg:g
      app-charts-line-series
      [xScale]="timelineXScale"
      [yScale]="timelineYScale"
      [colors]="colors"
      [data]="series"
      [scaleType]="scaleType"
      [curve]="curve"
      [hasRange]="hasRange"
      [animations]="animations"
    />
  </svg:g>
</svg:g>
</ngx-charts-chart>

<ng-template #seriesTooltipTemplate let-model="model">
  <xhtml:div class="area-tooltip-container">
    <xhtml:div *ngFor="let tooltipItem of model" class="tooltip-item">
      {{ tooltipItem.value == null || tooltipItem.value == 0.01 ? ('common.labels.noData' | translate) : tooltipItem.value }}
    </xhtml:div>
  </xhtml:div>
</ng-template>

<ng-template #tooltipTemplate let-model="model">
  <ng-container [ngSwitch]="model.value == null || model.value === 0.01">
    <span *ngSwitchCase="true">Data not available</span>
    <span *ngSwitchCase="false">{{model.value}} {{model.series}} {{model.name}}</span>
  </ng-container>
</ng-template>
import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { IActionEvent, IBottomAction } from 'src/app/components';
import { IDocument } from 'src/app/models/document';
import { IGraphItem, ILineGraphItem, IStringListItem } from 'src/app/models/listItem';
import { EProjectStatus, IProject } from 'src/app/models/project';
import { IRatingTableItem } from 'src/app/models/rating';
import { IRatingHistory } from 'src/app/models/ratingHistory';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiService } from 'src/app/services/api/api.service';
import { InternationalizationService } from 'src/app/services/internationalization/internationalization.service';

@Component({
  selector: 'app-project-panel',
  templateUrl: './project-panel.component.html',
  styleUrl: './project-panel.component.scss'
})
export class ProjectPanelComponent implements OnInit {

  project: IProject;

  isProjectLoading = true;

  isRatingLoading = true;
  ratingData: IRatingHistory[] = [];

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private router: Router,
    private api: ApiService,
    public internationalizationService: InternationalizationService,
    private alert: AlertService,
  ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id != null) {
      const numId = Number(id);
      this.loadData(numId);
    }
    else {
      this.location.back();
    }

    this.api.getDocumentTypes().subscribe((types) => {
      this.documentTypes = types;
    });
  }

  loadData(id: number) {
    this.isProjectLoading = true;

    this.api.getProject(id).subscribe({
      next: (project) => {
        this.isProjectLoading = false;
        this.project = project;
        if (this.project.status === EProjectStatus.Closed) {
          this.bottomActions = [];
        }

        this.changeDocumentsPage();
        this.changeRatingsPage();
        this.getRatingGraphData();
      }, 
      error: (error) => { 
        this.location.back();
      }
    });
  }

  bottomActions: IBottomAction[] = [
    { key: 'close', name: "project.actions.close", side: 'left', style: 'warn' },
  ];

  onActionClicked(action: IActionEvent) {
    switch (action.actionKey) {
      case 'close':
        this.alert.showAlert({
          type: "warning",
          acceptText: "common.actions.confirm",
          cancelText: "common.actions.cancel",
          message: "project.alerts.closeProject",
          onConfirm: () => { 
            this.api.closeProject(this.project.id).subscribe(() => {
              this.alert.dismissAlert();
              this.loadData(this.project.id);
            })
          }
        })
        break;
    }
  }
  onTitleClick() {
    this.router.navigate(['/projectsAndSuppliers/projects']);
  }

  @ViewChild("requestSummary") requestSummary: TemplateRef<any>;
  @ViewChild("supplierSummary") supplierSummary: TemplateRef<any>;

  isRequestSummaryVisible = false;
  isSupplierSummaryVisible = false;
  openRequestDetails() {
    this.isRequestSummaryVisible = true;
    this.alert.showAlert({
      size: 'large',
      templateRef: this.requestSummary,
      buttons: [{ 
        text: "common.actions.close",
        background: "",
        color: "",
        onClick: () => {
          this.alert.dismissAlert();
          this.isRequestSummaryVisible = false;
      }}]  
    })
  }
  openSupplierDetails() {
    this.isSupplierSummaryVisible = true;
    this.router.navigate(['/projectsAndSuppliers/supplier-panel/', this.project.supplier.id]);
  }

  editProject() {
    this.router.navigate(['/projectsAndSuppliers/project-edit/', this.project.id]);
  }

  
  // #region documents
  addDocumentClicked() {
    this.documentForm.reset({
      name: null,
      type: null,
      url: null,
    });
    
    this.alert.showAlert({
      templateRef: this.insertDocumentModalTemplate,
      buttons: [{ 
        text: "common.actions.cancel",
        background: "",
        color: "",
        onClick: () => {
          this.alert.dismissAlert();
      }}, { 
        text: "common.actions.confirm",
        background: "primary",
        color: "",
        onClick: () => {
          if (this.documentForm.valid) {
            this.api.addDocument({
              projectId: this.project.id,
              name: this.documentForm.value.name,
              type: this.documentForm.value.type,
              url: this.documentForm.value.url,
            }).subscribe({
              next: () => {
                this.alert.dismissAlert();
                this.changeDocumentsPage(0);

                const index = this.project.documents.findIndex((d) => d.type === this.documentForm.value.type);
                if (index != -1) {
                  this.project.documents[index].flagComplete = true;
                }
              },
              error: (err) => {
                // TODO: show error
              }
            })
          } else {
            this.documentForm.markAllAsTouched();
          }
      }}],
    })
  }

  @ViewChild("insertDocumentModalTemplate") insertDocumentModalTemplate: TemplateRef<any>;
  documentTypes: IStringListItem[] = [];
  documentForm = this.fb.group({
    name: [null, Validators.required],
    type: [null, Validators.required],
    url: [null, Validators.required],
  });
  isDocumentsLoading = true;
  documentsDataSource = new MatTableDataSource<IDocument>([]);
  documentsPageData = {
    index: 0,
    size: 5,
    totalElements: 0,
    sort: { active: "insertDate", direction: "desc" } as Sort | null,
  }
  documentsDisplayedColumns: string[] = [ 
    "insertDate",
    "type",
    "url",
    "actions"
  ];

  toggleCompleteFlag() {
    this.api.setFlagDocumentsComplete(this.project.id, !this.project.flagDocumentsComplete).subscribe(() => {
      this.loadData(this.project.id);
    });
  }

  deleteDocumentClicked(document: IDocument) {
    this.alert.showAlert({
      type: "warning",
      acceptText: "common.actions.delete",
      message: this.translateService.instant('document.alerts.deleteDocument', { document: document.name }),
      onConfirm: () => { 
        this.api.removeDocument(this.project.id, document.id).subscribe(() => {
          this.alert.dismissAlert();
          this.loadData(this.project.id);
        })
      }
    })
  }


  changeDocumentsPage(pageNumber?: number, pageSize?: number, sort?: Sort) {
    this.isDocumentsLoading = true;
    
    if (pageSize != null) this.documentsPageData.size = pageSize;
    if (pageNumber != null) this.documentsPageData.index = pageNumber;
    if (sort) this.documentsPageData.sort = sort;
    
    this.api.getPagedProjectDocuments({ projectId: this.project.id }, 
      this.documentsPageData.index,
      this.documentsPageData.size,
      this.documentsPageData.sort 
    ).subscribe((docs) => {
      this.isDocumentsLoading = false;
      this.documentsPageData.totalElements = docs.totalElements;
      this.documentsDataSource.data = docs.content;
    })
  }

  // #endregion

  // #region Ratings
  isRatingsLoading = true;
  ratingsDataSource = new MatTableDataSource<IRatingTableItem>([]);
  ratingsPageData = {
    index: 0,
    size: 5,
    totalElements: 0,
    sort: { active: "insertDate", direction: "desc" } as Sort | null,
  }
  ratingsDisplayedColumns: string[] = [ 
    "insertDate",
    "year",
    "quarter",
    "rating",
    "rating-actions",
  ];
  changeRatingsPage(pageNumber?: number, pageSize?: number, sort?: Sort) {
    this.isRatingsLoading = true;
    
    if (pageSize != null) this.ratingsPageData.size = pageSize;
    if (pageNumber != null) this.ratingsPageData.index = pageNumber;
    if (sort) this.ratingsPageData.sort = sort;
    
    this.api.getPagedProjectRatings({ projectId: this.project.id }, 
      this.ratingsPageData.index,
      this.ratingsPageData.size,
      this.ratingsPageData.sort 
    ).subscribe((docs) => {
      this.isRatingsLoading = false;
      this.ratingsPageData.totalElements = docs.totalElements;
      this.ratingsDataSource.data = docs.content;
    })
  }

  deleteRatingClicked(rating: IRatingTableItem) {
    this.alert.showAlert({
      type: "warning",
      acceptText: "common.actions.delete",
      message: this.translateService.instant('rating.alerts.deleteRating', { insertDate: moment(rating.insertDate).format( this.internationalizationService.getDateFormatPipeString().toUpperCase() ) }),
      onConfirm: () => { 
        this.api.removeRating(this.project.id, rating.id).subscribe(() => {
          this.alert.dismissAlert();
          this.changeRatingsPage(0);
          this.getRatingGraphData();
        })
      }
    })
  }

  @ViewChild("ratingViewModal") ratingViewModal: TemplateRef<any>;
  openRatingModal(rating: IRatingTableItem) {
    this.api.getRatingDetail(this.project.id, rating.id).subscribe((res) => {
      this.alert.showAlert({
        templateRef: this.ratingViewModal,
        templateContext: { rating: rating, answers: res },
        size: "medium",
        buttons: [{ 
          text: "common.actions.close",
          background: "",
          color: "",
          onClick: () => {
            this.alert.dismissAlert();
        }}],
      });
    });
  }

  addRatingClicked() {
    this.router.navigate(['/projectsAndSuppliers/add-rating/', this.project.id]);
  }

  yearsLabels: number[] = (() => {
    const year = new Date().getFullYear();
    return [
      year -1,
      year,
    ]
  })();
  graphData: ILineGraphItem[] = [];
  graphMinDate: Date;
  graphMaxDate: Date;
  getRatingGraphData() {
    this.api.getProjectRatingGraphData(this.project.id).subscribe((data) => {
      this.graphMinDate = new Date(this.project.startDate);
      this.graphMaxDate = new Date(this.project.endDate);

      const now = new Date();
      if (now.getTime() < this.graphMinDate.getTime()) {
        this.graphMinDate = now;
      }

      this.ratingData = data;
      this.isRatingLoading = false;
    });
  }
  getGraphLabel(label: string) {
    return label.split("_")[1];
  }
  removeDecimal(label: string) {
    return label.toString().split(".")[0];
  }

  // #endregion

  getSupplierTime() { 
    if (!this.project || !this.project.timeZone) return "-"

    return new Date().toLocaleString(undefined, { 
      timeZone: this.project.timeZoneLocale,
      formatMatcher: 'basic'
    });
  }
}

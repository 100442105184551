<img class="background" src="/assets/images/login-background.png" alt="login-background">

<div class="container">
  <div class="panel">
      <img src="../../../assets/images/logo-large.png" alt="logo" />

      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" type="email" placeholder="Email" required />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" type="password" placeholder="Password" required />
        </mat-form-field>

        <mat-slide-toggle color="primary" formControlName="rememberMe"><span>{{ 'login.rememberMe' | translate }}</span></mat-slide-toggle>
   
        <div class="login-error">
          <span *ngIf="loginFailedMessage != ''">{{ loginFailedMessage | translate }}</span>
        </div>

        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!loginForm.valid"
        >
          Login
        </button>

        <div class="lang-selector">
          <img *ngFor="let lang of internationalizationService.LANGS" src="../../../assets/icons/flags/{{ lang.value }}.svg" [class.selected]="internationalizationService.currentLang == lang.value" [alt]="lang.viewValue" (click)="internationalizationService.useLang(lang.value)">
        </div>
      </form>
  </div>
</div>
<app-page-container
  [pageTitleParts]="[ 'project-wizard.title' ]"
  [bottomActions]="contextActions"
  [headerContent]="stepsTemplate"
  (actionClicked)="onActionClicked($event)"
>
  <mat-stepper [linear]="false" [(selectedIndex)]="selectedIndex">
    <mat-step>
      <app-panel [style]="'default'">
        <span class="step-description">{{ 'project-wizard.step1.description' | translate }}</span>

        <span *ngIf="step1Form.touched && !step1Form.valid" class="error-label full-width mat-mdc-form-field-error mat-mdc-form-field-bottom-align">{{ "project-wizard.errors.requestRequired" | translate }}</span>

        <div *ngIf="step1Form.controls.request.value">
          <span>{{ "project-wizard.selected-request" | translate: { request: step1Form.controls.request.value.title } }}</span>
        </div>

        <mat-form-field class="search-box">
          <mat-label>{{ "common.labels.search" | translate }}</mat-label>
          <input matInput [(ngModel)]="requestSearchbox" (ngModelChange)="changePage()" [placeholder]="'common.labels.search' | translate" #supplierSearch>
        </mat-form-field>

      </app-panel>

      <div class="table-container">
        <div class="loading" [class.is-loading]="isRequestPanelLoading === true">
          <mat-spinner color="primary" diameter="120"></mat-spinner>
        </div>
    
        <div class="table-summary">
          <span> {{ "project-wizard.step1.requestFound" | translate : { shownElements: dataSource.data.length, totalElements: pageData.totalElements } }} </span>
        </div>

        <div class="inner-table-container">
          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="changePage(null, null, $event)" style="min-width: 100%;">
          
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "project.fields.title" | translate }} </th>
              <td mat-cell *matCellDef="let project"> {{project.title}} </td>
            </ng-container>

            <ng-container matColumnDef="tribe">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "project.fields.tribe" | translate }} </th>
              <td mat-cell *matCellDef="let project"> {{ project.businessLine }} - {{project.tribe }} </td>
            </ng-container>

            <ng-container matColumnDef="service">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "project.fields.technology" | translate }} </th>
              <td mat-cell *matCellDef="let project"> 
                <app-ellipsis [text]="project.catalog"></app-ellipsis>
              <td>
            </ng-container>

            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "project.fields.startDate" | translate }} </th>
              <td mat-cell *matCellDef="let project"> {{ project.startDate | date: internationalizationService.getDateFormatPipeString() }} </td>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "project.fields.endDate" | translate }} </th>
              <td mat-cell *matCellDef="let project"> {{ project.endDate | date: internationalizationService.getDateFormatPipeString() }} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="sticky-col"> </th>
              <td mat-cell *matCellDef="let element" class="sticky-col"> 
                
                <mat-radio-button name="selected-request" ngDefaultControl [checked]="isRequestSelected(element)" (change)="setSelectedRequest(element)"></mat-radio-button>

              </td>
            </ng-container>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell">
                {{ "common.labels.noData" | translate }}
              </td>
            </tr>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator 
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 50]" 
            [pageSize]="pageData.size" 
            [length]="pageData.totalElements" 
            [pageIndex]="pageData.index"
            (page)="changePage($event.pageIndex, $event.pageSize)"
          ></mat-paginator>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <app-panel [style]="'default'">
        <span class="step-description">{{ 'project-wizard.step2.description' | translate }}</span>

        <form [formGroup]="step2Form">
          <mat-form-field class="full-width">
            <mat-label>{{ "project.fields.title" | translate }}</mat-label>
            <input matInput formControlName="title" [placeholder]="'project.fields.title' | translate">
            <mat-error>{{ getFieldError('title') | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="requestInfo != null">
            <mat-label>Business Line</mat-label>
            <input matInput disabled [ngModel]="requestInfo.businessLine" [ngModelOptions]="{standalone: true}">
          </mat-form-field>

          <mat-form-field *ngIf="requestInfo != null">
            <mat-label>{{ "request.fields.tribe" | translate }}</mat-label>
            <input matInput disabled [ngModel]="requestInfo.tribe" [ngModelOptions]="{standalone: true}">
          </mat-form-field>

          <mat-form-field *ngIf="requestInfo != null">
            <mat-label>{{ "request.fields.lead" | translate }}</mat-label>
            <input matInput disabled [ngModel]="requestInfo.lead" [ngModelOptions]="{standalone: true}">
          </mat-form-field>

          <mat-form-field *ngIf="requestInfo != null">
            <mat-label>{{ "request.fields.serviceType" | translate }}</mat-label>
            <input matInput disabled [ngModel]="requestInfo.serviceType" [ngModelOptions]="{standalone: true}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ "project.fields.tpo" | translate }}</mat-label>
            <mat-select formControlName="tpoId" [placeholder]="'project.fields.tpo' | translate" (opened)="tposFilter.value = ''" >
              <div class="select-search">
                <mat-form-field>
                  <mat-label>{{ "common.labels.search" | translate }}</mat-label>
                  <input matInput [placeholder]="'common.labels.search' | translate" #tposFilter>
                </mat-form-field>
              </div>
              <mat-option *ngFor="let tpo of tpoList" [value]="tpo.id" [attr.data-hidden]="!tpo.value.toLowerCase().includes(tposFilter.value.toLowerCase())" >
                {{ tpo.value }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getFieldError('tpoId') | translate }}</mat-error>
          </mat-form-field>
          
          <mat-form-field>
            <mat-label>{{ "project.fields.contractManager" | translate }}</mat-label>
            <mat-select formControlName="contractManagerId" [placeholder]="'project.fields.contractManager' | translate" (opened)="contractManagersFilter.value = ''" >
              <div class="select-search">
                <mat-form-field>
                  <mat-label>{{ "common.labels.search" | translate }}</mat-label>
                  <input matInput [placeholder]="'common.labels.search' | translate" #contractManagersFilter>
                </mat-form-field>
              </div>
              <mat-option *ngFor="let contractManager of contractManagers" [value]="contractManager.id" [attr.data-hidden]="!contractManager.value.toLowerCase().includes(contractManagersFilter.value.toLowerCase())" >
                {{ contractManager.value }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getFieldError('contractOwnerId') | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ "project.fields.contractOwner" | translate }}</mat-label>
            <mat-select formControlName="contractOwnerId" [placeholder]="'project.fields.contractOwner' | translate" (opened)="contractOwnersFilter.value = ''" >
              <div class="select-search">
                <mat-form-field>
                  <mat-label>{{ "common.labels.search" | translate }}</mat-label>
                  <input matInput [placeholder]="'common.labels.search' | translate" #contractOwnersFilter>
                </mat-form-field>
              </div>
              <mat-option *ngFor="let contractOwner of contractOwners" [value]="contractOwner.id" [attr.data-hidden]="!contractOwner.value.toLowerCase().includes(contractOwnersFilter.value.toLowerCase())" >
                {{ contractOwner.value }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getFieldError('contractOwnerId') | translate }}</mat-error>
          </mat-form-field>
         
          <mat-form-field class="full-width">
            <mat-label>{{ "project.fields.externalFTE" | translate }}</mat-label>
            <input matInput (keydown)="onRateKeyDown($event)" type="number" formControlName="externalFTE" [placeholder]="'project.fields.externalFTE' | translate">
            <mat-error>{{ getFieldError('externalFTE') | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field class="span-4">
            <mat-label>{{ "project.fields.technology" | translate }}</mat-label>
            <mat-select formControlName="technologyId" [placeholder]="'project.fields.technology' | translate" (opened)="catalogsFilter.value = ''" >
              <div class="select-search">
                <mat-form-field>
                  <mat-label>{{ "common.labels.search" | translate }}</mat-label>
                  <input matInput [placeholder]="'common.labels.search' | translate" #catalogsFilter>
                </mat-form-field>
              </div>
              <mat-option *ngFor="let catalog of catalogs" [value]="catalog.id" [attr.data-hidden]="!catalog.value.toLowerCase().includes(catalogsFilter.value.toLowerCase())" >
                {{ catalog.value }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getFieldError('technologyId') | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field class="span-4">
            <mat-label>{{ "project.fields.fteRole" | translate }}</mat-label>
            <mat-select formControlName="fteRoleId" [placeholder]="'project.fields.fteRole' | translate" (opened)="fteRolesFilter.value = ''" >
              <div class="select-search">
                <mat-form-field>
                  <mat-label>{{ "common.labels.search" | translate }}</mat-label>
                  <input matInput [placeholder]="'common.labels.search' | translate" #fteRolesFilter>
                </mat-form-field>
              </div>
              <mat-option *ngFor="let fteRole of FTERoles" [value]="fteRole.id" [attr.data-hidden]="!fteRole.value.toLowerCase().includes(fteRolesFilter.value.toLowerCase())" >
                {{ fteRole.value }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getFieldError('fteRoleId') | translate }}</mat-error>
          </mat-form-field>


         <!--  <mat-form-field class="full-width">
            <mat-label>{{ "project.fields.serviceType" | translate }}</mat-label>
            <mat-select formControlName="serviceTypeId" [placeholder]="'project.fields.serviceType' | translate" (opened)="serviceTypesFilter.value = ''" >
              <div class="select-search">
                <mat-form-field>
                  <mat-label>{{ "common.labels.search" | translate }}</mat-label>
                  <input matInput [placeholder]="'common.labels.search' | translate" #serviceTypesFilter>
                </mat-form-field>
              </div>
              <mat-option *ngFor="let serviceType of serviceTypes" [value]="serviceType.id" [attr.data-hidden]="!serviceType.value.toLowerCase().includes(serviceTypesFilter.value.toLowerCase())" >
                {{ serviceType.value }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getFieldError('serviceTypeId') | translate }}</mat-error>
          </mat-form-field> -->

          <mat-form-field class="full-width">
            <mat-label>{{ "project.fields.contract" | translate }}</mat-label>
            <mat-select formControlName="contractId" placeholder="{{ 'project.fields.contract' | translate }}">
              <mat-option *ngFor="let contract of contracts" [value]="contract.id">
                {{ contract.value }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getFieldError('contractId') | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ "project.fields.startDate" | translate }}</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="startDate">
            <mat-hint>{{ internationalizationService.getDateFormatString() }}</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker1">
              <mat-icon matDatepickerToggleIcon>
                <img src="/assets/icons/calendar.svg" alt="calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker1 [calendarHeaderComponent]="datepickerHeaderComponent">
            </mat-datepicker>
            <mat-error>{{ getFieldError('startDate') | translate }}</mat-error>
          </mat-form-field>
      
          <mat-form-field>
            <mat-label>{{ "project.fields.endDate" | translate }}</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="endDate" [min]="step2Form.controls.startDate.value">
            <mat-hint>{{ internationalizationService.getDateFormatString() }}</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker2">
              <mat-icon matDatepickerToggleIcon>
                <img src="/assets/icons/calendar.svg" alt="calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker2 [calendarHeaderComponent]="datepickerHeaderComponent"></mat-datepicker>
            <mat-error>{{ getFieldError('endDate') | translate }}</mat-error>
          </mat-form-field>
      
          <mat-form-field>
            <mat-label>{{ "project.fields.dueDate" | translate }}</mat-label>
            <input matInput [matDatepicker]="picker3" formControlName="dueDate" [min]="step2Form.controls.startDate.value">
            <mat-hint>{{ internationalizationService.getDateFormatString() }}</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker3">
              <mat-icon matDatepickerToggleIcon>
                <img src="/assets/icons/calendar.svg" alt="calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker3 [calendarHeaderComponent]="datepickerHeaderComponent"></mat-datepicker>
            <mat-error>{{ getFieldError('dueDate') | translate }}</mat-error>
          </mat-form-field>
              
          <mat-form-field class="full-width">
            <mat-label>{{ "project.fields.miscellous" | translate }}</mat-label>
            <textarea matInput formControlName="miscellous" [placeholder]="'project.fields.miscellous' | translate"></textarea>
            <mat-error>{{ getFieldError('miscellous') | translate }}</mat-error>
          </mat-form-field>

        </form>
      </app-panel>
    </mat-step>
    <mat-step>

      <ng-container *ngIf="requestInfo != null" [ngSwitch]="requestInfo.serviceType">
        <ng-container *ngSwitchCase="'Shared'">
          <ng-container *ngTemplateOutlet="supplierListTemplate; context: { suppliers: getRequestSuppliersList('IntraGroup'), title: 'IntraGroup', name: 'selected-supplier' }"></ng-container>
          <ng-container *ngTemplateOutlet="supplierListTemplate; context: { suppliers: getRequestSuppliersList('SSI'), title: 'SSI', open: false, name: 'selected-supplier' }"></ng-container>
          <ng-container *ngTemplateOutlet="supplierListTemplate; context: { suppliers: getRequestSuppliersList('Locals'), title: 'Locals', open: false, name: 'selected-supplier' }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Specific'">
          <ng-container *ngTemplateOutlet="supplierListTemplate; context: { suppliers: getSpecificSupplier(), title: 'suppliers.title' | translate, open: false, name: 'selected-supplier' }"></ng-container>
        </ng-container>
      </ng-container>

      <app-accordion-panel [open]="false" [style]="'default'" [title]="'project-wizard.step3.suppliersList' | translate">
        <span class="step-description">{{ 'project-wizard.step3.description' | translate }}</span>
        <form [formGroup]="step3Form">
          <span *ngIf="step3Form.touched && !step3Form.valid" class="error-label full-width mat-mdc-form-field-error mat-mdc-form-field-bottom-align">{{ "project-wizard.errors.supplierRequired" | translate }}</span>
          <mat-form-field class="full-width">
            <mat-label>{{ "project.fields.supplier" | translate }}</mat-label>
            <mat-select formControlName="supplier" [placeholder]="'project.fields.supplier' | translate">
              <div class="select-search">
                <mat-form-field>
                  <mat-label>{{ "common.labels.search" | translate }}</mat-label>
                  <input matInput [placeholder]="'common.labels.search' | translate" #supplierFilter>
                </mat-form-field>
              </div>
              <mat-option *ngFor="let supplier of activeSuppliersList" [value]="supplier" [attr.data-hidden]="!supplier.name.toLowerCase().includes(supplierFilter.value.toLowerCase())">
                {{ supplier.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </app-accordion-panel>
    </mat-step>
    <mat-step>
      <app-panel [open]="true" [title]="'project-wizard.step4.summary' | translate" [isLoading]="requestInfo == null || supplierInfo == null">
        <div class="summary" *ngIf="requestInfo != null && supplierInfo != null">
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "request.fields.title" | translate }}
            </div>
            <div class="summary-item-value">
              {{ step2Form.value.title || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project-wizard.step4.selectedRequestSummary" | translate }}
            </div>
            <div class="summary-item-value">
              {{ requestInfo.title || "-" }}
              <img src="/assets/icons/info.svg" (click)="openRequestDetails()">
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.tribe" | translate }}
            </div>
            <div class="summary-item-value">
              {{ requestInfo.businessLine || "-" }} - {{ requestInfo.tribe || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.lead" | translate }}
            </div>
            <div class="summary-item-value">
              {{ requestInfo.lead || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.serviceType" | translate }}
            </div>
            <div class="summary-item-value">
              {{ requestInfo.serviceType || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project-wizard.step4.selectedSupplierSummary" | translate }}
            </div>
            <div class="summary-item-value">
              {{ (supplierInfo && supplierInfo.name) || "-" }}
              <img src="/assets/icons/info.svg" (click)="openSupplierDetails()">
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.supplierCategory" | translate }}
            </div>
            <div class="summary-item-value">
              {{ supplierInfo.category || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.tpo" | translate }}
            </div>
            <div class="summary-item-value">
              {{ getTPOName(step2Form.value.tpoId) || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.contractManager" | translate }}
            </div>
            <div class="summary-item-value">
              {{ getContractManagerName(step2Form.value.contractManagerId) || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.contractOwner" | translate }}
            </div>
            <div class="summary-item-value">
              {{ getContractOwnerName(step2Form.value.contractOwnerId) || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.externalFTE" | translate }}
            </div>
            <div class="summary-item-value">
              {{ step2Form.value.externalFTE || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.technology" | translate }}
            </div>
            <div class="summary-item-value">
              {{ getCatalogName(step2Form.value.technologyId) || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.fteRole" | translate }}
            </div>
            <div class="summary-item-value">
              {{ getFTERoleName(step2Form.value.fteRoleId) || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.serviceType" | translate }}
            </div>
            <div class="summary-item-value">
              {{ requestInfo.serviceType || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.contract" | translate }}
            </div>
            <div class="summary-item-value">
              {{ getContract(step2Form.value.contractId) || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.startDate" | translate }}
            </div>
            <div class="summary-item-value">
              {{ step2Form.value.startDate | date: internationalizationService.getDateFormatPipeString() || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.endDate" | translate }}
            </div>
            <div class="summary-item-value">
              {{ step2Form.value.endDate | date: internationalizationService.getDateFormatPipeString() || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.dueDate" | translate }}
            </div>
            <div class="summary-item-value">
              {{ step2Form.value.dueDate | date: internationalizationService.getDateFormatPipeString() || "-" }}
            </div>
          </div>
          <div class="summary-item">
            <div class="summary-item-label">
              {{ "project.fields.miscellous" | translate }}
            </div>
            <div class="summary-item-value">
              {{ step2Form.value.miscellous || "-" }}
            </div>
          </div>
        </div>
      </app-panel>
    </mat-step>
  </mat-stepper>
</app-page-container>

<ng-template #stepsTemplate >
  <app-stepper-header [steps]="steps" [selectedStep]="selectedIndex" (selectedStepChange)="setStep($event)"></app-stepper-header>
</ng-template>

<div class="loading" [class.is-loading]="isPageLoading === true">
  <mat-spinner color="primary" diameter="120"></mat-spinner>
</div>

<ng-template #supplierListTemplate let-suppliers="suppliers" let-title="title" let-name="name" let-open="open">
  <app-accordion-panel [style]="'default'" [open]="open" title="{{ title }} ({{ 'common.labels.found' | pluralTranslate:suppliers.length | translate: { items: suppliers.length } }})">
    <span class="step-description">{{ 'request-wizard.step3.description' | translate }}</span>

    <div class="suppliers-list">
      <div *ngFor="let supplier of suppliers" class="supplier-list-item" [class.selected]="isSupplierSelected(supplier)" (click)="selectedSupplierChanged(supplier)">
        <div>
          <mat-radio-button
            [name]="name"
            [checked]="isSupplierSelected(supplier)"
            (change)="selectedSupplierChanged(supplier)"
          ></mat-radio-button>
        </div>
        <div class="preferred-icon" *ngIf="supplier.order != null" [attr.preferred-position]="supplier.order + 1">
          <img src="/assets/icons/star/full.svg">
        </div>
        <div>
          {{ supplier.name }}
        </div>
        <div>
          <app-rating-view [rating]="supplier.rating" [ratingsNumber]="supplier.ratingsNumber"></app-rating-view>
        </div>
      </div>
      <div *ngIf="suppliers.length === 0" class="no-data">
        {{ "common.labels.noData" | translate }}
      </div>
    </div>
  </app-accordion-panel>
</ng-template>

<ng-template #requestSummary>
  <app-request-summary *ngIf="isRequestSummaryVisible" [requestId]="requestInfo.id"></app-request-summary>
</ng-template>
<ng-template #supplierSummary>
  <app-supplier-panel *ngIf="isSupplierSummaryVisible" [supplierId]="supplierInfo.id"></app-supplier-panel>
</ng-template>
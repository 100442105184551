import { ContentObserver } from '@angular/cdk/observers';
import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { concatMap, firstValueFrom } from 'rxjs';
import { DatepickerHeaderComponent, IActionEvent } from 'src/app/components';
import { IListItem } from 'src/app/models/listItem';
import { ERequestStatus, IRequest } from 'src/app/models/request';
import { ISupplier } from 'src/app/models/supplier';
import { IBusinessLine, ITribe } from 'src/app/models/user';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiService } from 'src/app/services/api/api.service';
import { InternationalizationService } from 'src/app/services/internationalization/internationalization.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-requests-list',
  templateUrl: './requests-list.component.html',
  styleUrls: ['./requests-list.component.scss']
})
export class RequestsListComponent implements OnInit {

  _ERequestStatus = ERequestStatus;
  datepickerHeaderComponent = DatepickerHeaderComponent;  
  @ViewChild("editNotesModalTemplate") editNotesModalTemplate: TemplateRef<any>;

  constructor (
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private alertService: AlertService,
    private userService: UserService,
    private translateService: TranslateService,
    private sharedService: SharedService,
    public internationalizationService: InternationalizationService,
  ) { }

  ngOnInit(): void {

    const f = this.userService.getSetting("requestsList_filters", true);
    if (f) {
      this.filterForm.patchValue(JSON.parse(f));
      this.filterForm.markAllAsTouched();
    }

    this.api.getBusinessLines().subscribe((businessLines) => {
      this.businessLines = businessLines;
    });    

    this.api.getServiceTypes().subscribe((serviceTypes) => {
      this.serviceTypes = serviceTypes;
    });

    this.api.getProjectTypes().subscribe((projectTypes) => {
      this.projectTypes = projectTypes;
    });

    this.api.getServices().subscribe((catalogs) => {
      this.catalogs = catalogs;
    });


    const p = this.userService.getSetting("requestsList_pageData");
    if (p) this.pageData = JSON.parse(p);

    this.getQueryParamFilters().then((editedFilters) => {
      if (editedFilters) return this.changePage(0);
      this.changePage();
    });
  }
  
  getQueryParamFilters() {
    return firstValueFrom(this.route.queryParams).then((params) => {
      if (params['filters']) {
        this.resetForm(false);
        try {
          this.filterForm.patchValue(JSON.parse(params['filters']));
        } catch (e) { 
          console.log("tried to patch value of filters form", params['filters']);
        }
        return true;
      } 
      return false;
    });
  }
  onActionClicked(action: IActionEvent) {
    switch (action.actionKey) {
      case 'toggleFilters': 
        this.showFilters = !action.status;
        break;
    }
  }

  //#region filters

  catalogs: IListItem[] = [];
  businessLines: IBusinessLine[] = [];
  projectTypes: IListItem[] = [];
  serviceTypes: IListItem[] = [];
  tribes: ITribe[] = [];
  onBuinessLineChange(value: number | null) {
    this.filterForm.controls.tribeId.setValue(null);

    if (value) {
      this.filterForm.controls.tribeId.enable();
      this.tribes = this.businessLines.find(b => b.id === value)?.tribes || [];
    } else {
      this.filterForm.controls.tribeId.disable();
      this.filterForm.controls.tribeId.setValue(null);
    }
  }

  showFilters = true;
  filterForm = this.fb.group({
    title: [null as string | null],
    statusCode: ['' as string | null],
    catalogId: [null as number | null],
    businessLineId: [null as number | null],
    tribeId: [{ value: null as string | null, disabled: true }],
    serviceTypeId: [0 as null | number],
    projectTypeId: [null as null | number],
    startDate: this.fb.group({
      from: [null],
      to: [null],
    }),
    endDate: this.fb.group({
      from: [null],
      to: [null],
    }),
  }, {
    updateOn: 'submit',
  });

  resetForm(changePage = true) {
    this.filterForm.reset({
      title: "",
      statusCode: "",
      catalogId: null,
      businessLineId: null,
      tribeId: null,
      serviceTypeId: 0,
      projectTypeId: null,
      startDate: {
        from: null,
        to: null,
      },
      endDate: {
        from: null,
        to: null,
      },
    });
    this.onBuinessLineChange(null);

    if (changePage) this.changePage(0);
    this.userService.deleteSetting("requestsList_filters", true);
  }

  savedFilters: { [key: string]: any } = {};

  //#endregion

  //#region table

  isLoading = true; 
  pageData = {
    index: 0,
    size: 10,
    totalElements: 0,
    sort: { active: "creationDate", direction: "desc" } as Sort | null,
  }
  displayedColumns: string[] = [ 
    "status",
    "title",
    "projectType",
    "serviceType",
    "tribe",
    "user",
    "creationDate",
    "startDate",
    "endDate",
    "technology",
    "actions",
  ];

  dataSource = new MatTableDataSource<IRequest>([]);
  changePage(pageNumber?: number, pageSize?: number, sort?: Sort) {
    this.isLoading = true;

    if (pageSize != null) this.pageData.size = pageSize;
    if (pageNumber != null) this.pageData.index = pageNumber;
    if (sort) this.pageData.sort = sort;
    
    const fc = this.filterForm.value;
    const filters: { [key: string]: string | number | string[] | number[] } = {};

    if (fc.title) filters["title"] = fc.title;
    if (fc.businessLineId != null) filters["businessLineId"] = fc.businessLineId;
    if (fc.tribeId != null) filters["tribeId"] = fc.tribeId;
    if (fc.serviceTypeId != 0) filters["serviceTypeId"] = fc.serviceTypeId;
    if (fc.projectTypeId != null) filters["projectTypeId"] = fc.projectTypeId;
    if (fc.catalogId != null) filters["catalogId"] = fc.catalogId;
    if (fc.statusCode != null && fc.statusCode != '') filters["status"] = fc.statusCode;

    if (fc.startDate.from != null) filters["startDateFrom"] = this.internationalizationService.ignoreDateTimezone(fc.startDate.from).toISOString();
    if (fc.startDate.to != null) filters["startDateTo"] = this.internationalizationService.ignoreDateTimezone(fc.startDate.to).toISOString();

    if (fc.endDate.from != null) filters["endDateFrom"] = this.internationalizationService.ignoreDateTimezone(fc.endDate.from).toISOString();
    if (fc.endDate.to != null) filters["endDateTo"] = this.internationalizationService.ignoreDateTimezone(fc.endDate.to).toISOString();

    
    if (this.filterForm.touched) this.userService.saveSetting("requestsList_filters", JSON.stringify(this.filterForm.value), true);
    this.userService.saveSetting("requestsList_pageData", JSON.stringify(this.pageData), true);

    this.api.getPagedRequests(filters, this.pageData.index, this.pageData.size, this.pageData.sort || { active: "creationDate", direction: "desc" }).subscribe({ 
      next: (requests) => {
        this.dataSource.data = requests.content;
        this.pageData.totalElements = requests.totalElements;
        this.isLoading = false;
      }, 
      error: () => { 
        this.isLoading = false; 
      }
    });
  }
  exportClicked() {
    this.isLoading = true;

    const fc = this.filterForm.value;
    const filters: { [key: string]: string | number | string[] | number[] } = {};

    if (fc.title) filters["title"] = fc.title;
    if (fc.businessLineId != null) filters["businessLineId"] = fc.businessLineId;
    if (fc.tribeId != null) filters["tribeId"] = fc.tribeId;
    if (fc.serviceTypeId != 0) filters["serviceTypeId"] = fc.serviceTypeId;
    if (fc.projectTypeId != null) filters["projectTypeId"] = fc.projectTypeId;
    if (fc.catalogId != null) filters["catalogId"] = fc.catalogId;
    if (fc.statusCode != null && fc.statusCode != '') filters["status"] = fc.statusCode;

    if (fc.startDate.from != null) filters["startDateFrom"] = this.internationalizationService.ignoreDateTimezone(fc.startDate.from).toISOString();
    if (fc.startDate.to != null) filters["startDateTo"] = this.internationalizationService.ignoreDateTimezone(fc.startDate.to).toISOString();

    if (fc.endDate.from != null) filters["endDateFrom"] = this.internationalizationService.ignoreDateTimezone(fc.endDate.from).toISOString();
    if (fc.endDate.to != null) filters["endDateTo"] = this.internationalizationService.ignoreDateTimezone(fc.endDate.to).toISOString();

    this.api.exportRequests(filters, this.pageData.sort || { active: "creationDate", direction: "desc" }, this.pageData.totalElements).subscribe({ 
      next: (data) => {
        this.sharedService.createDownload(data, "application/csv");
        this.isLoading = false;
      }, 
      error: () => { 
        this.isLoading = false; 
      }
    });
  }

  infoClicked(request: IRequest) {
    this.router.navigate(['projectRequests/request-summary', request.id]);
  }

  addClicked() {
    this.router.navigate(['projectRequests/request-wizard']);
  }

  cancelRequestClick(request: IRequest) {
    this.alertService.showAlert({
      type: "warning",
      acceptText: "common.labels.yes",
      cancelText: "common.labels.no",
      message: this.translateService.instant('requests.alerts.cancelRequest', { request: request.title }),
      onConfirm: () => {
        this.alertService.dismissAlert();
        this.api.cancelRequest(request.id).subscribe(() => {
          this.changePage();
        });
      },
    });
  }

  createProjectClick(request: IRequest) {
    this.router.navigate(['/projectsAndSuppliers/project-wizard/', request.id ]);
  }

  editNotes(request: IRequest) {
    this.textAreaValue = request.notes;
    this.alertService.showAlert({
      templateRef: this.editNotesModalTemplate,
      templateContext: { notes: request.notes, requestId: request.id },
    })
  }

  //#endregion

  textAreaValue = "";
  editNotesAction(requestId: number, confirmEdit: boolean) {
    if (confirmEdit) {
      this.api.editRequestNote(requestId, this.textAreaValue).subscribe(() => {
        this.alertService.dismissAlert(); 
        this.changePage();
      });
    } else {
      this.alertService.dismissAlert(); 
    }
  }
}

import { Location } from '@angular/common';
import { Component,OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IAction, IActionEvent, IBottomAction } from 'src/app/components';
import { IError } from 'src/app/models/error';
import { ERole, IBusinessLine, IEditUser } from 'src/app/models/user';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiService } from 'src/app/services/api/api.service';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  buildForm() {
    return this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(/@ing.com$/), this.beError.bind(this, "email")]],
      isActive: [ true as (boolean)],
      role: ['' as ERole, [Validators.required, this.beError.bind(this, "role")]],
      tribes: this.fb.array([
        this.fb.group({
          businessLineId: [null as number | null],
          tribeId: [{ value: null as number | null, disabled: true }],
        })
      ], { validators: [(this.isTribeAlreadyPresent.bind(this) as any), this.beError.bind(this, "tribes"), Validators.minLength(1)] }),
      newPassword: ['', [this.newPasswordRequired.bind(this), Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[$%£!=|;*?#\-:_\/\\])(?!.*\s).{8,}$/g)]], // controlla che ci sia almeno una maiuscola, una minuscola, un carattere speciale e che sia lunga almeno 8 caratteri
      confirmPassword: ['', this.confirmPasswordMatches.bind(this)],
    });
  }
  userForm = this.buildForm();

  isLoading = true;

  beErrors: { [key: string]: string } = { };

  contextActions: IBottomAction[] = [
    { key: 'save', name: "user.actions.add", side: 'right', style: 'primary' },
  ];
  userId?: number = null;
  businessLines: IBusinessLine[] = [];

  roles = [ /* ERole.Admin, */ ERole.CS, ERole.TechSourcing, ERole.Tribe ]

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private router: Router,
    private api: ApiService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    window.onbeforeunload = function() { return "Your work will be lost."; }


    this.api.getBusinessLines().subscribe((businessLines) => {
      this.businessLines = businessLines.map(b => {
        b.tribes = b.tribes.sort((a, b) => a.name.localeCompare(b.name))
        return b;
      }).sort((a, b) => a.name.localeCompare(b.name));
    });

    const id = this.route.snapshot.paramMap.get('id');
    if (id)
      this.loadData(Number(id));
    else 
      this.isLoading = false;
  }


  onActionClicked(action: IActionEvent) {
    switch (action.actionKey) {
      case 'delete':
        this.alertService.showAlert({
          type: "warning",
          acceptText: "common.actions.delete",
          message: this.translateService.instant('users.alerts.deleteUser', { user: this.userForm.controls.email.value }),
          onConfirm: () => {
            this.alertService.dismissAlert();
          },
        });
        break;
      case 'cancel':
        this.onTitleClick()
        break;
      case 'save':
        this.userForm.markAllAsTouched();
        const errors = this.sharedService.getFormErrors(this.userForm);
        if (this.userForm.valid || Object.values(errors).filter(e => e['beError'] !== true).length === 0) 
          this.submitForm();
        else {
          this.sharedService.focusOnInvalidInput(this.userForm)
        }
        break;
    }
  }

  addTribe(data: { businessLineId: number, tribeId: number } = {
    businessLineId: null,
    tribeId: null,
  }) {
    const tribe = this.fb.group({
      businessLineId: [data.businessLineId],
      tribeId: [{ value: data.tribeId, disabled: data.businessLineId == null }],
    });
    this.userForm.controls.tribes.push(tribe);
  }

  removeTribe(index: number) {
    const tribe = this.userForm.controls.tribes.controls[index].value;
    if (tribe.tribeId != null) {
      const bs = this.businessLines.find(b => b.id === tribe.businessLineId);
      const tr = bs.tribes.find(t => t.id === tribe.tribeId);

      this.alertService.showAlert({
        type: "warning",
        acceptText: "common.actions.delete",
        message: this.translateService.instant('user.alerts.deleteTribe', { tribe: bs.name + " - " + tr.name }),
        onConfirm: () => {
          this.alertService.dismissAlert();
          this.userForm.controls.tribes.removeAt(index);
        },
      });
      return;
    }
    this.userForm.controls.tribes.removeAt(index);
  }

  loadData(userId: number) {

    this.isLoading = true;
    this.api.getUser(userId).subscribe({
      next: (user) => {
      this.userId = userId;
      this.contextActions = [
        /* { key: 'delete', name: 'user.actions.delete', side: 'left', style: 'warn' }, */
        { key: 'cancel', name: 'common.actions.cancel', side: 'right' },
        { key: 'save', name: "user.actions.save", side: 'right', style: 'primary' },
      ];
      
      this.isLoading = false;
      this.userForm.controls.tribes.controls = [];
      this.userForm.setValue({
        email: user.email || '',
        role: user.role || null,
        isActive: user.isActive,
        newPassword: '',
        confirmPassword: '',
        tribes: [],
      });
      this.userForm.controls.email.disable();

      if (user.role === ERole.Admin) {
        this.roles.push(ERole.Admin);
        this.userForm.controls.role.disable();
      }

      user.tribes.forEach((tribe) => {
        this.addTribe({
          businessLineId: tribe.businessLineId,
          tribeId: tribe.tribeId,
        })
      });

      this.userForm.markAsUntouched()
    },
    error: () => {
      this.isLoading = false;
    }});
  }

  submitForm() {
    const user: IEditUser = {
      email: this.userForm.controls.email.value,
      role: this.userForm.controls.role.value,
      isActive: this.userForm.controls.isActive.value,
      tribes: this.userForm.controls.tribes.value.reduce((p, c) => {
        if (c.tribeId != null) {
          p.push(c.tribeId);
        }
        return p
      }, []),
    };

    if (this.userId != null) {
      user.id = this.userId;
      user.password = this.userForm.controls.newPassword.value;
    } else if (this.userForm.controls.newPassword.value) {
      user.password = this.userForm.controls.newPassword.value;
    }


    this.api.editUser(user).subscribe({
      next: () => {
        this.userForm.markAsUntouched();
        this.location.back();
      },
      error: (err: IError) => {
        this.beErrors = {};
        err.messages.forEach((m) => {
          this.beErrors[m.field] = m.key;

          //@ts-ignore
          const control = this.userForm.controls[m.field];
          if (control) {
            control.setErrors({ beError: true });
            this.sharedService.focusOnInvalidInput(this.userForm);
          }

        });
        
      }
    });
  }

  get hasUnsavedChanges() {
    return this.userForm.touched;
  }

  onNavigationOut() {
    if (this.hasUnsavedChanges) {
      return this.sharedService.showBackNavigationAlertAsync();
    }
    return true;
  }

  onTitleClick() {
    this.router.navigate(['/backoffice/manage-users']);
  }

  onBuinessLineChange(formIndex: number, value: number | null) {
    const tribeForm = this.userForm.controls.tribes.controls[formIndex];
    if (!tribeForm) return;

    tribeForm.controls.tribeId.setValue(null);

    if (value != null) {
      tribeForm.controls.tribeId.enable();
      tribeForm.controls.tribeId.setValidators(Validators.required);
      tribeForm.controls.tribeId.updateValueAndValidity();
    } else {
      tribeForm.controls.tribeId.disable();
      tribeForm.controls.tribeId.setValidators(null);
      tribeForm.controls.tribeId.updateValueAndValidity();
    }
  }


  // #region Custom validators
  isTribeAlreadyPresent(control: FormArray<FormGroup<{ tribeId: AbstractControl<number, number>, businessLineId: AbstractControl<number, number> }>>) {
    const tribesLength = Object.keys(control.controls.reduce((p, c) => { 
      if (!c.valid) return p;
      
      const value = c.value; 
      p[value.businessLineId + "_" + value.tribeId] = true; 
      return p;
    }, {} as any)).length;

    if (this.userForm && control.controls.filter(c => c.valid).length != tribesLength) {
      return { tribeAlreadyPresent: true };
    }
    return null;
  }
  newPasswordRequired(control: AbstractControl<string, string>) {
    if (this.userId == null && control.value === '') {
      return { required: true };
    }
    return null;
  }
  confirmPasswordMatches(control: AbstractControl<string, string>) {
    if (this.userForm && control.value !== this.userForm.controls.newPassword.value) {
      return { passwordMismatch: true };
    }
    return null;
  }
  beError(controlName: string, control: AbstractControl<string, string>) {
    if (this.beErrors && this.beErrors[controlName]) {
      return { beError: true };
    }
    return null;
  }
  // #endregion

  getFieldError(controlName: (keyof typeof this.userForm.controls)): string {
    const formControl = this.userForm.controls[controlName];

    if (formControl.hasError('required')) {
      return 'common.errors.requiredField';
    }
  
    if (formControl.hasError('pattern')){
      switch(controlName) {
        case "email": return 'user.errors.email.pattern';
        case "newPassword": return 'user.errors.password.pattern';
      } 
    }
    
    if (formControl.hasError('passwordMismatch')) {
      return 'user.errors.password.mismatch';
    }

    if (formControl.hasError('tribeAlreadyPresent')) {
      return 'user.errors.tribes.already_present';
    }

    if (this.beErrors[controlName]) {
      return 'user.errors.' + controlName + '.' + this.beErrors[controlName];
    }

    return ''
  }
  getFormErrors(): string {
    if (this.beErrors['form']) {
      return 'user.errors.form.' + this.beErrors['form'];
    }

    return ''
  }
  getTribesOf(businessLineId: number) {
    return this.businessLines.find((bl) => bl.id === businessLineId)?.tribes || [];
  }


}

<app-page-container [class.csm_user]="isCsmUser"
  [pageTitleParts]="[ 'Home' ]"
>
  <app-panel *ngIf="!isCsmUser" [title]="'home.requests.title' | translate" class="kpi-panel" [class.request-panel-highlight]="isHoverRequest" 
    (mouseenter)="isHoverRequest = true" (mouseleave)="isHoverRequest = false"
  >
    @if (requestKpi == null) {
      <ng-container *ngTemplateOutlet="kpiLoadingTemplateSkeleton"></ng-container>
    } @else {
      <div class="kpi-view">
        <div class="kpi-column" (click)="kpiClicked('requests', 'empty')">
          <span class="value"><h4>{{ requestKpi.kpi.submitted }}</h4></span>
          <span>{{ 'home.requests.submitted' | translate }}</span>
        </div>
        <div>
          <div class="kpi-column highlighted" (click)="kpiClicked('requests', 'inProgress')">
            <span class="value">{{ requestKpi.kpi.inProgress }}</span>
            <span>{{ 'home.requests.inProgress' | translate }}</span>
          </div>
          <div class="kpi-column" (click)="kpiClicked('requests', 'closed')">
            <span class="value">{{ requestKpi.kpi.closed }}</span>
            <span>{{ 'home.requests.closed' | translate }}</span>
          </div>
        </div>
      </div>
    }
  </app-panel>

  <app-panel [title]="'home.projects.title' | translate" class="kpi-panel" [class.project-panel-highlight]="isHoverProject" 
    (mouseenter)="isHoverProject = true" (mouseleave)="isHoverProject = false"
  >
    @if (projectKpi == null) {
      <ng-container *ngTemplateOutlet="kpiLoadingTemplateSkeleton"></ng-container>
    } @else {
      <div class="kpi-view">
        <div class="kpi-column" (click)="kpiClicked('projects', 'empty')">
          <span class="value"><h4>{{ projectKpi.kpi.submitted }}</h4></span>
          <span>{{ 'home.projects.submitted' | translate }}</span>
        </div>
        <div>
          <div class="kpi-column" (click)="kpiClicked('projects', 'open')">
            <span class="value">{{ projectKpi.kpi.open }}</span>
            <span>{{ 'home.projects.open' | translate }}</span>
          </div>
          <div class="kpi-column highlighted" (click)="kpiClicked('projects', 'expiring')">
            <span class="value">{{ projectKpi.kpi.expiring }}</span>
            <span>{{ 'home.projects.expiring' | translate }}</span>
          </div>
        </div>
      </div>
    }
  </app-panel>

  <app-panel [title]="'home.scorecards.title' | translate" class="kpi-panel" [class.scorecard-panel-highlight]="isHoverScorecard" 
    (mouseenter)="isHoverScorecard = true" (mouseleave)="isHoverScorecard = false"
  >
    @if (scorecardKpi == null) {
      <ng-container *ngTemplateOutlet="kpiLoadingTemplateSkeleton"></ng-container>
    } @else {
      <div class="kpi-view">
        <div class="kpi-column not-clickable" (click)="kpiClicked('scorecards', 'empty')">
          <span class="value"><h4>{{ scorecardKpi.kpi.submitted }}</h4></span>
          <span>{{ 'home.scorecards.submitted' | translate }}</span>
        </div>
        <div>
          <div class="kpi-column not-clickable">
            <span class="value">{{ scorecardKpi.kpi.inserted || 0 }}</span>
            <span>{{ 'home.scorecards.inserted' | translate }}</span>
          </div>
          <div class="kpi-column highlighted" (click)="kpiClicked('scorecards', 'toBeInserted')">
            <span class="value">{{ scorecardKpi.kpi.toBeInserted || 0  }}</span>
            <span>{{ 'home.scorecards.toBeInserted' | translate }}</span>
          </div>
        </div>
      </div>
    }
  </app-panel>

  <div *ngIf="!isCsmUser" class="table-container notification-table-container" [class.request-panel-highlight]="isHoverRequest" 
    (mouseenter)="isHoverRequest = true" (mouseleave)="isHoverRequest = false"
  >
    <div class="table-summary" style="padding-top: 18px; margin-bottom: 15px;">
      <h3 class="panel-title" style="margin: 0;">{{ "home.requests.inProgressRequests" | translate }}</h3>
    </div>

    <div class="inner-table-container notifications-inner-table-container"> 
      <div>
        <table class="mat-table mat-mdc-table mdc-data-table__table cdk-table" style="min-width: 100%; max-height: 100%;" *ngIf="requestKpi != null">
          <thead role="rowgroup">
            <tr class="mat-header-row mat-mdc-header-row mdc-data-table__header-row cdk-header-row">
              <th role="columnheader" class="mat-header-cell mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell ng-star-inserted">
                {{ "request.fields.title" | translate }}
              </th>
              <th role="columnheader" class="mat-header-cell mat-column-date mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell ng-star-inserted">
                {{ "request.fields.startDate" | translate }}
              </th>
              <th role="columnheader" class="mat-header-cell mat-column-actions mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell ng-star-inserted">
              </th>
            </tr>
          </thead>
          <tbody role="rowgroup" class="mdc-data-table__content">
            <tr class="mat-row" *ngFor="let request of requestKpi.alerts">
              <td role="row" class="mat-cell mdc-data-table__cell">
                <app-ellipsis [text]="request.title"></app-ellipsis>
              </td>
              <td role="row" class="mat-cell mdc-data-table__cell">
                {{ request.startDate | date: internationalizationService.getDateFormatPipeString() }}
              </td>
              <td role="row" class="mat-cell mdc-data-table__cell">
                <button mat-icon-button (click)="goToRequest(request.id)" [appTooltipNoHover]="'home.requests.goToRequest' | translate" matTooltipPosition="above">
                  <img src="/assets/icons/info.svg">
                </button>
              </td>
            </tr>
            <tr *ngIf="requestKpi.alerts.length === 0" class="mat-row mat-mdc-no-data-row" role="row">
              <td class="mat-cell"> 
                {{ "common.labels.noData" | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="table-container notification-table-container" [class.project-panel-highlight]="isHoverProject" 
    (mouseenter)="isHoverProject = true" (mouseleave)="isHoverProject = false"
  >
    <div class="table-summary" style="padding-top: 18px; margin-bottom: 15px;">
      <h3 class="panel-title" style="margin: 0;">{{ "home.projects.expiringProjects" | translate }}</h3>
    </div>

    <div class="inner-table-container notifications-inner-table-container"> 
     <div>
      <table class="mat-table mat-mdc-table mdc-data-table__table cdk-table" style="min-width: 100%; max-height: 100%;" *ngIf="projectKpi != null">
        <thead role="rowgroup">
          <tr class="mat-header-row mat-mdc-header-row mdc-data-table__header-row cdk-header-row">
            <th role="columnheader" class="mat-header-cell mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell ng-star-inserted">
              {{ "project.fields.title" | translate }}
            </th>
            <th role="columnheader" class="mat-column-date mat-header-cell mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell ng-star-inserted">
              {{ "project.fields.dueDate" | translate }}
            </th>
            <th role="columnheader" class="mat-column-date mat-header-cell mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell ng-star-inserted">
              {{ "project.fields.endDate" | translate }}
            </th>
            <th role="columnheader" class="mat-column-actions mat-header-cell mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell ng-star-inserted">
            </th>
          </tr>
        </thead>
        <tbody role="rowgroup" class="mdc-data-table__content">
          <tr class="mat-row" *ngFor="let project of projectKpi.alerts">
            <td role="row" class="mat-cell mdc-data-table__cell">
              <app-ellipsis [text]="project.title"></app-ellipsis>
            </td>
            <td role="row" class="mat-cell mdc-data-table__cell">
              {{ project.dueDate | date: internationalizationService.getDateFormatPipeString() }}
            </td>
            <td role="row" class="mat-cell mdc-data-table__cell">
              {{ project.endDate | date: internationalizationService.getDateFormatPipeString() }}
            </td>
            <td role="row" class="mat-cell mdc-data-table__cell">
              <button mat-icon-button (click)="goToProject(project.id)" [appTooltipNoHover]="'home.projects.goToProject' | translate" matTooltipPosition="above">
                <img src="/assets/icons/info.svg">
              </button>
            </td>
          </tr>
          <tr *ngIf="projectKpi.alerts.length === 0" class="mat-row mat-mdc-no-data-row" role="row">
            <td class="mat-cell"> 
              {{ "common.labels.noData" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
     </div>
    </div>
  </div>

  <div class="table-container notification-table-container" [class.scorecard-panel-highlight]="isHoverScorecard" 
    (mouseenter)="isHoverScorecard = true" (mouseleave)="isHoverScorecard = false"
  >
    <div class="table-summary" style="padding-top: 18px; margin-bottom: 15px;">
      <h3 class="panel-title" style="margin: 0;">{{ "home.scorecards.missingRatingProjects" | translate }}</h3>
    </div>

    <div class="inner-table-container notifications-inner-table-container"> 
      <div>
        <table class="mat-table mat-mdc-table mdc-data-table__table cdk-table" style="min-width: 100%; max-height: 100%;" *ngIf="scorecardKpi != null">
          <thead role="rowgroup">
            <tr class="mat-header-row mat-mdc-header-row mdc-data-table__header-row cdk-header-row">
              <th role="columnheader" class="mat-header-cell mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell ng-star-inserted">
                {{ "home.scorecards.project" | translate }}
              </th>
              <th role="columnheader" class="mat-header-cell mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell ng-star-inserted">
                {{ "project.fields.missingRatings" | translate }}
              </th>
              <th role="columnheader" class="mat-column-actions mat-header-cell mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell ng-star-inserted">
              </th>
            </tr>
          </thead>
          <tbody role="rowgroup" class="mdc-data-table__content">
            <tr class="mat-row" *ngFor="let project of scorecardKpi.alerts">
              <td role="row" class="mat-cell mdc-data-table__cell">
                <app-ellipsis [text]="project.title"></app-ellipsis>
              </td>
              <td role="row" class="mat-cell mdc-data-table__cell">
                {{ project.missingRatings }}
              </td>
              <td role="row" class="mat-cell mdc-data-table__cell">
                <button mat-icon-button (click)="goToProject(project.id)" [appTooltipNoHover]="'home.scorecards.goToScorecard' | translate" matTooltipPosition="above">
                  <img src="/assets/icons/info.svg">
                </button>
              </td>
            </tr>
            <tr *ngIf="scorecardKpi.alerts.length === 0" class="mat-row mat-mdc-no-data-row" role="row">
              <td class="mat-cell"> 
                {{ "common.labels.noData" | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


  <div class="table-container span-full">
    <div class="loading" [class.is-loading]="isDocumentsLoading === true">
      <mat-spinner color="primary" diameter="120"></mat-spinner>
    </div>

    <div class="table-summary">
      <span> {{ "home.documents.projectsFound" | pluralTranslate:documentsPageData.totalElements | translate : { totalElements: documentsPageData.totalElements } }} </span>
      <button mat-icon-button color="primary" type="button" (click)="kpiClicked('projects', 'missingDocs')">
        <img src="/assets/icons/arrow-up-outlined.svg" alt="go to list">
      </button>
    </div>

    <div class="inner-table-container"> 
      <table mat-table [dataSource]="documentsDataSource" style="min-width: 100%;"
        matSort [matSortActive]="documentsPageData.sort?.active" [matSortDirection]="documentsPageData.sort?.direction" (matSortChange)="changeDocumentsPage(null, null, $event)" 
      >

        <ng-container matColumnDef="projectTitle">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "home.documents.projectTitle" | translate }} </th>
          <td mat-cell *matCellDef="let project"> {{ project.title }} </td>
        </ng-container>
        <ng-container matColumnDef="ODS">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > ODS </th>
          <td mat-cell *matCellDef="let project">
              <img src="/assets/icons/{{ project.documents.ODS ? 'check' : 'warn-filled-lime' }}.svg" [matTooltip]="(project.documents.ODS ? 'document.status.complete' : 'document.status.incomplete') | translate" alt="document status">
          </td>
        </ng-container>
        <ng-container matColumnDef="TREE">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Decision Tree </th>
          <td mat-cell *matCellDef="let project">
              <img src="/assets/icons/{{ project.documents.TREE ? 'check' : 'warn-filled-lime' }}.svg" [matTooltip]="(project.documents.TREE ? 'document.status.complete' : 'document.status.incomplete') | translate" alt="document status">
          </td>
        </ng-container>
        <ng-container matColumnDef="ITSR">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ITSR </th>
          <td mat-cell *matCellDef="let project">
              <img src="/assets/icons/{{ project.documents.ITSR ? 'check' : 'warn-filled-lime' }}.svg" [matTooltip]="(project.documents.ITSR ? 'document.status.complete' : 'document.status.incomplete') | translate" alt="document status">
          </td>
        </ng-container>
        <ng-container matColumnDef="GDPR">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> GDPR </th>
          <td mat-cell *matCellDef="let project">
              <img src="/assets/icons/{{ project.documents.GDPR ? 'check' : 'warn-filled-lime' }}.svg" [matTooltip]="(project.documents.GDPR ? 'document.status.complete' : 'document.status.incomplete') | translate" alt="document status">
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let project">
            <button mat-icon-button (click)="goToProject(project.id)">
              <img src="/assets/icons/info.svg">
            </button>
          </td>
        </ng-container>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell">
            {{ "common.labels.noData" | translate }}
          </td>
        </tr>
      
        <tr mat-header-row *matHeaderRowDef="documentsDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: documentsDisplayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator 
      showFirstLastButtons
      [pageSizeOptions]="[5, 10, 15]" 
      [pageSize]="documentsPageData.size" 
      [length]="documentsPageData.totalElements" 
      [pageIndex]="documentsPageData.index"
      (page)="changeDocumentsPage($event.pageIndex, $event.pageSize)"
    ></mat-paginator>
  </div>

</app-page-container>


<ng-template #kpiLoadingTemplateSkeleton>
  <div class="kpi-view skeleton-loading">
    <div class="kpi-column">
      <span class="value is-loading"></span>
      <span class="is-loading"></span>
    </div>
    <div>
      <div class="kpi-column">
        <span class="value is-loading"></span>
        <span class="is-loading"></span>
      </div>
      <div class="kpi-column">
        <span class="value is-loading"></span>
        <span class="is-loading"></span>
      </div>
    </div>
  </div>
</ng-template>
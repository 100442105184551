<app-page-container
  [pageTitleParts]="[ 'requests.title' ]"
  [actions]="[
    { key: 'toggleFilters', icon: 'eye-closed.svg', name: 'common.actions.hideFilters', activeIcon: 'eye-open.svg', activeName: 'common.actions.showFilters' }
  ]"
  (actionClicked)="onActionClicked($event)"
>
  <app-panel  
    [open]="showFilters === true" 
    [style]="'secondary'"
  >
    <form class="example-form" [formGroup]="filterForm" (ngSubmit)="changePage(0)">
      <div class="panel-actions-container m-bottom">
        <h3 class="panel-title no-line">{{ "common.labels.filters" | translate }}</h3>
        <button mat-flat-button color="accent" type="button" (click)="resetForm()">{{ "common.filters.clearFilters" | translate }}</button>
        <button mat-flat-button color="primary" type="submit">{{ "common.filters.filter" | translate }}</button>
      </div>

      <mat-form-field class="span-4">
        <mat-label>{{ 'request.fields.title' | translate }}</mat-label>
        <input matInput formControlName="title" autocomplete="off" [placeholder]="'request.fields.title' | translate">
      </mat-form-field>

      <div class="mat-form-field span-4 status-field">
        <mat-label class="mat-button-toggle-label">{{ "request.fields.status" | translate }}</mat-label>
        <mat-button-toggle-group formControlName="statusCode" aria-label="status">
          <mat-button-toggle value="">
            {{ "common.labels.all" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="IPR">
            <span class="request-status-indicator" status-code="IPR"></span>
            {{ "request.status.IPR" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="CLS">
            <span class="request-status-indicator" status-code="CLS"></span>
            {{ "request.status.CLS" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="CAN">
            <span class="request-status-indicator" status-code="CAN"></span>
            {{ "request.status.CAN" | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="mat-form-field">
        <mat-label class="mat-button-toggle-label">Service type</mat-label>
        <mat-button-toggle-group formControlName="serviceTypeId" aria-label="serviceType">
          <mat-button-toggle [value]="0">{{ "common.labels.all" | translate }}</mat-button-toggle>
          <mat-button-toggle *ngFor="let service of serviceTypes" [value]="service.id">{{ service.value }}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
        
      <mat-form-field>
        <mat-label>{{ "request.fields.projectType" | translate }}</mat-label>
        <mat-select formControlName="projectTypeId" [placeholder]="'request.fields.projectType' | translate">
          <mat-option [value]="null">
            {{ "common.labels.empty" | translate }}
          </mat-option>
          <mat-option *ngFor="let projectType of projectTypes" [value]="projectType.id">
            {{ projectType.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="span-4">
        <mat-label>{{ "request.fields.catalog" | translate }}</mat-label>
        <mat-select formControlName="catalogId" [placeholder]="'request.fields.catalog' | translate" (opened)="catalogsFilter.value = ''" >
          <div class="select-search">
            <mat-form-field>
              <mat-label>{{ "common.labels.search" | translate }}</mat-label>
              <input matInput [placeholder]="'common.labels.search' | translate" #catalogsFilter>
            </mat-form-field>
          </div>
          <mat-option [value]="null">
            {{ "common.labels.empty" | translate }}
          </mat-option>
          <mat-option *ngFor="let catalog of catalogs" [value]="catalog.id" [attr.data-hidden]="!catalog.value.toLowerCase().includes(catalogsFilter.value.toLowerCase())" >
            {{ catalog.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Business Line</mat-label>
        <mat-select formControlName="businessLineId" placeholder="Business Line" (valueChange)="onBuinessLineChange($event)">
          <mat-option [value]="null">{{ "common.labels.all" | translate }}</mat-option>
          <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">{{ businessLine.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="non-view-field">
        <mat-label>Tribe</mat-label>
        <mat-select formControlName="tribeId" placeholder="Tribe" (opened)="tribesFilter.value = ''" >
          <div class="select-search">
            <mat-form-field>
              <mat-label>{{ "common.labels.search" | translate }}</mat-label>
              <input matInput [placeholder]="'common.labels.search' | translate" #tribesFilter>
            </mat-form-field>
          </div>
          <mat-option [value]="null">{{ "common.labels.empty" | translate }}</mat-option>
          <mat-option *ngFor="let trib of tribes" 
            [attr.data-hidden]="!trib.name.toLowerCase().includes(tribesFilter.value.toLowerCase())" 
            [value]="trib.id">
            {{ trib.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "request.fields.startDate" | translate }}</mat-label>
        <mat-date-range-input formGroupName="startDate" [rangePicker]="dateStartPicker" [comparisonStart]="savedFilters['endDateFrom']" [comparisonEnd]="savedFilters['endDateTo']">
          <input matStartDate formControlName="from" (dateChange)="savedFilters['startDateFrom'] = $event.value" [placeholder]="'request.fields.startDate' | translate">
          <input matEndDate formControlName="to" (dateChange)="savedFilters['startDateTo'] = $event.value" [placeholder]="'request.fields.endDate' | translate">
        </mat-date-range-input>
        <mat-hint>{{ internationalizationService.getDateFormatString() }} – {{ internationalizationService.getDateFormatString() }}</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="dateStartPicker">
          <mat-icon matDatepickerToggleIcon>
            <img src="/assets/icons/calendar.svg" alt="calendar">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #dateStartPicker [calendarHeaderComponent]="datepickerHeaderComponent"></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "request.fields.endDate" | translate }}</mat-label>
        <mat-date-range-input formGroupName="endDate" [rangePicker]="dateEndPicker" [comparisonStart]="savedFilters['startDateFrom']" [comparisonEnd]="savedFilters['startDateTo']">
          <input matStartDate #endDateFrom (dateChange)="savedFilters['endDateFrom'] = $event.value" formControlName="from" [placeholder]="'request.fields.startDate' | translate">
          <input matEndDate #endDateTo (dateChange)="savedFilters['endDateTo'] = $event.value" formControlName="to" [placeholder]="'request.fields.endDate' | translate">
        </mat-date-range-input>
        <mat-hint>{{ internationalizationService.getDateFormatString() }} – {{ internationalizationService.getDateFormatString() }}</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="dateEndPicker">
          <mat-icon matDatepickerToggleIcon>
            <img src="/assets/icons/calendar.svg" alt="calendar">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #dateEndPicker [calendarHeaderComponent]="datepickerHeaderComponent"></mat-date-range-picker> 
      </mat-form-field>
  
    </form>
  </app-panel>
  <app-panel 
    [open]="showFilters === false && filterForm.touched === true"
    [style]="'secondary'"
  >
    <span>{{ "common.filters.activeFilters.0" | translate }} <a (click)="resetForm()">{{ "common.filters.activeFilters.1" | translate }}</a> {{ "common.filters.activeFilters.2" | translate }}</span>
  </app-panel>
 
  <div class="table-container">
    <div class="loading" [class.is-loading]="isLoading === true">
      <mat-spinner color="primary" diameter="120"></mat-spinner>
    </div>

    <div class="table-summary">
      <span> {{ "requests.totalElements" | pluralTranslate:pageData.totalElements | translate : { totalElements: pageData.totalElements } }} </span>
      <div class="divider"></div>
      <button mat-flat-button color="primary" (click)="exportClicked()">
        <img class="button-icon white-icon" src="/assets/icons/download.svg">
        {{ "common.actions.export" | translate }}
      </button>
      <button mat-flat-button color="primary" (click)="addClicked()">
        <img class="button-icon white-icon" src="/assets/icons/add-document.svg">
        {{ "requests.actions.addRequest" | translate }}
      </button>
    </div>

    <div class="inner-table-container"> 
      <table mat-table [dataSource]="dataSource" (matSortChange)="changePage(null, null, $event)" style="min-width: max(2000px, 100%);"
        matSort [matSortActive]="pageData.sort?.active" [matSortDirection]="pageData.sort?.direction" 
      >
      
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "request.fields.title" | translate }} </th>
          <td mat-cell *matCellDef="let request"> {{request.title}} </td>
        </ng-container>

        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "request.fields.user" | translate }} </th>
          <td mat-cell *matCellDef="let project"> {{ project.user.email }} </td>
        </ng-container>

        <ng-container matColumnDef="tribe">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "request.fields.tribe" | translate }} </th>
          <td mat-cell *matCellDef="let request"> {{ request.businessLine }} - {{request.tribe }} </td>
        </ng-container>

        <ng-container matColumnDef="technology">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "request.fields.catalog" | translate }} </th>
          <td mat-cell *matCellDef="let request"> 
            <app-ellipsis [text]="request.catalog"></app-ellipsis>
          <td>
        </ng-container>
       
        <ng-container matColumnDef="projectType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "request.fields.projectType" | translate }} </th>
          <td mat-cell *matCellDef="let request"> {{ request.projectType }} </td>
        </ng-container>

        <ng-container matColumnDef="serviceType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "request.fields.serviceType" | translate }} </th>
          <td mat-cell *matCellDef="let request"> {{ request.serviceType }} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "request.fields.status" | translate }} </th>
          <td mat-cell *matCellDef="let request" class="text-center">
            <span class="request-status-indicator" [attr.status-code]="request.status" [matTooltip]="('request.status.' + request.status) | translate" matTooltipPosition="above" ></span>
          </td>
        </ng-container>

        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "request.fields.creationDate" | translate }} </th>
          <td mat-cell *matCellDef="let request"> {{ request.creationDate | date: internationalizationService.getDateFormatPipeString() }} </td>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "request.fields.startDate" | translate }} </th>
          <td mat-cell *matCellDef="let request"> {{ request.startDate | date: internationalizationService.getDateFormatPipeString() }} </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "request.fields.endDate" | translate }} </th>
          <td mat-cell *matCellDef="let request"> {{ request.endDate | date: internationalizationService.getDateFormatPipeString() }} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="sticky-col"> </th>
          <td mat-cell *matCellDef="let element" class="sticky-col"> 
            
            <button mat-icon-button (click)="infoClicked(element)"
              [appTooltipNoHover]="'requests.actions.goToPanel' | translate" matTooltipPosition="above"
            >
              <img src="/assets/icons/info.svg" alt="info">
            </button>
            
            <button mat-icon-button (click)="editNotes(element)"
              [appTooltipNoHover]="'requests.actions.editNotes' | translate" matTooltipPosition="above" 
            >
              <img src="/assets/icons/notes.svg" alt="notes">
            </button>
            
            <button mat-icon-button cdkOverlayOrigin [matMenuTriggerFor]="menu" [matMenuTriggerData]="{data: element}"
              [appTooltipNoHover]="'requests.actions.manageRequest' | translate" matTooltipPosition="above"
              [disabled]="element.status !== _ERequestStatus['In progress']"
            >
              <img src="/assets/icons/settings.svg" alt="setting">
            </button>

          </td>
        </ng-container>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell">
            {{ "common.labels.noData" | translate }}
          </td>
        </tr>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator 
      showFirstLastButtons
      [pageSizeOptions]="[10, 20, 50]" 
      [pageSize]="pageData.size" 
      [length]="pageData.totalElements" 
      [pageIndex]="pageData.index"
      (page)="changePage($event.pageIndex, $event.pageSize)"
    ></mat-paginator>
  </div>
</app-page-container>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-data="data">
    <button mat-menu-item (click)="cancelRequestClick(data)">{{ "requests.actions.cancelRequest" | translate }}</button>
    <button mat-menu-item (click)="createProjectClick(data)">{{ "requests.actions.createProject" | translate }}</button>
  </ng-template>
</mat-menu>

<ng-template #editNotesModalTemplate let-requestId="requestId">
  <mat-form-field class="full-width">
    <mat-label>{{ "supplier.fields.notes" | translate }}</mat-label>
    <textarea matInput [ngModel]="textAreaValue" (ngModelChange)="textAreaValue = $event" placeholder="{{ 'supplier.fields.notes' | translate }}"></textarea>
  </mat-form-field>
  <div class="actions-row">
    <button mat-button (click)="editNotesAction(requestId, false)">{{ "common.actions.cancel" | translate }}</button>
    <button mat-flat-button color="primary" (click)="editNotesAction(requestId, true)">{{ "common.actions.confirm" | translate }}</button>
  </div>
</ng-template>
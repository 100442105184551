<app-panel [isLoading]="procedure == null">
  @if (procedure != null) {
    <!-- <h3 class="panel-title" *ngIf="procedure.hideTitle !== true">{{ procedure.title }}</h3> -->
    <ng-container [ngSwitch]="procedure.type"> 
      <ng-container *ngSwitchCase="'composite'">
        @for (paragraph of getParagraphs(); track $index) {
          <div class="composite-view">
            <ng-container *ngTemplateOutlet="compositePart; context: { procedure: paragraph }"></ng-container>
          </div>
        }
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="compositePart; context: { procedure: procedure }"></ng-container>
      </ng-container>
    </ng-container>
  }
</app-panel>

<!-- ITextProcedure -->
<ng-template #TextProcedurePart let-text="text">
  <p>
    {{ text }}
  </p>
</ng-template>

<!-- IImageProcedure -->
<ng-template #ImageProcedurePart let-imageUrl="imageUrl" let-imageDescription="imageDescription">
  <app-image-viewer [image]="imageUrl" [imageDescription]="imageDescription"></app-image-viewer>
</ng-template>

<ng-template #compositePart let-compositeProcedure="procedure">
  <ng-container [ngSwitch]="compositeProcedure.type"> 
    <ng-container *ngSwitchCase="'image'">
      <ng-container *ngTemplateOutlet="ImageProcedurePart; context: compositeProcedure"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'text'">
      <ng-container *ngTemplateOutlet="TextProcedurePart; context: compositeProcedure"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

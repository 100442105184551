import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { IActionEvent } from 'src/app/components';
import { IListItem } from 'src/app/models/listItem';
import { IServiceCatalog, ISupplier } from 'src/app/models/supplier';
import { ERole } from 'src/app/models/user';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiService } from 'src/app/services/api/api.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-suppliers-list',
  templateUrl: './suppliers-list.component.html',
  styleUrls: ['./suppliers-list.component.scss'],
}) 
export class SuppliersListComponent implements OnInit {

  constructor (
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private alertService: AlertService,
    private userService: UserService,
    private sharedService: SharedService,
    private translateService: TranslateService,
  ) {}

  canEdit = true;

  ngOnInit(): void {
    this.canEdit = this.router.url.includes('backoffice');

    if (this.userService.role === ERole.CS) {
      this.filterForm.controls.flagCsm.setValue(true);
    }

    const f = this.userService.getSetting("suppliersList_filters", true);
    if (f) {
      this.filterForm.patchValue(JSON.parse(f));
      this.minRating = this.filterForm.controls.minRating.value;
      this.maxRating = this.filterForm.controls.maxRating.value;
    }

    const p = this.userService.getSetting("suppliersList_pageData", true);
    if (p) {
      this.pageData = JSON.parse(p);
      this.filterForm.markAllAsTouched();
    }


    this.api.getSupplierCategories().subscribe((categories) => {
      this.categories = categories;
    });
    this.api.getContractTypes().subscribe((contractTypes) => {
      this.contractTypes = contractTypes;
    });

    this.getQueryParamFilters().then((editedFilters) => {
      if (editedFilters) return this.changePage(0);
      this.changePage();
    });
  }
  
  getQueryParamFilters() {
    return firstValueFrom(this.route.queryParams).then((params) => {
      if (params['filters']) {
        this.resetForm(false);
        try {
          this.filterForm.patchValue(JSON.parse(params['filters']));
        } catch (e) { 
          console.log("tried to patch value of filters form", params['filters']);
        }
        return true;
      } 
      return false;
    });
  }

  onActionClicked(action: IActionEvent) {
    switch (action.actionKey) {
      case 'toggleFilters': 
        this.showFilters = !action.status;
        break;
    }
  }

  //#region filters
  showFilters = true;
  filterForm = this.fb.group({
    name: [ '' ],
    isActive: [ 0 ],
    flagCsm: [ 0 as 0 | boolean],
    categories: [ [] as number[] ],
    contracts: [ [] as number[] ],
    minRating: [ 0 ],
    maxRating: [ 5 ],
  }, {
    updateOn: 'submit',
  });

  categories: IListItem[] = [];
  contractTypes: IListItem[] = [];


  resetForm(changePage = true) {
    this.filterForm.reset({
      name: '',
      isActive: 0,
      flagCsm: 0,
      categories: [],
      contracts: [],
      minRating: 0,
      maxRating: 5,
    });
    this.minRating = 0;
    this.maxRating = 5;

    if (this.userService.role === ERole.CS) {
      this.filterForm.controls.flagCsm.setValue(true);
    }

    if (changePage) this.changePage(0);
    this.userService.deleteSetting("suppliersList_filters", true);
  }

  minRating = 0;
  maxRating = 5;
  onMinRatingChanged(value: number) {
    this.minRating = value;
    if (this.minRating >= this.maxRating) {
      this.maxRating = this.minRating + 1;
      this.filterForm.controls.maxRating.setValue(this.maxRating);
    }
  }
  onMaxRatingChanged(value: number) {
    this.maxRating = value;
    if (this.minRating >= this.maxRating) {
      this.minRating = this.maxRating - 1;
      this.filterForm.controls.minRating.setValue(this.minRating);
    }
  }

  //#endregion

  //#region table

  isLoading = true; 
  pageData = {
    index: 0,
    size: 10,
    totalElements: 0,
    sort: null as Sort | null,
  }
  displayedColumns: string[] = [ 
    "isActive",
    "country", 
    "name",

    "category",
    "contracts",
    "rating",
    "flagCsm",
    //"notes",
    "actions",
  ];

  dataSource = new MatTableDataSource<ISupplier>([]);
  changePage(pageNumber?: number, pageSize?: number, sort?: Sort) {
    this.isLoading = true;

    if (pageSize != null) this.pageData.size = pageSize;
    if (pageNumber != null) this.pageData.index = pageNumber;
    if (sort) this.pageData.sort = sort;
    
    const fc = this.filterForm.value;
    const filters: { [key: string]: string | number | string[] | number[] | boolean } = {};

    if (fc.name) filters['name'] = fc.name;
    if ((fc.categories = fc.categories.filter((c: number) => c !== null)) && fc.categories.length) filters['categories'] = fc.categories;
    if ((fc.contracts = fc.contracts.filter((c: number) => c !== null)) && fc.contracts.length) filters['contracts'] = fc.contracts;
    if (fc.isActive !== 0) filters['isActive'] = fc.isActive;
    if (fc.flagCsm !== 0) filters['flagCsm'] = fc.flagCsm;
    
    filters['minRating'] = fc.minRating;
    filters['maxRating'] = fc.maxRating;
    
    if (this.filterForm.touched) this.userService.saveSetting("suppliersList_filters", JSON.stringify(this.filterForm.value), true);
    this.userService.saveSetting("suppliersList_pageData", JSON.stringify(this.pageData), true);

    this.api.getPagedSuppliers(filters, this.pageData.index, this.pageData.size, this.pageData.sort).subscribe({ 
      next: (suppliers) => {
        this.dataSource.data = suppliers.content;
        this.pageData.totalElements = suppliers.totalElements;
        this.isLoading = false;
      }, 
      error: () => { 
        this.isLoading = false; 
      }
    });
  }

  deleteClicked(supplier: ISupplier) {
    this.alertService.showAlert({
      type: "warning",
      acceptText: "common.actions.delete",
      message: this.translateService.instant('suppliers.alerts.delete', { supplier: supplier.name }),
      onConfirm: () => {
        // TODO: call API to delete user
      },
    });
  }

  exportClicked() {
    this.isLoading = true;
    
    const fc = this.filterForm.value;
    const filters: { [key: string]: string | number | string[] | number[] | boolean } = {};

    if (fc.name) filters['name'] = fc.name;
    if ((fc.categories = fc.categories.filter((c: number) => c !== null)) && fc.categories.length) filters['categories'] = fc.categories;
    if ((fc.contracts = fc.contracts.filter((c: number) => c !== null)) && fc.contracts.length) filters['contracts'] = fc.contracts;
    if (fc.isActive !== 0) filters['isActive'] = fc.isActive;
    if (fc.flagCsm !== 0) filters['flagCsm'] = fc.flagCsm;
    
    filters['minRating'] = fc.minRating;
    filters['maxRating'] = fc.maxRating;

    this.api.exportSuppliers(filters, this.pageData.sort, this.pageData.totalElements).subscribe({ 
      next: (data) => {
        this.sharedService.createDownload(data, "application/csv");
      }, 
      error: () => { 
        this.isLoading = false; 
      }
    });
  }
  
  addClicked() {
    this.router.navigate(['/backoffice/add-supplier']);
  }

  editClicked(supplier: ISupplier) {
    this.router.navigate(['/backoffice/supplier/', supplier.id]);
  }

  infoClicked(supplier: ISupplier) {
    this.router.navigate([this.router.url.includes('backoffice') ? 'backoffice' : 'projectsAndSuppliers', 'supplier-panel', supplier.id]);
  }

  getContracts(contracts: ISupplier['contracts']) {
    if (contracts && contracts.length) return contracts.map((c) => c.value).join(', ');

    return "-";
  }

  getServiceText(services: IServiceCatalog[]) {
    return services.map((s) => s.catalog).join(', ');
  }

  //#endregion
}

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatepickerHeaderComponent, IActionEvent, IBottomAction } from 'src/app/components';
import { IError } from 'src/app/models/error';
import { IListItem } from 'src/app/models/listItem';
import { IRequest } from 'src/app/models/request';
import { ISupplier } from 'src/app/models/supplier';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiService } from 'src/app/services/api/api.service';
import { InternationalizationService } from 'src/app/services/internationalization/internationalization.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrl: './project-edit.component.scss'
})
export class ProjectEditComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private api: ApiService,
    private alertService: AlertService,
    private location: Location,
    private sharedService: SharedService,
    public internationalizationService: InternationalizationService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    
    this.api.getServices().subscribe(services => {
      this.technologies = services;
    });
 
    this.api.getJobsDescription().subscribe(FTERole => {
      this.FTERoles = FTERole;
    });

    this.api.getTPOList().subscribe(tpo => {
      this.tpoList = tpo;
    });

    this.api.getContractTypes().subscribe(ct => {
      this.contracts = ct;
    });

    this.api.getContractManagers().subscribe(cm => {
      this.contractManagers = cm;
    });

    this.api.getContractOwners().subscribe(co => {
      this.contractOwners = co;
    });
    
    this.api.getServiceTypes().subscribe(st => {
      this.serviceTypes = st;
    });
    
    const id = this.route.snapshot.paramMap.get('id');
    if (id) 
      this.loadData(Number(id));
    else {
      this.location.back();
    }
  }

  isLoading = true;
  datepickerHeaderComponent = DatepickerHeaderComponent;

  contextActions: IBottomAction[] = [
    { key: 'cancel', name: 'common.actions.cancel', side: 'right' },
    { key: 'save', name: "project.actions.save", side: 'right', style: 'primary' },
  ];

  onActionClicked(action: IActionEvent) {
    switch (action.actionKey) {
      case 'cancel':
        this.location.back();
        break;
      case 'save':
        this.projectForm.markAllAsTouched();
        const errors = this.sharedService.getFormErrors(this.projectForm);
        if (this.projectForm.valid || Object.values(errors).filter(e => e['beError'] !== true).length === 0) 
          this.submitForm();
        else {
          this.sharedService.focusOnInvalidInput(this.projectForm)
        }
        break;
    }
  }

  onRateKeyDown(event: KeyboardEvent) {
    if (event.key.match(/^[eE,+-]$/g)) {
      event.preventDefault();
    }
  }

  technologies: IListItem[] = [];
  FTERoles: IListItem[] = [];
  contracts: IListItem[] = [];
  serviceTypes: IListItem[] = [];
  tpoList: IListItem[] = [];
  contractManagers: IListItem[] = [];
  contractOwners: IListItem[] = [];

  projectForm = this.fb.group({ 
    projectId: [ null as number ],
    requestId: [ null as number ],
    tribeId: [ null as number ],
    
    title: ['', Validators.required],
    tpoId: [ null as number, Validators.required],
    externalFTE: [ 0 as number, [Validators.required, Validators.min(0), Validators.max(9007199254740991)]],
    serviceTypeId: [ null as number, Validators.required],
    fteRoleId: [ null as number, Validators.required],
    technologyId: [ null as number, Validators.required],
    contractOwnerId: [ null as number, Validators.required],
    contractManagerId: [ null as number, Validators.required],
    startDate: [ null as Date, Validators.required],
    endDate: [ null as Date, Validators.required],
    dueDate: [ null as Date, Validators.required],
    contractId: [null as number, Validators.required],
    miscellous: [ null as string],
  });

  beErrors: { [key: string]: string } = { };
  getFieldError(controlName: (keyof typeof this.projectForm.controls)): string {
    const formControl = this.projectForm.controls[controlName];

    if (formControl.hasError('required')) {
      return 'common.errors.requiredField';
    }

    if (formControl.hasError('invalidNumber')) {
      return 'common.errors.invalidNumber';
    }
    if (formControl.hasError('max')) {
      return 'common.errors.invalidNumber';
    }

    if (this.beErrors[controlName]) {
      return 'request-wizard.errors.' + controlName + '.' + this.beErrors[controlName];
    }

    if (formControl.errors) {
      return 'request-wizard.errors.' + Object.keys(formControl.errors)[0];
    }

    return ''
  }

  request: IRequest;
  supplier: ISupplier;

  loadData(projectId: number) {
    this.api.getProject(projectId).subscribe(project => {
      this.projectForm.patchValue({
        projectId: project.id,
        requestId: project.requestId,
        tribeId: project.tribeId,

        title: project.title,
        tpoId: project.tpoId,
        externalFTE: project.externalFTE,
        serviceTypeId: project.serviceTypeId,
        fteRoleId: project.fteRoleId,
        technologyId: project.technologyId,
        contractOwnerId: project.contractOwnerId,
        contractManagerId: project.contractManagerId,
        startDate: new Date(project.startDate),
        endDate: new Date(project.endDate),
        dueDate: new Date(project.dueDate),
        contractId: project.contractId,
        miscellous: project.miscellous,
      });
      this.supplier = project.supplier;
      this.isLoading = false;
    });
  }
  submitForm() {
    const project = this.projectForm.value;
    
    this.isLoading = true;
    this.api.editProject({
      id: project.projectId,

      requestId: project.requestId,
      supplierId: this.supplier.id,
      tribeId: project.tribeId,

      title: project.title,
      tpoId: project.tpoId,
      externalFTE: project.externalFTE,
      contractId: project.contractId,
      serviceTypeId: project.serviceTypeId,
      fteRoleId: project.fteRoleId,
      technologyId: project.technologyId,
      contractOwnerId: project.contractOwnerId,
      contractManagerId: project.contractManagerId,
      startDate: this.internationalizationService.ignoreDateTimezone( project.startDate ).toISOString(),
      endDate: this.internationalizationService.ignoreDateTimezone( project.endDate ).toISOString(),
      dueDate: this.internationalizationService.ignoreDateTimezone( project.dueDate ).toISOString(),
      miscellous: project.miscellous,
    }).subscribe({
      next: (res) => {
        this.isLoading = false;

        this.projectForm.markAsUntouched();
        this.router.navigate(['/projectsAndSuppliers/project-panel', project.projectId.toString()]);
      },
      error: (err: IError) => {
        this.projectForm.markAsTouched();
        err.messages.forEach((m) => {
          this.beErrors[m.field] = m.key;

          //@ts-ignore
          const control = this.projectForm.controls[m.field];
          if (control) {
            control.setErrors({ beError: true });
            this.sharedService.focusOnInvalidInput(this.projectForm);
          }
        });
      }
    })
  }

  onTitleClick() {
    this.router.navigate(['/projectsAndSuppliers/projects']);
  }
  
  get hasUnsavedChanges() {
    return this.projectForm.touched;
  }

  onNavigationOut() {
    if (this.hasUnsavedChanges) {
      return this.sharedService.showBackNavigationAlertAsync();
    }
    return true;
  }

}

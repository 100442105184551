import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IRatingQuestion, IRatingValue } from 'src/app/models/rating';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

const QUESTIONS: IRatingQuestion[] = [
  {  
    id: 1,
    questionKey: "q1",
    type: "boolean",
    required: true
  },
  {  
    id: 2,
    questionKey: "q2",
    type: "boolean",
    required: true
  },
  {  
    id: 3,
    questionKey: "q3",
    type: "boolean",
    required: true
  },
  {  
    id: 4,
    questionKey: "q4",
    type: "boolean",
    required: true
  },
  {  
    id: 5,
    questionKey: "q5",
    type: "boolean",
    required: true
  },
  {  
    id: 6,
    questionKey: "q6",
    type: "string",
    required: false
  },
]

@Component({
  selector: 'app-rating-form',
  templateUrl: './rating-form.component.html',
  styleUrl: './rating-form.component.scss'
})
export class RatingFormComponent {

  _QUESTIONS = QUESTIONS
  formQuestions = this.fb.group({
    questions: this.fb.array(QUESTIONS.map(q => {
      return this.fb.group({
        id: q.id,
        question: [ null as IRatingValue['value'], q.required ? Validators.required : null ]
      });
    }))
  });

  @Input() set readonly(readonly: boolean) {
    if (readonly) {
      this.formQuestions.disable();
    } else {
      this.formQuestions.enable();
    }
  }

  @Input("answers") set setAnswers(answers: IRatingValue[]) {
    this.formQuestions.patchValue({
      questions: answers.map(a => {
        return {
          "question": a.value,
        };
      }),
    });
  }
  @Output() answersChange = new EventEmitter<IRatingValue[]>();

  getformQuestionControl(index: number) {
    return this.formQuestions.controls.questions.controls[index].controls.question;
  }

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.formQuestions.valueChanges.subscribe((value) => {
      this.answersChange.emit(value.questions.map((q) => {
        return {
          id: q.id,
          value: q.question,
        };
      }));
    });
  }
}

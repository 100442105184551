import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { IActionEvent, IBottomAction, RatingFormComponent } from 'src/app/components';
import { IRatingValue } from 'src/app/models/rating';
import { ApiService } from 'src/app/services/api/api.service';
import { InternationalizationService } from 'src/app/services/internationalization/internationalization.service';
import { SharedService } from 'src/app/services/shared/shared.service';


export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrl: './rating.component.scss',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
  ],
})
export class RatingComponent implements OnInit {


  projectId: number;
  projectTitle: string;
  answers: IRatingValue[] = [];

  projectStartDate = new Date();
  projectStartYear = "2023";
  projectStartQuarter = "2023 - Q1";
  projectStartQuarterNum = 1;


  dateForm = new FormGroup({
    year: new FormControl( null as Date, [ Validators.required, (control) => {
      if (control.touched && control.value && moment(control.value).year() < this.projectStartDate.getFullYear())
        return { matDatepickerMin: true }
      
      return null;
    } ]),
    quarter: new FormControl( 1, [ Validators.required, Validators.min(1), Validators.max(4) ]),
  }, (group: any) => {
    if (group.value.year && 
      moment(group.value.year).format("YYYY") === this.projectStartYear && 
      group.value.quarter < this.projectStartQuarterNum
    )
      return { invalidQuarter: true }
    
    return null;
  });

  quarters = [
    { value: 1, name: 'Q1' },
    { value: 2, name: 'Q2' },
    { value: 3, name: 'Q3' },
    { value: 4, name: 'Q4' },
  ]

  @ViewChild("ratingForm") ratingFormElement: RatingFormComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private location: Location,
    private api: ApiService,
  ) { }

  ngOnInit(): void {

    const now = new Date();

    this.dateForm.patchValue({
      year: now,
      quarter: Math.floor(now.getMonth() / 3) + 1,
    })

    this.projectId = Number(this.route.snapshot.paramMap.get('id'));
    if (Number.isNaN(this.projectId)) {
      this.location.back();
      return;
    }

    this.api.getProject(this.projectId).subscribe({
      next: (project) => {
        this.projectTitle = project.title;
        this.projectStartDate = new Date(project.startDate);
        this.projectStartYear = this.projectStartDate.getFullYear().toString();
        this.projectStartQuarterNum = Math.floor(moment(project.startDate).month() / 3) + 1;
        this.projectStartQuarter = this.projectStartYear + " - Q" + this.projectStartQuarterNum;
      },
      error: (error) => {
        this.location.back();
      }
    })
  }

  getFieldError(controlName: (keyof typeof this.dateForm.controls)): string {
    const formControl = this.dateForm.controls[controlName];

    if (formControl.hasError('required')) {
      return 'common.errors.requiredField';
    }

    if (formControl.hasError('matDatepickerMin')) {
      return 'rating.errors.invalidYear';
    }

    return ''
  }
  getFormErrors(): string {
    if (this.dateForm.hasError('invalidQuarter')) {
      return 'rating.errors.invalidQuarter'
    }

    return ''
  }

  setYear(normalizedMonthAndYear: ReturnType<typeof moment>, datepicker: MatDatepicker<ReturnType<typeof moment>>) {
    const ctrlValue = new Date(this.projectStartDate);
    ctrlValue.setFullYear(normalizedMonthAndYear.year());
    this.dateForm.controls.year.setValue(ctrlValue);
    datepicker.close();
  }

  contextActions: IBottomAction[] = [
    { key: 'cancel', name: 'common.actions.cancel', side: 'right' },
    { key: 'save', name: "rating.actions.save", side: 'right', style: 'primary' },
  ];

  onActionClicked(action: IActionEvent) {
    switch (action.actionKey) {
      case 'cancel':
        this.location.back();
        break;
      case 'save':
        this.dateForm.markAllAsTouched();
        this.ratingFormElement.formQuestions.markAllAsTouched();
        if (this.dateForm.valid && (this.ratingFormElement.formQuestions.valid || Object.keys(this.ratingFormElement.formQuestions.errors).filter(e => e !== 'beError').length == 0)) 
          this.submitForm();
        else {
          this.sharedService.focusOnInvalidInput(this.ratingFormElement.formQuestions)
        }
        break;
    }
  }

  onTitleClick(titleIndex: Number) {
    switch (titleIndex) {
      case 0:
        this.router.navigate(['/projectsAndSuppliers/projects']);
        break;
      case 1:
        this.router.navigate(['/projectsAndSuppliers/project-panel/', this.projectId]);
        break;
    }
  }

  submitForm() {
    const { year, quarter } = this.dateForm.value;
    this.api.addRating(this.projectId, this.answers, year.getFullYear(), quarter).subscribe({
      next: () => {
        this.ratingFormElement.formQuestions.markAsUntouched();
        this.location.back();
      },
      error: () => {
        // TODO: show error
      }
    })
  }

  get hasUnsavedChanges() {
    return this.ratingFormElement.formQuestions.touched
  }

  onNavigationOut() {
    if (this.hasUnsavedChanges) {
      return this.sharedService.showBackNavigationAlertAsync();
    }
    return true;
  }
}

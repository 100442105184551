<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [activeEntries]="activeEntries"
  [animations]="animations"
  (legendLabelClick)="onClick($event)"
  (legendLabelActivate)="onActivate($event, true)"
  (legendLabelDeactivate)="onDeactivate($event, true)"
>
<svg:g [attr.transform]="transform" class="bar-chart chart">
  <svg:g
    ngx-charts-x-axis
    [xScale]="xScale"
    [dims]="dims"
    [showGridLines]="showGridLines"
    [showLabel]="showXAxisLabel"
    [labelText]="xAxisLabel"
    [trimTicks]="trimXAxisTicks"
    [rotateTicks]="rotateXAxisTicks"
    [maxTickLength]="maxXAxisTickLength"
    [tickFormatting]="xAxisTickFormatting"
    [ticks]="xAxisTicks"
    [xAxisOffset]="dataLabelMaxHeight.negative"
    [wrapTicks]="wrapTicks"
    (dimensionsChanged)="updateXAxisHeight($event)"
  ></svg:g>
  <ng-container *ngIf="yAxis">
    <svg:g
      ngx-charts-y-axis
      ngx-charts-y-secondary-axis 
      [yScale]="yScale"
      [dims]="dims"
      [showGridLines]="true"
      [showLabel]="showYAxisLabel"
      [labelText]="yAxisLabel"
      [trimTicks]="trimYAxisTicks"
      [maxTickLength]="maxYAxisTickLength"
      [tickFormatting]="yAxisTickFormattingFn"
      [ticks]="yAxisTicks"
      [wrapTicks]="wrapTicks"
      (dimensionsChanged)="updateYAxisWidth($event)"
    ></svg:g>
    <svg:g
      ngx-charts-y-axis
      ngx-charts-y-max-axis
      [yScale]="yScale"
      [dims]="dims"
      [showGridLines]="true"
      [showLabel]="showYAxisLabel"
      [labelText]="yAxisLabel"
      [trimTicks]="trimYAxisTicks"
      [maxTickLength]="maxYAxisTickLength"
      [tickFormatting]="yAxisTickFormattingFn"
      [ticks]="yAxisMaxTicks"
      [wrapTicks]="wrapTicks"
      (dimensionsChanged)="updateYAxisWidth($event)"
    ></svg:g>
    <svg:g
      ngx-charts-y-axis
      *ngIf="yAxis"
      [yScale]="yScale"
      [dims]="dims"
      [showGridLines]="true"
      [showLabel]="showYAxisLabel"
      [labelText]="yAxisLabel"
      [trimTicks]="trimYAxisTicks"
      [maxTickLength]="maxYAxisTickLength"
      [tickFormatting]="yAxisTickFormattingFn"
      [ticks]="yAxisMainTicks"
      [wrapTicks]="wrapTicks"
      (dimensionsChanged)="updateYAxisWidth($event)"
    ></svg:g>
  </ng-container>
  <svg:g 
    ngx-charts-series-vertical
    [xScale]="xBarScale"
    [yScale]="yScale"
    [colors]="colors"
    [series]="results"
    [dims]="dims"
    [gradient]="gradient"
    [tooltipDisabled]="tooltipDisabled"
    [tooltipTemplate]="tooltipTemplate"
    [showDataLabel]="showDataLabel"
    [dataLabelFormatting]="dataLabelFormatting"
    [activeEntries]="activeEntries"
    [roundEdges]="roundEdges"
    [animations]="animations"
    [noBarWhenZero]="noBarWhenZero"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)"
    (select)="onClick($event)"
    (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event)"
  ></svg:g>
</svg:g>
</ngx-charts-chart>
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormControlOptions, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { curveNatural } from 'd3-shape';
import { retry } from 'rxjs';
import { DatepickerHeaderComponent, IActionEvent, IBottomAction } from 'src/app/components';
import { IListItem } from 'src/app/models/listItem';
import { IPreferredSuppliers, ISupplier } from 'src/app/models/supplier';
import { ApiService } from 'src/app/services/api/api.service';
import { InternationalizationService } from 'src/app/services/internationalization/internationalization.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { SupplierEditComponent } from '../../supplier/supplier-edit/supplier-edit.component';
import { UserService } from 'src/app/services/user/user.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { IError } from 'src/app/models/error';
import { Router } from '@angular/router';

export type SupplierType = IPreferredSuppliers['type']
export type SuggestedSupplierWithType = ISupplier & { type: SupplierType };

@Component({
  selector: 'app-request-wizard',
  templateUrl: './request-wizard.component.html',
  styleUrls: ['./request-wizard.component.scss']
})
export class RequestWizardComponent implements OnInit {

  datepickerHeaderComponent = DatepickerHeaderComponent;

  steps = [{
    title: 'request-wizard.step1.title'
  }, {
    title: 'request-wizard.step2.title'
  }, {
    title: 'request-wizard.step3.title'
  }]
  width = 0;
  leftPosition = 0;
  selectedIndex = 0;

  contextActions: IBottomAction[] = [
    { key: 'prev',  name: "common.actions.prev", side: 'right', style: 'accent', disabled: true },
    { key: 'next',  name: "common.actions.next", side: 'right', style: 'primary' },
    { key: 'cancel',  name: "common.actions.cancel", side: 'left', style: 'warn' },
  ];
  
  constructor(
    private fb: FormBuilder,
    public internationalizationService: InternationalizationService,
    private sharedService: SharedService,
    private location: Location,
    private router: Router,
    private api: ApiService,
    private alert: AlertService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.api.getServiceTypes().subscribe(services => {
      this.serviceTypes = services;
    });
    this.api.getServices().subscribe(services => {
      this.catalogs = services;
    });
    this.api.getJobsDescription().subscribe(jobs => {
      this.jobs = jobs;
    });
    this.api.getSeniorities().subscribe(seniorities => {
      this.seniorities = seniorities.map(s => {
        const separatorIndex = s.value.indexOf(' ');
        return {
          id: s.id,
          value: s.value.substring(0, separatorIndex),
          valueExplanation: s.value.substring(separatorIndex + 1),
        };
      }).sort((a, b) => b.id - a.id);
    });
    this.api.getSuggestedSuppliers().subscribe(suppliers => {
      this.fullSuppliersList = suppliers.reduce((p, c) => {
        p.push(...c.suppliers.map(s => ({ ...s, type: c.type })));
        return p;
      }, []).sort((a, b) => a.rating - b.rating);
    });
    this.api.getProjectTypes().subscribe(projectTypes => {
      this.projectTypes = projectTypes;
    });
  }

  onActionClicked(action: IActionEvent) {
    switch (action.actionKey) {
      case 'cancel':
        this.location.back();
        break;
      case 'prev':
        this.setStep(this.selectedIndex - 1);
        break;
      case 'next':
        this.setStep(this.selectedIndex + 1);
        break;
    }
  }

  setStep(step: number) {
    if (this.canSetStep(step)) {
      this.selectedIndex = step;
      this.onStepChanged();
    }
  }

  canSetStep(step: number): boolean {
    switch (step) {
      case 0:
        return true;
      case 3:
        if (!this.step3Form.valid) {
          this.setStep(2);
          this.isSuppliersDrawerOpen = true;
          this.step3Form.markAllAsTouched();
          return false;
        }
        if (this.canSetStep(2)) {
          this.submit();
        }
        return false;
      case 2:
        if (!this.step2Form.valid) {
          this.setStep(1);
          this.step2Form.markAllAsTouched();
          this.sharedService.focusOnInvalidInput(this.step2Form)
          return false;
        }
        return this.canSetStep(1);
      case 1:
        if (!this.step1Form.valid) {
          this.setStep(0);
          this.step1Form.markAllAsTouched();
          return false;
        }
        return true;
    }
    return false;
  }

  onStepChanged() {
    switch (this.selectedIndex) {
      case 0:
        this.contextActions[0].disabled = true;
        this.contextActions[1].name = 'common.actions.next';
        break;
      case 1:
        this.contextActions[0].disabled = false;
        this.contextActions[1].name = 'common.actions.next';
        break;
      case 2:
        this.contextActions[0].disabled = false;
        this.contextActions[1].name = 'common.actions.submit';

        if (this.step2Form.controls.catalogId.touched 
          || this.step2Form.controls.jobId.touched) {
          const formV = this.step2Form.value;
          this.api.getSuggestedSuppliers(formV.catalogId, formV.jobId).subscribe(suppliers => {
            this.suppliers = suppliers;

            const localSuppliers = this.getSuppliersList("Locals");
            this.minSuppliers = Math.min(2, localSuppliers.length);
            this.step3Form.reset({
              selectedSuppliers: [],
              selectedSupplierId: null,
            })
            this.step3Form.updateValueAndValidity();

            if (this.step1Form.value.serviceType === 'shared' 
              && localSuppliers.length === 0 
              && this.getSuppliersList('IntraGroup').length === 0
              && this.getSuppliersList('SSI').length === 0
            ) {
              this.alert.showAlert({ 
                type: 'error',
                title: 'common.labels.error',
                message: 'request-wizard.errors.noSuppliersFound',
                acceptText: 'Ok',
                noCancel: true,
                onConfirm: () => {
                  this.alert.dismissAlert();
                  this.setStep(1);
                },
              });
            }
          });
        }
        break;
    }
  }


  //#region step 1

  serviceTypes: IListItem[] = [];
  step1Form = this.fb.group({ 
    serviceType: [ null as 'specific' | 'shared' | null, Validators.required ],
  });

  serviceTypeChanged() {
    this.step2Form.controls.projectTypeId.setValidators(this.step1Form.controls.serviceType.value === 'specific' ? Validators.required : null);
    this.step2Form.controls.projectTypeId.updateValueAndValidity();

    this.step3Form.controls.selectedSuppliers.setValue([]);
    this.step3Form.setValidators(this.step1Form.controls.serviceType.value === 'specific' ? (control: AbstractControl<Partial<{ selectedSuppliers: SuggestedSupplierWithType[]; selectedSupplierId: number; }>>) => {
      if (control.value.selectedSupplierId != null) {
        return null;
      }
      return { noSuppliersSelected: true };
    } : (control: AbstractControl<Partial<{ selectedSuppliers: SuggestedSupplierWithType[]; selectedSupplierId: number; }>>) => {
      if (this.minSuppliers !== 0 && control.value.selectedSuppliers.length < this.minSuppliers) {
        return { noSuppliersSelected: true };
      }
      return null;
    });
    this.step3Form.controls.selectedSuppliers.updateValueAndValidity();

    this.setStep(1);
  }

  get selectedServiceType() {
    return this.step1Form.value.serviceType;
  }

  //#endregion

  //#region step 2

  catalogs: IListItem[] = [];
  jobs: IListItem[] = [];
  tribes = this.userService.tribes;
  seniorities: (IListItem & { valueExplanation: string })[] = [];
  projectTypes: IListItem[] = [];

  step2Form = this.fb.group({ 
    tribeId: [ this.tribes.length === 1 ? this.tribes[0].tribeId : null, Validators.required ],
    title: [ '', [Validators.required, Validators.maxLength(255)] ],
    catalogId: [ null as number, Validators.required ],
    jobId: [ null as number, Validators.required ],
    startDate: [ new Date(), Validators.required ],
    endDate: [ null as Date, [Validators.required, (control: AbstractControl<Date>) => {
      if (control.value && control.value < this.step2Form.controls.startDate.value) {
        return { invalidEndDate: true };
      }
      return null;
    }]],
    description: [ '', Validators.required ],
    seniorityId: [ null as number ],
    rate: [ null as number, [Validators.min(0), Validators.max(9007199254740991), (control: AbstractControl<number>) => {
      if (!control.value) return null;

      if (control.value.toString().match(/[A-z]/g))
        return { invalidNumber: true };
     

      if (Math.floor(control.value) !== control.value && control.value.toString().split(".")[1].length > 2)
        return { tooManyDecimals: true };

        return null;
    }]],
    projectTypeId: [ null ],
  });

  getSeniorityFromId(id: number) {
    const s = this.seniorities.find(s => s.id === id);

    if (s) return s.value 
    return "";
  }

  resetDateField(control: 'startDate' | 'endDate') {
    this.step2Form.controls[control].setValue(new Date());
  }

  onRateKeyDown(event: KeyboardEvent) {
    if (event.key.match(/^[eE,+-]$/g)) {
      event.preventDefault();
    }
  }

  beErrors: { [key: string]: string } = { };
  getFieldError(controlName: (keyof typeof this.step2Form.controls)): string {
    const formControl = this.step2Form.controls[controlName];

    if (formControl.hasError('required')) {
      return 'common.errors.requiredField';
    }

    if (formControl.hasError('invalidNumber') || formControl.hasError('max')) {
      return 'common.errors.invalidNumber';
    }

    if (this.beErrors[controlName]) {
      return 'request-wizard.errors.' + controlName + '.' + this.beErrors[controlName];
    }

    if (formControl.errors) {
      return 'request-wizard.errors.' + Object.keys(formControl.errors)[0];
    }

    return ''
  }

  //#endregion

  //#region step 3

  suppliers: Array<{
    type: 'IntraGroup' | 'SSI' | 'Locals';
    suppliers: ISupplier[];
  }> = [];
  fullSuppliersList: SuggestedSupplierWithType[] = [];
  minSuppliers = 2;
  isSuppliersDrawerOpen = true;

  step3Form = this.fb.group({
    // utilizzato nel caso di serviceType == shared
    selectedSuppliers: new FormControl<SuggestedSupplierWithType[]>([]),
    // utilizzato nel caso di serviceType == specific
    selectedSupplierId: new FormControl<number>(null),
  });
  get selectedSuppliers() {
    return this.step3Form.controls.selectedSuppliers.value;
  }

  filteredSuppliers: ISupplier[] = [];

  getSuppliersList(listType?: typeof this.suppliers[number]['type'], filter?: string) {
    let s: ISupplier[];
    
    if (!listType) {
      s = this.fullSuppliersList;
    } else {
      s = this.suppliers.find(s => s.type === listType)?.suppliers;
    }

    if (!s) return [];

    if (filter && filter.length > 3) {
      return s.filter(s => s.name.toLowerCase().includes(filter.toLowerCase()));
    }
    return s;
  }

  getCatalogFromId(id: number) {
    const c = this.catalogs.find(c => c.id === id);

    if (c) return c.value 
    return "";
  }

  getTribeFromId(id: number) {
    const t = this.tribes.find(t => t.tribeId === id);

    if (t) return `${ t.businessLine } - ${ t.tribe }`
    return "";
  }

  getJobFromId(id: number) {
    const j = this.jobs.find(j => j.id === id);

    if (j) return j.value 
    return "";
  }

  getProjectFromId(id: number) {
    const p = this.projectTypes.find(p => p.id === id);

    if (p) return p.value 
    return "";
  }

  drop(event: CdkDragDrop<ISupplier[]>) {
    moveItemInArray(this.selectedSuppliers, event.previousIndex, event.currentIndex);
  }

  isSupplierSelected(supplier: ISupplier) {
    return this.selectedSuppliers.findIndex(s => s.id === supplier.id) !== -1;
  }
  getSelectedSupplierPos(supplier: ISupplier) {
    const pos = this.selectedSuppliers.findIndex(s => s.id === supplier.id);
    if (pos === -1) return null;
    return pos + 1;
  }
  checkedChange(event: MatCheckboxChange | boolean, supplier: SuggestedSupplierWithType ) {
    if (typeof event == "boolean" ? event : event.checked) {
      this.step3Form.controls.selectedSuppliers.setValue([ ...this.selectedSuppliers, supplier ]);
    } else {
      this.selectedSuppliers.splice(this.selectedSuppliers.findIndex(s => s.id === supplier.id), 1);
      this.step3Form.controls.selectedSuppliers.setValue(this.selectedSuppliers);
    }
  }
  selectedSupplierChanged(supplier: SuggestedSupplierWithType) { 
    this.step3Form.controls.selectedSuppliers.setValue([ supplier ]);
  }

  //#endregion

  //#region submit
  isLoading = false;
  submit() {
    this.isLoading = true;

    const formV = this.step2Form.value;
    let suppliers: IPreferredSuppliers[] = [];    

    if (this.selectedServiceType === 'specific') {
      const selectedSupplier = this.fullSuppliersList.find(s => s.id === this.step3Form.value.selectedSupplierId);
      suppliers = [{
        type: selectedSupplier.type,
        suppliers:[ Object.assign(selectedSupplier, { order: 0 }) ]
      }]
    } else {
      suppliers = this.suppliers.reduce((p, c) => {

        if (c.type === "Locals") {
          p.push({
            type: c.type,
            suppliers: (c.suppliers as IPreferredSuppliers['suppliers']).map(s => {
              const index = this.selectedSuppliers.findIndex(ss => ss.id === s.id);
              s.order = index != -1 ? index : null;
              return s;
            })
          })
        } else if (this.step1Form.controls.serviceType.value !== "specific") {
          p.push({
            type: c.type,
            suppliers: (c.suppliers as IPreferredSuppliers['suppliers'])
          })
        }
  
        return p;
      }, [] as IPreferredSuppliers[])
    }

    this.api.editRequest({
      serviceTypeId: this.serviceTypes.find(s => s.value.toLowerCase() === this.step1Form.controls.serviceType.value).id,
      title: formV.title,
      catalogId: formV.catalogId,
      jobId: formV.jobId,
      startDate: this.internationalizationService.ignoreDateTimezone( formV.startDate ).toISOString(),
      endDate: this.internationalizationService.ignoreDateTimezone( formV.endDate ).toISOString(),
      description: formV.description,
      seniorityId: formV.seniorityId,
      rate: formV.rate,
      projectTypeId: formV.projectTypeId,
      suppliers: suppliers,
      tribeId: formV.tribeId,
      notes: ''
    }).subscribe({ next: (req) => {
      this.isLoading = false;
      
      this.step1Form.markAsUntouched();
      this.step2Form.markAsUntouched();
      this.step3Form.markAsUntouched();

      this.router.navigate(['/projectRequests/request-summary/', req.id]);
    }, error: (error: IError) => {
      this.alert.showAlert({
        type: 'error',
        title: 'common.labels.error',
        message: error.messages.map(e => e.field + "_" + e.key).join(', '),
        acceptText: 'Ok',
        noCancel: true,
        onConfirm: () => {
          this.alert.dismissAlert();
        },
      });

      this.isLoading = false;
    }});
  }
  //#endregion
    
  get hasUnsavedChanges() {
    return this.step1Form.touched || this.step2Form.touched || this.step3Form.touched; 
  }

  onNavigationOut() {
    if (this.hasUnsavedChanges) {
      return this.sharedService.showBackNavigationAlertAsync();
    }
    return true;
  }

}


<div class="dates">
  <span [matTooltip]="'project.fields.startDate' | translate" matTooltipPosition="above">
    {{ dates.startDate | date: internationalizationService.getDateFormatPipeString() }}
  </span>
  <span [matTooltip]="'project.fields.dueDate' | translate" matTooltipPosition="above">
    {{ dates.dueDate | date: internationalizationService.getDateFormatPipeString() }}
  </span>
</div>
<div class="bar">
  <div class="fill" [style.width.%]="100 * completeRatio"></div>
</div>
<div class="text">{{ "project.fields.timing" | translate }} <b>{{ timing }}</b></div>

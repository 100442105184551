<app-page-container
  [pageTitleParts]="['suppliers.title', supplier ? supplier.name : '']"
  (titleClick)="onTitleClick()"
  [condensed]="isCondensed"
>
  <div class="col span-1">
    <app-panel class="flexible" [style]="'default'" [isLoading]="isLoading">
      <div class="panel-actions-container">
        <h3 class="panel-title no-line">{{ "supplier-panel.supplierData" | translate }}</h3>
        <button mat-icon-button *ngIf="canEdit" color="primary" type="button" (click)="editSupplier()">
          <img src="/assets/icons/edit.svg" alt="edit">
        </button>
      </div>
      <div class="summary minimized" [class.expanded]="isSummaryExpanded" [@panelState]="isSummaryExpanded ? 'expanded' : 'minimized'" #summary>
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "supplier.fields.isActive" | translate }}
          </div>
          <div class="summary-item-value status-value">
            <img src="/assets/icons/eye-{{ supplier && supplier.isActive === true ? 'open' : 'closed' }}.svg" 
            [appTooltipNoHover]="('common.labels.' + (supplier && supplier.isActive ? 'active' : 'disabled')) | translate"
            matTooltipPosition="above" alt="Active">
            <span>
              {{ ((supplier && supplier.isActive) ? "common.labels.active" : "common.labels.disabled")| translate }}
            </span>
          </div>
        </div>
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "supplier.fields.email" | translate }}
          </div>
          <div class="summary-item-value">
            {{ (supplier && supplier.email) || "-" }}
          </div>
        </div>
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "supplier.fields.country" | translate }}
          </div>
          <div class="summary-item-value">
            {{ (supplier && supplier.country) || "-" }}
          </div>
        </div>
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "supplier.fields.address" | translate }}
          </div>
          <div class="summary-item-value">
            {{ (supplier && supplier.address) || "-" }}
          </div>
        </div>
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "supplier.fields.timeZone" | translate }}
          </div>
          <div class="summary-item-value">
            {{ (supplier && supplier.timeZone) || "-" }} 
            |
            <span [matTooltip]="'supplier.currentSupplierTime' | translate">{{ getSupplierTime() }}</span>
          </div>
        </div>
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "supplier.fields.category" | translate }}
          </div>
          <div class="summary-item-value">
            {{ (supplier && supplier.category) || "-" }}
          </div>
        </div>
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "supplier.fields.contracts" | translate }}
          </div>
          <div class="summary-item-value">
            {{ (supplier && getListItemValue(supplier.contracts)) || "-" }}
          </div>
        </div>
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "supplier.fields.flagCsm" | translate }}
          </div>
          <div class="summary-item-value status-value">
            <img *ngIf="supplier && supplier.flagCsm" src="/assets/icons/check.svg" alt="flagCsm">
            <span>
              {{ ((supplier && supplier.flagCsm) ? "common.labels.yes" : "common.labels.no") | translate }}
            </span>
          </div>
        </div>
        <div class="summary-item column">
          <div class="summary-item-label">
            {{ "supplier.fields.notes" | translate }}
          </div>
          <div class="summary-item-value" style="white-space: pre-wrap;">
            {{ (supplier && supplier.notes) || "-"  }}
          </div>
        </div>
  
      </div>
      <div class="expand-summary">
        <button mat-button color="primary" (click)="(summary.scrollTop = 0) || isSummaryExpanded = !isSummaryExpanded">
          <app-accordion-icon [up]="isSummaryExpanded"></app-accordion-icon>
          <span>
            {{ (isSummaryExpanded ?  "common.actions.minimize" : "common.actions.expand") | translate }}
          </span>
        </button>
      </div>
    </app-panel>
    <div class="row">
      <app-panel class="span-1" [isLoading]="isLoading" title="{{'supplier.fields.serviceCatalog' | translate}} ({{ (supplier && supplier.servicesCatalog.length) || 0 }})">
        <div class="services-container" *ngIf="supplier != null">
           <div class="service" *ngFor="let service of supplier.servicesCatalog">
             <h3>{{ service.catalog }}</h3>
             <ul>
               <li *ngFor="let job of service.jobs">
                 {{ job.description }}
               </li>
             </ul>
           </div>
           <div *ngIf="supplier.servicesCatalog.length === 0">
            {{ "common.labels.noData" | translate }}
           </div>
        </div>
       </app-panel>
       <app-panel class="span-1" title="{{ 'supplier-panel.activeTribes' | translate }} ({{ tribesLenght }})">
         <div class="services-container">
           <div class="service" *ngFor="let bl of businessLines">
             <h3>{{ bl.name }}</h3>
             <ul>
               <li *ngFor="let tr of bl.tribes">
                 {{ tr.name }}
               </li>
             </ul>
           </div>
           <div *ngIf="businessLines.length === 0">
            {{ "common.labels.noData" | translate }}
           </div>
        </div>
       </app-panel>
    </div>
  </div>

  <div class="col span-1">
    <app-panel class="rating-view-panel">
      <app-rating-view [rating]="supplier?.rating" [ratingsNumber]="supplier?.ratingsNumber"></app-rating-view>
    </app-panel>

    <app-rating-history-panel class="rating-panel" [isLoading]="isScorecardLoading" [data]="supplierRatingGraphData"></app-rating-history-panel>

    <div class="table-container">
      <div class="loading" [class.is-loading]="isLoading === true">
        <mat-spinner color="primary" diameter="120"></mat-spinner>
      </div>

      <div style="position: relative;">
        <h3 class="panel-title" [ngSwitch]="projectStatusFilter">
          <ng-container *ngSwitchCase="'ALL'">{{ 'supplier-panel.projects' | translate }}</ng-container>
          <ng-container *ngSwitchCase="'OPN'">{{ 'supplier-panel.activeProjects' | translate }}</ng-container>
          <ng-container *ngSwitchCase="'CLS'">{{ 'supplier-panel.closedProjects' | translate }}</ng-container>
        </h3>
        <div class="table-summary">
          <span> {{ "projects.totalElements" | pluralTranslate:projectsPageData.totalElements | translate : { totalElements: projectsPageData.totalElements } }} </span>
        </div>
        <div class="panel-actions">

          <div class="mat-form-field force-styling">
            <mat-label class="mat-button-toggle-label">{{ "supplier-panel.filterByStatus" | translate }}</mat-label>
            <mat-button-toggle-group [value]="projectStatusFilter" (valueChange)="(projectStatusFilter = $event) && supplier && changePagedProjects(0)" aria-label="status">
              <mat-button-toggle [value]="'ALL'">
                {{ "common.labels.all" | translate }}
              </mat-button-toggle>
              <mat-button-toggle value="OPN">
                <span class="project-status-indicator" status-code="OPN"></span>
                {{ "project.status.OPN" | translate }}
              </mat-button-toggle>
              <mat-button-toggle value="CLS">
                <span class="project-status-indicator" status-code="CLS"></span>
                {{ "project.status.CLS" | translate }}
              </mat-button-toggle>
            </mat-button-toggle-group>  
          </div>

          <button mat-icon-button *ngIf="canEdit" color="primary" type="button" [matTooltip]="'supplier-panel.goToProjectList' | translate" (click)="goToProjectsList()">
            <img src="/assets/icons/arrow-up-outlined.svg" alt="go to list">
          </button>
        </div>
      </div>
  
      <div class="inner-table-container projects-inner-table-container"> 
        <div>
          <table mat-table [dataSource]="projectsDatasource" style="min-width: 100%; max-height: 100%;"
            matSort [matSortActive]="projectsPageData.sort?.active" [matSortDirection]="projectsPageData.sort?.direction" (matSortChange)="changePagedProjects(null, null, $event)" 
          >
    
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "project.fields.title" | translate }} </th>
              <td mat-cell *matCellDef="let project">
                {{ project.title }}
              </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "project.fields.startDate" | translate }} </th>
              <td mat-cell *matCellDef="let project">
                {{ project.startDate | date: internationalizationService.getDateFormatPipeString() }}
              </td>
            </ng-container>
            <ng-container matColumnDef="endDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "project.fields.endDate" | translate }} </th>
              <td mat-cell *matCellDef="let project">
                {{ project.endDate | date: internationalizationService.getDateFormatPipeString() }}
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let project">
                <button mat-icon-button (click)="goToProjectPanel(project)"
                  [appTooltipNoHover]="'projects.actions.goToPanel' | translate" matTooltipPosition="above"
                >
                <img src="/assets/icons/info.svg" alt="info">
              </button>
              </td>
            </ng-container>
  
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell">
                {{ "common.labels.noData" | translate }}
              </td>
            </tr>
          
            <tr mat-header-row *matHeaderRowDef="projectsDisplayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: projectsDisplayedColumns;"></tr>
          </table>
        </div>
      </div>
      <mat-paginator 
        showFirstLastButtons
        [pageSizeOptions]="[10, 15, 20]" 
        [pageSize]="projectsPageData.size" 
        [length]="projectsPageData.totalElements" 
        [pageIndex]="projectsPageData.index"
        (page)="changePagedProjects($event.pageIndex, $event.pageSize)"
      ></mat-paginator>
    </div>
  </div>

</app-page-container>

<app-page-container
  [condensed]="isCondensed"
  [pageTitleParts]="[ 'requests.title', request ? request.title : 'request-summary.title' ]"
  [bottomActions]="contextActions"
  (actionClicked)="onActionClicked($event)"
  (titleClick)="onTitleClick()"
>
  <app-panel [style]="'default'" [isLoading]="isLoading" [title]="'request-wizard.step3.form-summary' | translate">
    <div class="summary">
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "request.fields.title" | translate }}
        </div>
        <div class="summary-item-value">
          {{ request.title || "-" }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "request.fields.status" | translate }}
        </div>
        <div class="summary-item-value status-value">
          <span class="request-status-indicator" [attr.status-code]="request.status"></span>
          <span>
            {{ ('request.status.' + request.status) | translate }}
          </span>
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "request.fields.serviceType" | translate }}
        </div>
        <div class="summary-item-value">
          {{ request.serviceType || "-" }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "request.fields.catalog" | translate }}
        </div>
        <div class="summary-item-value">
          {{ request.catalog || "-" }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "request.fields.job" | translate }}
        </div>
        <div class="summary-item-value">
          {{ request.job }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "request.fields.user" | translate }}
        </div>
        <div class="summary-item-value">
          {{ request.user?.email || "-"  }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "request.fields.creationDate" | translate }}
        </div>
        <div class="summary-item-value">
          {{ (request.creationDate | date: internationalizationService.getDateFormatPipeString() ) || "-"  }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "request.fields.startDate" | translate }}
        </div>
        <div class="summary-item-value">
          {{ (request.startDate| date: internationalizationService.getDateFormatPipeString() ) || "-"  }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "request.fields.endDate" | translate }}
        </div>
        <div class="summary-item-value">
          {{ (request.endDate| date: internationalizationService.getDateFormatPipeString() ) || "-"  }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "request.fields.description" | translate }}
        </div>
        <div class="summary-item-value">
          {{ request.description || "-"  }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "request.fields.seniority" | translate }}
        </div>
        <div class="summary-item-value">
          {{ request.seniority || "-"  }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "request.fields.rate" | translate }}
        </div>
        <div class="summary-item-value">
          {{ request.rate || "-"  }}
        </div>
      </div>
      <div class="summary-item column">
        <div class="summary-item-label">
          {{ "request.fields.notes" | translate }}
          <button class="edit-button" mat-icon-button (click)="editNotes()"
            [appTooltipNoHover]="'requests.actions.editNotes' | translate" matTooltipPosition="above" 
          >
            <img src="/assets/icons/edit.svg" alt="notes">
          </button>
        </div>
        <div class="summary-item-value" style="white-space: pre-wrap;">
          {{ request.notes || "-"  }}
        </div>
      </div>
    </div>
  </app-panel>

  <ng-container [ngSwitch]="request ? request.serviceType : ''">
    <ng-container *ngSwitchDefault>
      <app-accordion-panel [isLoading]="isLoading" [style]="'default'" title="IntraGroup ({{ getSuppliersList('IntraGroup').length }})">
        <ng-container *ngTemplateOutlet="supplierListTemplate; context: { suppliers: getSuppliersList('IntraGroup') }"></ng-container>
      </app-accordion-panel>
      <app-accordion-panel [isLoading]="isLoading" [style]="'default'" title="SSI ({{ getSuppliersList('SSI').length }})">
        <ng-container *ngTemplateOutlet="supplierListTemplate; context: { suppliers: getSuppliersList('SSI') }"></ng-container>
      </app-accordion-panel>
      <app-accordion-panel [isLoading]="isLoading" [style]="'default'" title="Locals ({{ getSuppliersList('Locals').length }})">
        <div class="selected-suppliers">
          <div *ngFor="let supplier of getSelectedSuppliers('Locals')" class="selected-supplier-item">
            <div class="preferred-icon" [attr.preferred-position]="supplier.order + 1">
              <img src="/assets/icons/star/full.svg">
            </div>
            {{ supplier.name }}
          </div>
        </div>
        <ng-container *ngTemplateOutlet="localSupplierListTemplate; context: { suppliers: getSuppliersList('Locals') }"></ng-container>
      </app-accordion-panel>
    </ng-container>
    <ng-container *ngSwitchCase="'Specific'">
      <app-accordion-panel [isLoading]="isLoading" [style]="'default'" title="{{ 'suppliers.title' | translate }} ({{ getSpecificSuppliers().length }})">
       <!--  <mat-form-field class="search-box">
          <mat-label>{{ "common.labels.search" | translate }}</mat-label>
          <input matInput [placeholder]="'common.labels.search' | translate" #supplierSearch>
        </mat-form-field> -->
        <ng-container *ngTemplateOutlet="localSupplierListTemplate; context: { suppliers: getSpecificSuppliers() }"></ng-container>
      </app-accordion-panel>
    </ng-container>
  </ng-container>

</app-page-container>

<ng-template #supplierListTemplate let-suppliers="suppliers">
  <div class="suppliers-list">
    <div *ngFor="let supplier of suppliers" class="supplier-list-item">
      <div>
        {{ supplier.name }}
      </div>
      <div>
        <app-rating-view [rating]="supplier.rating" [ratingsNumber]="supplier.ratingsNumber"></app-rating-view>
      </div>
    </div>
    <div *ngIf="suppliers.length === 0" class="no-data">
      {{ "common.labels.noData" | translate }}
    </div>
  </div>
</ng-template>

<ng-template #localSupplierListTemplate let-suppliers="suppliers">
  <div class="suppliers-list">
    <div *ngFor="let supplier of suppliers" class="supplier-list-item" [class.selected]="supplier.order !== null"> 
      <div>
        <mat-checkbox
          [checked]="supplier.order !== null"
          [disabled]="true"
          color="secondary"
        ></mat-checkbox>
      </div>
      <div [matBadge]="supplier.order != null ? supplier.order + 1 : null" matBadgeSize="small" matBadgeOverlap="false" style="margin-right: 5px">
        {{ supplier.name }}
      </div>
      <div>
        <app-rating-view [rating]="supplier.rating" [ratingsNumber]="supplier.ratingsNumber"></app-rating-view>
      </div>
    </div>
    <div *ngIf="suppliers.length === 0" class="no-data">
      {{ "common.labels.noData" | translate }}
    </div>
  </div>
</ng-template>

<ng-template #editNotesModalTemplate let-requestId="requestId">
  <mat-form-field class="full-width">
    <mat-label>{{ "supplier.fields.notes" | translate }}</mat-label>
    <textarea matInput [ngModel]="textAreaValue" (ngModelChange)="textAreaValue = $event" placeholder="{{ 'supplier.fields.notes' | translate }}"></textarea>
  </mat-form-field>
  <div class="actions-row">
    <button mat-button (click)="editNotesAction(requestId, false)">{{ "common.actions.cancel" | translate }}</button>
    <button mat-flat-button color="primary" (click)="editNotesAction(requestId, true)">{{ "common.actions.confirm" | translate }}</button>
  </div>
</ng-template>
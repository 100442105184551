<div class="main-container">
  <div class="header" *ngIf="!condensed">
    <div class="title" [class.isFullScreen]="sharedService.isFullScreen">
      <img src="../../../assets/images/logo.png" alt="ING">
  
      <h1>
        @for (title of pageTitleParts; track title; let index = $index, isLast = $last) {
          <span (click)="emitTitleClick(index)">{{ title | translate }}</span>
          <img *ngIf="!isLast" class="arrow-icon" src="../../../assets/icons/arrow-right.svg" alt="arrow">
        }
      </h1>
    </div>
    <button  mat-stroked-button color="primary" (click)="showActions = !showActions">Actions</button>
    <div class="actions-container" [class.show-actions]="showActions">
      <ng-container *ngFor="let action of actions">
        <ng-container *ngTemplateOutlet="actionTemplate; context: { action, selected: action.activeName && activeActions.includes(action.key) }"></ng-container>
      </ng-container>
      <ng-container *ngFor="let action of defaultActions">
        <ng-container *ngTemplateOutlet="defaultActionTemplate; context: { action, selected: action.activeName && activeActions.includes(action.key) }"></ng-container>
      </ng-container> 

    </div>
    <ng-container *ngTemplateOutlet="headerContent"></ng-container>
    <div class="divider"></div>
    
    
    <ng-template #actionTemplate let-action="action" let-selected="selected">
      <div *ngIf="action != null" matRipple class="action" id="action_{{action.key}}" [matMenuTriggerFor]="action.menuRef" [class.active]="selected" (click)="toggleAction(action)">
        <img src="../../../assets/icons/{{ selected ? action.activeIcon : action.icon }}" [alt]="action.name">
        <span>{{ (selected ? action.activeName : action.name ) | translate }}</span>
      </div>
    </ng-template>
    
    <ng-template #defaultActionTemplate let-action="action" let-selected="selected">
      <div *ngIf="action != null" matRipple class="action" [class.active]="selected" (click)="toggleDefaultAction(action)">
        <img src="../../../assets/icons/{{ selected ? action.activeIcon : action.icon }}" [alt]="action.name">
        <span>{{ (selected ? action.activeName : action.name) | translate }}</span>
      </div>
    </ng-template>
  </div>
  <div class="page-container">
    <div [class.row]="direction === 'row'" [class.wrap]="wrap === 'wrap'">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="footer" *ngIf="!condensed && (leftBottomActions.length > 0 || rightBottomActions.length > 0)">
    <div class="divider"></div>
    <div class="actions-container">
      <button mat-flat-button *ngFor="let action of leftBottomActions" type="button" 
        (click)="toggleAction(action)"
        [disabled]="action.disabled"
        [color]="action.style || 'accent'" 
      >
        {{ action.name | translate }}
      </button>
      <div style="flex: 1 1 0;"></div>
      <button mat-flat-button *ngFor="let action of rightBottomActions" type="button" 
        (click)="toggleAction(action)"
        [disabled]="action.disabled"
        [color]="action.style || 'accent'" 
      >
        {{ action.name | translate }}
      </button>
    </div>
  </div>
</div>
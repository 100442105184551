import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-accordion-icon',
  templateUrl: './accordion-icon.component.html',
  styleUrl: './accordion-icon.component.scss',
})
export class AccordionIconComponent {

  @Input() set up(up: boolean) {
    this.invert = up;
  }

  @HostBinding('class.invert') invert: boolean = false;

}

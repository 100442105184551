import { ISODateString } from "../types/ISODateString";
import { ERequestStatus } from "./request";
import { ISupplier } from "./supplier";
import { IUser } from "./user";


export interface IProjectCondensed {
  name: string;
  startDate: ISODateString;
  endDate: ISODateString;
}

export enum EDocumentType {
  "ODS" = "ODS",
  "Decision Tree" = "TREE",
  "ITSR" = "ITSR",
  "GDPR" = "GDPR",
  "Other" = "OTHER",
}

interface IDocument {
  type: EDocumentType;
  flagComplete: boolean;
}

export enum EProjectStatus {
  "Open" = "OPN",
  "Closed" = "CLS",
}

export interface IProject extends IProjectCondensed {
  id: number;
  title: string;
  requestId: number;
  requestTitle: string;
  businessLineId: number;
  businessLine: string;
  tribeId: number;
  tribe: string;
  leadId: number;
  lead: string;
  creationDate: ISODateString;
  user: IUser;
  supplier: ISupplier;
  contractId: number;
  contract: string;
  externalFTE: number;
  tpoId: number;
  tpo: string;
  serviceTypeId: number;
  serviceType: string;
  fteRoleId: number;
  fteRole: string;
  technologyId: number;
  technology: string;
  contractOwnerId: number;
  contractOwner: string;
  contractManagerId: number;
  contractManager: string;
  startDate: ISODateString;
  endDate: ISODateString;
  dueDate?: ISODateString;
  timing?: string;
  countryCode?: string;
  country?: string;
  timeZoneId?: string;
  timeZone?: string;
  timeZoneLocale?: string;
  miscellous?: string;
  flagDocumentsComplete: boolean;
  documents: IDocument[];
  rating?: number;
  ratingsNumber: number;
  missingRatings: number;
  status: EProjectStatus;
}

export interface IEditProject {
  id?: number,
  requestId: number,
  title: string,
  tribeId: number,
  tpoId: number,
  supplierId: number,
  externalFTE: number,
  contractId: number,
  serviceTypeId: number,
  fteRoleId: number,
  technologyId: number,
  contractOwnerId: number,
  contractManagerId: number,
  startDate: ISODateString,
  endDate: ISODateString,
  dueDate: ISODateString,
  miscellous: string,
}

export interface IProjectDocInfo {
  id: number,
  title: string,
  documents: IDocument[];
}
export interface IProjectDocInfoKeyed {
  id: number,
  title: string,
  documents: { [key in EDocumentType]: boolean };
}
<app-page-container
  [pageTitleParts]="[ 'projects.title', (project && project.title) ]"
  [bottomActions]="bottomActions"
  (titleClick)="onTitleClick()"
  (actionClicked)="onActionClicked($event)"
>
  <div class="span-full row">
    <app-panel
    class="span-1"
    [style]="'default'"
    [isLoading]="isProjectLoading"
    >
      <ng-container *ngIf="project != null">
        <h2>
          <span class="project-status-indicator" [attr.status-code]="project.status" [appTooltipNoHover]="('project.status.' + project.status) | translate" matTooltipPosition="above" ></span>
          {{ project.title }}
        </h2>
        <span>
          <span [matTooltip]="'project.fields.businessLine' | translate" matTooltipPosition="below">
            {{ project.businessLine }} -
          </span>
          <span [matTooltip]="'project.fields.tribe' | translate" matTooltipPosition="below">
            {{ project.tribe }} -
          </span>
          <span [matTooltip]="'project.fields.user' | translate" matTooltipPosition="below">
            {{ project.user?.email }}
          </span>
        </span>
      </ng-container>
    </app-panel>

    <app-panel
      class="span-2"
      [style]="'default'"
      [isLoading]="isProjectLoading"
    >
      <app-project-timing
        [project]="project"
      ></app-project-timing>

    </app-panel>
    
    <app-panel
      class="center-content span-1"
      [style]="'default'"
      [isLoading]="isProjectLoading"
    >
      <app-rating-view class="rating-view"
        [rating]="project && project.rating"
        [ratingsNumber]="project && project. ratingsNumber"
      ></app-rating-view>
    </app-panel>
  </div>

 
  <app-panel [open]="true" class="span-full">
    <div class="panel-actions-container">
      <h3 class="panel-title no-line">{{ "project-wizard.step4.summary" | translate }}</h3>
      <button mat-icon-button color="primary" type="button" (click)="editProject()">
        <img src="/assets/icons/edit.svg" alt="edit">
      </button>
    </div>

    <div class="summary">

  <!--     <div class="summary-group">
        <h3>Anagrafica</h3> -->
        <!-- <div class="summary-item">
          <div class="summary-item-label">
            {{ "project.fields.user" | translate }}
          </div>
          <div class="summary-item-value">
            {{ project.user.email || "-" }}
          </div>
        </div>
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "project.fields.tribe" | translate }}
          </div>
          <div class="summary-item-value">
            {{ project.businessLine }} - {{ project.tribe }}
          </div>
        </div> -->
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "project.fields.endDate" | translate }}
          </div>
          <div class="summary-item-value">
            {{ (project && project.endDate) | date: internationalizationService.getDateFormatPipeString() || "-" }}
          </div>
        </div>
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "project.fields.lead" | translate }}
          </div>
          <div class="summary-item-value">
            {{ (project && project.lead) }} - {{ (project && project.tribe) }}
          </div>
        </div>

 <!--      </div> -->

      <!-- <div class="summary-group">
        <h3>Request</h3> -->
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "project.fields.request" | translate }}
          </div>
          <div class="summary-item-value">
            {{ (project && project.requestTitle) || "-" }}
            <img src="/assets/icons/info.svg" (click)="openRequestDetails()">
          </div>
        </div>
  <!--     </div> -->
<!-- 
      <div class="summary-group">
        <h3>Supplier</h3> -->
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "project-wizard.step4.selectedSupplierSummary" | translate }}
          </div>
          <div class="summary-item-value">
            {{ (project && project.supplier && project.supplier.name) || "-" }}
            <img src="/assets/icons/info.svg" (click)="openSupplierDetails()">
          </div>
        </div>
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "project.fields.supplierCategory" | translate }}
          </div>
          <div class="summary-item-value">
            {{ (project && project.supplier && project.supplier.category) || "-" }}
          </div>
        </div>
        <div class="summary-item">
          <div class="summary-item-label">
            {{ "project.fields.country" | translate }}
          </div>
          <div class="summary-item-value">
            {{ (project && project.supplier && project.supplier.country) || "-" }}
          </div>
        </div>
        <div class="summary-item">
          <div class="summary-item-label">
            Timezone
          </div>
          <div class="summary-item-value">
            {{ (project && project.timeZone) || "-" }}
            |
            {{ getSupplierTime() }}
          </div>
        </div>
       
  <!--     </div>
   -->
      
       <div class="summary-item">
         <div class="summary-item-label">
           {{ "project.fields.tpo" | translate }}
         </div>
         <div class="summary-item-value">
           {{ (project && project.tpo) || "-" }}
         </div>
       </div>

       <div class="summary-item">
        <div class="summary-item-label">
          {{ "project.fields.contractManager" | translate }}
        </div>
        <div class="summary-item-value">
          {{ (project && project.contractManager) }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "project.fields.contractOwner" | translate }}
        </div>
        <div class="summary-item-value">
          {{ (project && project.contractOwner) || "-" }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "project.fields.externalFTE" | translate }}
        </div>
        <div class="summary-item-value">
          {{ (project && project.externalFTE) || "-" }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "project.fields.contract" | translate }}
        </div>
        <div class="summary-item-value">
          {{ (project && project.contract) || "-" }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "project.fields.serviceType" | translate }}
        </div>
        <div class="summary-item-value">
          {{ (project && project.serviceType) || "-" }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "project.fields.fteRole" | translate }}
        </div>
        <div class="summary-item-value">
          {{ (project && project.fteRole) || "-" }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "project.fields.technology" | translate }}
        </div>
        <div class="summary-item-value">
          {{ (project && project.technology) || "-" }}
        </div>
      </div>
      
      

   
     <!--  <div class="summary-item">
        <div class="summary-item-label">
          {{ "project.fields.startDate" | translate }}
        </div>
        <div class="summary-item-value">
          {{ (project && project.startDate) | date: internationalizationService.getDateFormatPipeString() || "-" }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "project.fields.endDate" | translate }}
        </div>
        <div class="summary-item-value">
          {{ (project && project.endDate) | date: internationalizationService.getDateFormatPipeString() || "-" }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "project.fields.dueDate" | translate }}
        </div>
        <div class="summary-item-value">
          {{ (project && project.dueDate) | date: internationalizationService.getDateFormatPipeString() || "-" }}
        </div>
      </div>
      <div class="summary-item">
        <div class="summary-item-label">
          {{ "project.fields.timing" | translate }}
        </div>
        <div class="summary-item-value">
          {{ (project && project.timing) || "-" }}
        </div>
      </div> -->


      <div class="summary-item">
        <div class="summary-item-label">
          {{ "project.fields.miscellous" | translate }}
        </div>
        <div class="summary-item-value" style="white-space: pre-wrap;">
          {{ (project && project.miscellous) || "-" }}
        </div>
      </div>
    </div>
  </app-panel>

  <div class="table-container span-half">
    <div class="table-summary" style="padding-top: 18px; margin-bottom: 15px;">
      <h3 class="panel-title" style="margin: 0;">{{ "project-panel.documentsChecklist" | translate }}</h3>
      <div class="divider"></div>
      <button mat-flat-button color="primary" *ngIf="project != null" (click)="toggleCompleteFlag()">
        <img class="button-icon white-icon" [src]="project.flagDocumentsComplete ? '/assets/icons/close.svg' : '/assets/icons/check.svg'">
        {{ (project.flagDocumentsComplete ? "documents.actions.removeForceFlag" : "documents.actions.forceFlag") | translate }}
      </button>
    </div>

    <div class="inner-table-container"> 
      <table class="mat-table mat-mdc-table mdc-data-table__table cdk-table" style="min-width: 100%;" *ngIf="project != null">
        <thead role="rowgroup">
          <tr class="mat-header-row mat-mdc-header-row mdc-data-table__header-row cdk-header-row">
            <th role="columnheader" class="text-center mat-header-cell mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell" *ngFor="let doc of project.documents">{{ ('document.types.' + doc.type) | translate }}</th>
          </tr>
        </thead>
        <tbody role="rowgroup" class="mdc-data-table__content">
          <tr class="mat-row mat-mdc-no-hover-row">
            <td role="row" class="mat-cell document-check" *ngFor="let doc of project.documents">
              <img src="/assets/icons/{{ doc.flagComplete ? 'check' : 'warn-filled-lime' }}.svg" [matTooltip]="(doc.flagComplete ? 'document.status.complete' : 'document.status.incomplete') | translate" alt="document status">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

<!--   <app-panel class="graph-panel">
    <h3 class="panel-title">{{  "rating.ratingTrend" | translate }}</h3>
    <div class="graph-row">
      <div class="graph-container">
        <ngx-charts-line-chart
          hideXAxisTicks
          [showGridLines]="true"
          [yScaleMin]="0"
          [yScaleMax]="5"
          [yAxisTicks]="[ 0, 1, 2, 3, 4, 5 ]"
          [maxXAxisTickLength]="100"
          [xAxis]="true"
          [yAxis]="true"
          [xAxisTickFormatting]="getGraphLabel"
          [yAxisTickFormatting]="removeDecimal"
          [results]="graphData"
        ></ngx-charts-line-chart>
      </div>
    </div>
    <div class="years-labels">
      <span *ngFor="let year of yearsLabels">{{ year }}</span>
    </div>
  </app-panel> -->
  <app-rating-history-panel class="rating-panel" [minRatingDate]="graphMinDate" [maxRatingDate]="graphMaxDate" [isLoading]="isRatingLoading" [data]="ratingData"></app-rating-history-panel>


  <div class="table-container">
    <div class="loading" [class.is-loading]="isDocumentsLoading === true">
      <mat-spinner color="primary" diameter="120"></mat-spinner>
    </div>

    <div class="table-summary">
      <span> {{ "documents.totalElements" | pluralTranslate:documentsPageData.totalElements | translate : { totalElements: documentsPageData.totalElements } }} </span>
      <div class="divider"></div>
      <button mat-flat-button color="primary" (click)="addDocumentClicked()">
        <img class="button-icon white-icon" src="/assets/icons/add-document.svg">
        {{ "documents.actions.addDocument" | translate }}
      </button>
    </div>

    <div class="inner-table-container"> 
      <table mat-table [dataSource]="documentsDataSource" style="min-width: 100%;"
        matSort [matSortActive]="documentsPageData.sort?.active" [matSortDirection]="documentsPageData.sort?.direction" (matSortChange)="changeDocumentsPage(null, null, $event)" 
      >

        <ng-container matColumnDef="insertDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "document.fields.insertDate" | translate }} </th>
          <td mat-cell *matCellDef="let document"> {{ document.insertDate | date: internationalizationService.getDateFormatPipeString() }} </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "document.fields.type" | translate }} </th>
          <td mat-cell *matCellDef="let document"> {{  ('document.types.' + document.type) | translate }} </td>
        </ng-container>
        <ng-container matColumnDef="url">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "document.fields.url" | translate }} </th>
          <td mat-cell *matCellDef="let document"><a [href]="document.url" target="_blank" rel="noreferrer noopener">{{ document.name }}</a></td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
          <td mat-cell *matCellDef="let document">
            <button mat-icon-button color="primary" type="button" (click)="deleteDocumentClicked(document)"
              [appTooltipNoHover]="'documents.actions.delete' | translate" matTooltipPosition="above"
            >
              <img src="/assets/icons/delete.svg" alt="delete">
            </button>
          </td>
        </ng-container>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell">
            {{ "common.labels.noData" | translate }}
          </td>
        </tr>
      
        <tr mat-header-row *matHeaderRowDef="documentsDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: documentsDisplayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator 
      showFirstLastButtons
      [pageSizeOptions]="[5]" 
      [pageSize]="documentsPageData.size" 
      [length]="documentsPageData.totalElements" 
      [pageIndex]="documentsPageData.index"
      (page)="changeDocumentsPage($event.pageIndex, $event.pageSize)"
    ></mat-paginator>
  </div>

  <div class="table-container">
    <div class="loading" [class.is-loading]="isRatingsLoading === true">
      <mat-spinner color="primary" diameter="120"></mat-spinner>
    </div>

    <div class="table-summary">
      <span> {{ "ratings.totalElements" | pluralTranslate:ratingsPageData.totalElements | translate : { totalElements: ratingsPageData.totalElements } }} </span>
      <div class="divider"></div>
      <button mat-flat-button color="primary" (click)="addRatingClicked()">
        <img class="button-icon white-icon" src="/assets/icons/add-rating.svg">
        {{ "ratings.actions.addRating" | translate }}
      </button>
    </div>

    <div class="inner-table-container"> 
      <table mat-table [dataSource]="ratingsDataSource" style="min-width: 100%;"
        matSort [matSortActive]="ratingsPageData.sort?.active" [matSortDirection]="ratingsPageData.sort?.direction" (matSortChange)="changeRatingsPage(null, null, $event)" 
      >

        <ng-container matColumnDef="insertDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "rating.fields.insertDate" | translate }} </th>
          <td mat-cell *matCellDef="let rating"> {{ rating.insertDate | date: internationalizationService.getDateFormatPipeString()  }} </td>
        </ng-container>
        <ng-container matColumnDef="year">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "rating.fields.year" | translate }} </th>
          <td mat-cell *matCellDef="let rating"> {{ rating.year }} </td>
        </ng-container>
        <ng-container matColumnDef="quarter">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "rating.fields.quarter" | translate }} </th>
          <td mat-cell *matCellDef="let rating"> Q{{ rating.quarter }} </td>
        </ng-container>
        <ng-container matColumnDef="rating">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "rating.fields.rating" | translate }} </th>
          <td mat-cell *matCellDef="let rating"> {{ rating.rating }} </td>
        </ng-container>
        <ng-container matColumnDef="rating-actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
          <td mat-cell *matCellDef="let rating">
            <button mat-icon-button color="primary" type="button" (click)="openRatingModal(rating)"
              [appTooltipNoHover]="'ratings.actions.showAnswers' | translate" matTooltipPosition="above"
            >
              <img src="/assets/icons/view-rating.svg" alt="info">
            </button>
            <button mat-icon-button color="primary" type="button" (click)="deleteRatingClicked(rating)"
              [appTooltipNoHover]="'ratings.actions.delete' | translate" matTooltipPosition="above"
            >
              <img src="/assets/icons/delete.svg" alt="delete">
            </button>
          </td>
        </ng-container>


        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell">
            {{ "common.labels.noData" | translate }}
          </td>
        </tr>
      
        <tr mat-header-row *matHeaderRowDef="ratingsDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: ratingsDisplayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator 
      showFirstLastButtons
      [pageSizeOptions]="[5]" 
      [pageSize]="ratingsPageData.size" 
      [length]="ratingsPageData.totalElements" 
      [pageIndex]="ratingsPageData.index"
      (page)="changeRatingsPage($event.pageIndex, $event.pageSize)"
    ></mat-paginator>
  </div>
</app-page-container>

<ng-template #requestSummary>
  <h3>{{ "project-panel.selectedRequestSummary" | translate }}</h3>
  <app-request-summary *ngIf="isRequestSummaryVisible" [requestId]="project.requestId"></app-request-summary>
</ng-template>
<ng-template #supplierSummary>
  <h1 style="margin: 0;">{{ project.supplier.name }}</h1>
  <app-supplier-panel *ngIf="isSupplierSummaryVisible" [supplierId]="project.supplier.id"></app-supplier-panel>
</ng-template>

<ng-template #ratingViewModal let-rating="rating" let-answers="answers">
  <h3>{{ "project.fields.rating" | translate }} {{ rating.insertDate | date: internationalizationService.getDateFormatPipeString() }} (Q{{ rating.quarter }})</h3>
  <app-rating-form [answers]="answers" [readonly]="true"></app-rating-form>
</ng-template>

<ng-template #insertDocumentModalTemplate>
  <form [formGroup]="documentForm" class="document-form">
    <mat-form-field>
      <mat-label>{{ "document.fields.name" | translate }}</mat-label>
      <input matInput formControlName="name" >
      <mat-error>{{ "common.errors.requiredField" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "document.fields.type" | translate }}</mat-label>
      <mat-select formControlName="type" >
        <mat-option *ngFor="let type of documentTypes" [value]="type.id">{{ ('document.types.' + type.id) | translate }}</mat-option>
      </mat-select>
      <mat-error>{{ "common.errors.requiredField" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "document.fields.url" | translate }}</mat-label>
      <input matInput formControlName="url" >
      <mat-error>{{ "common.errors.requiredField" | translate }}</mat-error>
    </mat-form-field>
  </form>
</ng-template>
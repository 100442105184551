<app-page-container
  [pageTitleParts]="[ 'users.title', (userId != null ? userForm.controls.email.value : 'user.newUser') ]"
  (titleClick)="onTitleClick()"
  [bottomActions]="contextActions"
  (actionClicked)="onActionClicked($event)"
>
  <app-panel
    [style]="'default'"
    [isLoading]="isLoading"
  >
    <form class="vertical-form" [formGroup]="userForm" (ngSubmit)="submitForm()" autocomplete="off">
      <!-- tags to force browsers to not autocomplete the fields below -->
      <input id="username" style="display:none" type="text" autocomplete="off" name="fakeusernameremembered">
      <input id="password" style="display:none" type="password" autocomplete="new-password" name="fakepasswordremembered">

      <span *ngIf="getFormErrors() != ''" class="error-label full-width mat-mdc-form-field-error mat-mdc-form-field-bottom-align">{{ getFormErrors() | translate }}</span>
        
      <mat-form-field class="full-width view-field">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" placeholder="Email" autocomplete="email">
        <mat-error>{{ getFieldError('email') | translate }}</mat-error>
      </mat-form-field>
      
      <mat-form-field class="span-2">
        <mat-label>{{ "user.fields.role" | translate }}</mat-label>
        <mat-select formControlName="role" placeholder="Tribe">
          <mat-option *ngFor="let role of roles" [value]="role">{{ ("user.roles." + role) | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="mat-form-field span-2">
        <mat-label class="mat-button-toggle-label">{{ "user.fields.isActive" | translate }}</mat-label>
        <mat-button-toggle-group formControlName="isActive" aria-label="isActive">
          <mat-button-toggle [value]="true">{{ "common.labels.active" | translate }}</mat-button-toggle>
          <mat-button-toggle [value]="false">{{ "common.labels.disabled" | translate }}</mat-button-toggle>
        </mat-button-toggle-group>  
      </div>


      
      <h3>{{ "user.classification" | translate }}</h3>
      <span *ngIf="getFieldError('tribes') != ''" class="error-label full-width mat-mdc-form-field-error mat-mdc-form-field-bottom-align">{{ getFieldError('tribes') | translate }}</span>

      <ng-container formArrayName="tribes">
        <ng-container *ngFor="let tribe of userForm.controls.tribes.controls; let i = index" [formGroupName]="i">
            <div class="classification-group mat-form-field full-width" [class.error]="!tribe.valid">
              <div class="remove-button"> 
                <button mat-icon-button type="button" (click)="removeTribe(i)">
                  <img src="/assets/icons/delete.svg" alt="remove" >
                </button>
              </div>
              
              <mat-form-field>
                <mat-label>Business Line</mat-label>
                <mat-select formControlName="businessLineId" placeholder="Business Line" (valueChange)="onBuinessLineChange(i, $event)">
                  <mat-option [value]="null">{{ "common.labels.empty" | translate }}</mat-option>
                  <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">{{ businessLine.name }}</mat-option>
                </mat-select>
                <mat-error>{{ "common.errors.requiredField" | translate }}</mat-error>
              </mat-form-field>
        
              <mat-form-field>
                <mat-label>Tribe</mat-label>
                <mat-select formControlName="tribeId" placeholder="Tribe" (opened)="tribesFilter.value = ''" >
                  <div class="select-search">
                    <mat-form-field>
                      <mat-label>{{ "common.labels.search" | translate }}</mat-label>
                      <input matInput [placeholder]="'common.labels.search' | translate" #tribesFilter>
                    </mat-form-field>
                  </div>
                  <mat-option [value]="null">{{ "common.labels.empty" | translate }}</mat-option>
                  <mat-option *ngFor="let trib of getTribesOf(tribe.value.businessLineId)" 
                    [attr.data-hidden]="!trib.name.toLowerCase().includes(tribesFilter.value.toLowerCase())" 
                    [value]="trib.id">
                    {{ trib.name }}
                  </mat-option>
                </mat-select>
                <mat-error>{{ "common.errors.requiredField" | translate }}</mat-error>
              </mat-form-field>
             
            </div>
        </ng-container>
      </ng-container>
      <div class="classification-group mat-form-field full-width">
        <div class="add-button"> 
          <button mat-mini-fab type="button" color="primary" (click)="addTribe()">
            <img src="/assets/icons/add.svg" class="white-icon" alt="add" >
          </button>
        </div>
        <div class="fake-field"></div>
        <div class="fake-field"></div>
      </div>

      <h3>Password</h3>
      <mat-form-field class="span-4">
        <mat-label>{{ "user.fields.newPassword" | translate }}{{ userId != null ? "" : "*" }}</mat-label>
        <input matInput formControlName="newPassword" autocomplete="new-password" type="password" placeholder="New Password">
        <mat-error>{{ getFieldError('newPassword') | translate }}</mat-error>
      </mat-form-field>
      
      <mat-form-field class="span-4">
        <mat-label>{{ "user.fields.confirmPassword" | translate }}</mat-label>
        <input matInput formControlName="confirmPassword" autocomplete="new-password" type="password" placeholder="Confirm Password">
        <mat-error>{{ getFieldError('confirmPassword') | translate }}</mat-error>
      </mat-form-field>

    </form>
  </app-panel>
</app-page-container>
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { IAlert, ICustomAlertModel } from '../../models/alert';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService implements OnDestroy, OnInit {
  private isAlertVisible = false;
  public showAlertEvent = new Subject<boolean>();
  public alertData: IAlert | ICustomAlertModel;

  constructor() { }

  public showAlert(alert: IAlert | ICustomAlertModel) {
    this.alertData = alert;
    this.showAlertEvent.next(true);
  }

  public showAsyncAlert(alert: Omit<Omit<IAlert, 'onConfirm'>, 'onCancel'>) {
    return new Promise<boolean>((resolve, reject) => {
      this.alertData = {
        ...alert,
        onConfirm: () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        }
      }
      this.showAlertEvent.next(true);
    });
  }

  public dismissAlert() {
    this.alertData = null;
    this.showAlertEvent.next(false);
  }

  public getAlertStatus() {
    return this.isAlertVisible;
  }

  ngOnInit() {
    this.showAlertEvent.subscribe(val => this.isAlertVisible = val);
  }
  ngOnDestroy() {
    this.showAlertEvent.complete();
  }
}

<div class="sidemenu-container" [class.hidden]="sharedService.isFullScreen === true" [class.dark-mode]="sharedService.isUsingDarkTheme">
  <div class="sidemenu-panel panel"> 
  
    
    <div class="user-info">
      <img [src]="sharedService.isUsingDarkTheme ? '../../../assets/images/logo-large-dark.png' : '../../../assets/images/logo-large.png'" alt="ING logo">
      <div class="user">
        <span>{{ userService.email }}</span>
        <span>{{ getTribes() }}</span>
      </div>
      <button class="logout-button" mat-flat-button color="primary" (click)="goToLogin()"><b>Logout</b></button>

      <button class="dark-mode-toggle" mat-icon-button (click)="onThemeChange()">
        <img [src]="sharedService.isUsingDarkTheme ? '/assets/icons/dark-mode-toggle.svg' : '/assets/icons/light-mode-toggle.svg'" alt="toggle mode">
      </button>
    </div>
  
    <div class="routes-container">
      <div *ngFor="let route of routes" class="route" [class.hasSubroutes]="route.hasSubroutes" [class.selected]="isRouteSelected(route)" (click)="!route.hasSubroutes ? navigate(route) : null">
        <div class="title-row">
          <img src="../../../assets/icons/{{route.icon}}.svg" alt="{{ getTitle(route.name) }}" />
          <span [class.selected]="selectedRouteUrl === route.path">{{ getTitle(route.name) }}</span>
        </div>
        <div *ngFor="let subroute of route.subroutes; let isLast=last" class="subroute" [class.last]="isLast" [class.selected]="selectedRouteUrl === subroute.path" (click)="navigate(subroute)">
          <span class="circle"></span>
          {{ getTitle(subroute.name) }}
        </div>
      </div>
    </div>
  
    <div class="lang-selector">
      <img *ngFor="let lang of internationalizationService.LANGS" src="../../../assets/icons/flags/{{ lang.value }}.svg" [class.selected]="selectedLang == lang.value" [alt]="lang.viewValue" (click)="internationalizationService.useLang(lang.value)">
    </div>
  </div>
</div>
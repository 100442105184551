import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IActionEvent, IBottomAction } from 'src/app/components';
import { IError } from 'src/app/models/error';
import { ERequestStatus, IRequest } from 'src/app/models/request';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiService } from 'src/app/services/api/api.service';
import { InternationalizationService } from 'src/app/services/internationalization/internationalization.service';

@Component({
  selector: 'app-request-summary',
  templateUrl: './request-summary.component.html',
  styleUrl: './request-summary.component.scss'
})
export class RequestSummaryComponent implements OnInit {

  isLoading = true;
  isCondensed = false;
  //@ts-ignore
  request: IRequest = {};

  @Input() requestId: number | null = null;

  @ViewChild("editNotesModalTemplate") editNotesModalTemplate: TemplateRef<any>;

  contextActions: IBottomAction[] = [];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private translateService: TranslateService,
    private alertService: AlertService,
    public internationalizationService: InternationalizationService,
  ) { }

  ngOnInit(): void {
    // check if component is not used as a route
    if (this.route.component !== this.constructor) {
      this.isCondensed = true;
    }

    const id = this.requestId || this.route.snapshot.paramMap.get('id');
    if (id) 
      this.loadData(Number(id));
    else {
      this.location.back();
    }
  }

  loadData(id: number) {
    this.isLoading = true;
    this.api.getRequest(id).subscribe({
      next: (request) => {
        this.request = request;

        if (this.request.status === ERequestStatus['In progress'])
          this.contextActions = [
            { key: 'delete',  name: "requests.actions.cancelRequest", side: 'left', style: 'warn' },
            { key: 'project',  name: "requests.actions.createProject", side: 'right', style: 'primary' },
          ];

        this.isLoading = false;
      },
      error: () => {
        this.location.back();
      }
    });
  }

  getSuppliersList(listType: typeof this.request['suppliers'][number]['type'], filter?: string) {
    if (!this.request || !this.request.suppliers) return [];
    
    const s = this.request.suppliers.find(s => s.type === listType);
    if (!s) return [];

    if (filter && filter.length > 3) {
      return s.suppliers.filter(s => s.name.toLowerCase().includes(filter.toLowerCase()));
    }
    return s ? s.suppliers : [];
  }

  getSelectedSuppliers(listType: typeof this.request['suppliers'][number]['type']) {
    return this.getSuppliersList(listType).filter(s => s.order !== null).sort((a, b) => a.order! - b.order!);
  }
  
  getSpecificSuppliers() {
    return this.request.suppliers.reduce((p,c) => [ ...p, ...c.suppliers], []).filter(s => s.order !== null);
  }

  
  onTitleClick() {
    this.router.navigate(['/projectRequests/requests']);
  }


  textAreaValue = "";
  editNotes() {
    this.textAreaValue = this.request.notes;
    this.alertService.showAlert({
      templateRef: this.editNotesModalTemplate,
      templateContext: { notes: this.request.notes, requestId: this.request.id },
    })
  }
  editNotesAction(requestId: number, confirmEdit: boolean) {
    if (confirmEdit) {
      this.api.editRequestNote(requestId, this.textAreaValue).subscribe(() => {
        this.alertService.dismissAlert(); 
        this.request.notes = this.textAreaValue;
      });
    } else {
      this.alertService.dismissAlert(); 
    }
  }

  onActionClicked(action: IActionEvent) {
    switch (action.actionKey) {
      case 'delete':
        this.alertService.showAlert({
          type: "warning",
          acceptText: "common.labels.yes",
          cancelText: "common.labels.no",
          message: this.translateService.instant('requests.alerts.cancelRequest', { request: this.request.title }),
          onConfirm: () => {
            this.alertService.dismissAlert();
            this.api.cancelRequest(this.request.id).subscribe(() => {
              this.loadData(this.request.id);
            });
          },
        });
        break;
      case 'project':
        this.router.navigate(['/projectsAndSuppliers/project-wizard/', this.request.id ]);
    }
  }
}

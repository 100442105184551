import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatepickerHeaderComponent, IActionEvent, IBottomAction } from 'src/app/components';
import { ICustomAlertModel } from 'src/app/models/alert';
import { IError } from 'src/app/models/error';
import { IListItem } from 'src/app/models/listItem';
import { ERequestStatus, IRequest } from 'src/app/models/request';
import { IPreferredSuppliers, ISupplier } from 'src/app/models/supplier';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiService } from 'src/app/services/api/api.service';
import { InternationalizationService } from 'src/app/services/internationalization/internationalization.service';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-project-wizard',
  templateUrl: './project-wizard.component.html',
  styleUrl: './project-wizard.component.scss'
})
export class ProjectWizardComponent implements OnInit {

  steps = [{
    title: 'project-wizard.step1.title'
  }, {
    title: 'project-wizard.step2.title'
  }, {
    title: 'project-wizard.step3.title'
  }, {
    title: 'project-wizard.step4.title'
  }]
  width = 0;
  leftPosition = 0;
  selectedIndex = 0;

  isRequestPanelLoading = false;
  isPageLoading = false;

  datepickerHeaderComponent = DatepickerHeaderComponent;

  contextActions: IBottomAction[] = [
    { key: 'prev',  name: "common.actions.prev", side: 'right', style: 'accent', disabled: true },
    { key: 'next',  name: "common.actions.next", side: 'right', style: 'primary' },
    { key: 'cancel',  name: "common.actions.cancel", side: 'left', style: 'warn' },
  ];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private sharedService: SharedService,
    private translate: TranslateService,
    private router: Router,
    private api: ApiService,
    private alert: AlertService,
    public internationalizationService: InternationalizationService,
  ) { }

  ngOnInit() {
    this.changePage(0);

    this.api.getServices().subscribe(services => {
      this.catalogs = services;
    });
 
    this.api.getJobsDescription().subscribe(FTERole => {
      this.FTERoles = FTERole;
    });

    this.api.getTPOList().subscribe(tpo => {
      this.tpoList = tpo;
    });

    this.api.getContractTypes().subscribe(ct => {
      this.contracts = ct;
    });

    this.api.getContractManagers().subscribe(cm => {
      this.contractManagers = cm;
    });

    this.api.getContractOwners().subscribe(co => {
      this.contractOwners = co;
    });
    
    this.api.getServiceTypes().subscribe(st => {
      this.serviceTypes = st;
    });

    this.api.getSuggestedSuppliers().subscribe(suppliers => {
      this.activeSuppliersList = suppliers.reduce((p, c) => {
        p.push(...c.suppliers.map(s => ({ ...s, type: c.type })));
        return p;
      }, []).sort((a, b) => a.rating - b.rating);
    });

    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.api.getRequest(Number(id)).subscribe({
        next: (request) => {
          this.step1Form.controls.request.markAsTouched();
          this.step1Form.controls.request.setValue(request);
          this.requestSearchbox = request.title;
          this.changePage(0);
          this.setStep(1);
        },
        error: () => {
          this.location.back();
        }
      })
    }
  }

  onActionClicked(action: IActionEvent) {
    switch (action.actionKey) {
      case 'cancel':
        this.location.back();
        break;
      case 'prev':
        this.setStep(this.selectedIndex - 1);
        break;
      case 'next':
        this.setStep(this.selectedIndex + 1);
        break;
    }
  }

  setStep(step: number) {
    if (this.canSetStep(step)) {
      if (step === this.steps.length) {
        this.submitForm();
        return;
      }

      this.selectedIndex = step;
      this.onStepChanged();
    }
  }

  canSetStep(step: number): boolean {
    switch (step) {
      case 0:
        return true;
      case 4: 
        if (this.canSetStep(3)) {
          this.submitForm();
        }
        return false;
      case 3: 
        if (!this.step3Form.valid) {
          this.setStep(2);
          this.isSuppliersDrawerOpen = true;
          this.step3Form.markAllAsTouched();
          return false;
        }
        return this.canSetStep(2);
      case 2:
        if (!this.step2Form.valid) {
          this.setStep(1);
          this.step2Form.markAllAsTouched();
          this.sharedService.focusOnInvalidInput(this.step2Form)
          return false;
        }
        return this.canSetStep(1);
      case 1:
        if (!this.step1Form.valid) {
          this.setStep(0);
          this.step1Form.markAllAsTouched();
          return false;
        }
        return true;
    }
    return false;
  }

  onStepChanged() {
    switch (this.selectedIndex) {
      case 0:
        this.contextActions[0].disabled = true;
        this.contextActions[1].name = 'common.actions.next';
        break;
      case 1:
        this.contextActions[0].disabled = false;
        this.contextActions[1].name = 'common.actions.next';
        
        if (this.step1Form.controls.request.touched) {
          const r = this.step1Form.controls.request.value;

          if (this.step2Form.controls.title.untouched) {
            this.step2Form.controls.title.setValue(r.title);
            this.step2Form.controls.title.markAsTouched();
          }

          if (this.step2Form.controls.technologyId.untouched) {
            this.step2Form.controls.technologyId.setValue(r.catalogId);
            this.step2Form.controls.technologyId.markAsTouched();
          }

          if (this.step2Form.controls.fteRoleId.untouched) {
            this.step2Form.controls.fteRoleId.setValue(r.jobId);
            this.step2Form.controls.fteRoleId.markAsTouched();
          }

          if (this.step2Form.controls.startDate.untouched) {
            this.step2Form.controls.startDate.setValue(new Date(r.startDate));
            this.step2Form.controls.startDate.markAsTouched();
          }

          if (this.step2Form.controls.endDate.untouched) {
            this.step2Form.controls.endDate.setValue(new Date(r.endDate));
            this.step2Form.controls.endDate.markAsTouched();
          }
        }

        break;
      case 2:
        this.contextActions[0].disabled = false;
        this.contextActions[1].name = 'common.actions.next';
        
        if (this.step2Form.controls.technologyId.touched 
          || this.step2Form.controls.fteRoleId.touched
          || this.step2Form.controls.contractId.touched
        ) {
          const contractTypeId = this.step2Form.controls.contractId.value;
          if (this.requestInfo.serviceType === 'Specific') {
            this.preferredSuppliers = (JSON.parse(JSON.stringify(this.requestInfo.suppliers)) as typeof this.requestInfo.suppliers).reduce((p, c) => {
              c.suppliers = c.suppliers.filter(s => s.order === 0 && s.contracts && s.contracts.some(c => c.id === contractTypeId));
              if (c.suppliers.length > 0) p.push(c);
              return p;
            }, []);
          } else {
            this.preferredSuppliers = (JSON.parse(JSON.stringify(this.requestInfo.suppliers)) as typeof this.requestInfo.suppliers).reduce((p, c) => {
              c.suppliers = c.suppliers.filter(s => s.contracts && s.contracts.some(c => c.id === contractTypeId));
              if (c.suppliers.length > 0) p.push(c);
              return p;
            }, []);
          }
        }

        if (this.preferredSuppliers.length === 0) {
          this.alert.showAlert({ 
            type: 'error',
            title: 'common.labels.error',
            message: 'project-wizard.errors.noSuppliersFound',
            acceptText: 'Ok',
            noCancel: true,
            onConfirm: () => {
              this.alert.dismissAlert();
            },
          });
        }

        break;  
      case 3:
        this.contextActions[0].disabled = false;
        this.contextActions[1].name = 'common.actions.submit';
        break;
    }
  }

  //#region Step 1
  requestSearchbox = "";
  dataSource = new MatTableDataSource<IRequest>([]);

  pageData = {
    index: 0,
    size: 5,
    totalElements: 0,
    sort: null as Sort | null,
  }
  displayedColumns = [
    "actions",
    "title",
    "tribe",
    "service",
    "startDate",
    "endDate",
  ]
  step1Form = this.fb.group({
    request: [null as IRequest, Validators.required],
  });

  isRequestSelected(element: IRequest) {
    return this.requestInfo && this.requestInfo.id === element.id
  }
  setSelectedRequest(request: IRequest) {
    this.step1Form.controls.request.setValue(request);
    this.step1Form.controls.request.markAsTouched();
  }

  changePage(pageNumber?: number, pageSize?: number, sort?: Sort) {

    this.isRequestPanelLoading = true;

    if (pageSize != null) this.pageData.size = pageSize;
    if (pageNumber != null) this.pageData.index = pageNumber;
    if (sort) this.pageData.sort = sort;

    this.isRequestPanelLoading = true;
    this.api.getPagedRequests({
      status: ERequestStatus['In progress'],
      title: this.requestSearchbox,
    }, this.pageData.index, this.pageData.size, this.pageData.sort || { active: 'title', direction: "asc" }).subscribe((requests) => {
      if (this.requestSearchbox.trim() !== '' && this.pageData.index !== 0 && requests.content.length === 0) {
        this.changePage(0); 
        return;
      }
      this.isRequestPanelLoading = false;
      this.pageData.totalElements = requests.totalElements;
      this.dataSource.data = requests.content;
    });
  }

  //#endregion

  //#region Step 2
  step2Form = this.fb.group({
    title: ['', [Validators.required, Validators.maxLength(255)]],
    tpoId: [ null as number, Validators.required],
    externalFTE: [ 0 as number, [Validators.required, Validators.min(0)]],
    fteRoleId: [ null as number, Validators.required],
    technologyId: [ null as number, Validators.required],
    contractOwnerId: [ null as number, Validators.required],
    contractManagerId: [ null as number, Validators.required],
    startDate: [ null as Date, Validators.required],
    endDate: [ null as Date, Validators.required],
    dueDate: [ null as Date, Validators.required],
    contractId: [null as number, Validators.required],
    miscellous: [ null as string, [ Validators.maxLength(1000) ]],
  });

  catalogs: IListItem[] = [];
  FTERoles: IListItem[] = [];
  contracts: IListItem[] = [];
  serviceTypes: IListItem[] = [];
  tpoList: IListItem[] = [];
  contractManagers: IListItem[] = [];
  contractOwners: IListItem[] = [];

  beErrors: { [key: string]: string } = {};
  getFieldError(controlName: (keyof typeof this.step2Form.controls)): string {
    const formControl = this.step2Form.controls[controlName];

    if (formControl.hasError('required')) {
      return 'common.errors.requiredField';
    }

    if (formControl.hasError('invalidNumber')) {
      return 'common.errors.invalidNumber';
    }
    
    if (formControl.hasError('maxlength')) {
      return 'common.errors.maxlength';
    }

    if (this.beErrors[controlName]) {
      return 'request-wizard.errors.' + controlName + '.' + this.beErrors[controlName];
    }

    if (formControl.errors) {
      return 'request-wizard.errors.' + Object.keys(formControl.errors)[0];
    }

    return ''
  }

  onRateKeyDown(event: KeyboardEvent) {
    if (event.key.match(/^[eE,+-]$/g)) {
      event.preventDefault();
    }
  }

  //#endregion

  //#region Step 3
  step3Form = this.fb.group({
    supplier: [ null as ISupplier, Validators.required],
  });

  isSuppliersDrawerOpen = true;

  // this is the list of all the avaible suppliers at the moment of the request creation filtered by the contract type
  preferredSuppliers: IPreferredSuppliers[] = [];

  // this is the list of all active suppliers
  activeSuppliersList: ISupplier[] = [];

  isSupplierSelected(supplier: ISupplier) {
    return this.step3Form.controls.supplier.value?.id === supplier.id;
  }

  selectedSupplierChanged(supplier: ISupplier) { 
    this.step3Form.controls.supplier.setValue(supplier);
  }

  getSpecificSupplier() {
    if (!this.requestInfo || !this.preferredSuppliers || this.preferredSuppliers.length === 0) return [];
    return this.preferredSuppliers.reduce((p,c) => { return [...p, ...c.suppliers] }, [] as IPreferredSuppliers["suppliers"]).filter(s => s.order === 0);
  }

  /**
   * returns the list of suppliers at the moment of the request creation for the given listType
   * @param listType if null returns all the suppliers
   * @param filter 
   * @returns 
   */
  getRequestSuppliersList(listType?: IPreferredSuppliers['type'], filter?: string) {
    if (!this.requestInfo || !this.preferredSuppliers) return [];

    const s = this.preferredSuppliers.find(s => s.type === listType);
    if (!s) return [];

    if (filter && filter.length > 3) {
      s.suppliers = s.suppliers.filter(s => s.name.toLowerCase().includes(filter.toLowerCase()));
    }
    return s.suppliers.sort((a, b) => a.order - b.order);
  }

  //#endregion

  //#region Step 4

  @ViewChild("requestSummary") requestSummary: TemplateRef<any>;
  @ViewChild("supplierSummary") supplierSummary: TemplateRef<any>;

  get requestInfo() {
    return this.step1Form.controls.request.value;
  }
  get supplierInfo() {
    return this.step3Form.controls.supplier.value;
  }

  getTPOName(id: number) {
    return this.tpoList.find(t => t.id === id)?.value;
  }
  getContractManagerName(id: number) {
    return this.contractManagers.find(t => t.id === id)?.value;
  }
  getContractOwnerName(id: number) {
    return this.contractOwners.find(t => t.id === id)?.value;
  }
  getFTERoleName(id: number) {
    return this.FTERoles.find(t => t.id === id)?.value;
  }
  getCatalogName(id: number) {
    return this.catalogs.find(t => t.id === id)?.value;
  }
  getContract(id: number) {
    return this.contracts.find(t => t.id === id)?.value;
  }

  isRequestSummaryVisible = false;
  isSupplierSummaryVisible = false;
  openRequestDetails() {
    this.isRequestSummaryVisible = true;
    this.alert.showAlert({
      size: 'large',
      templateRef: this.requestSummary,
      buttons: [{ 
        text: "common.actions.close",
        background: "",
        color: "",
        onClick: () => {
          this.alert.dismissAlert();
          this.isRequestSummaryVisible = false;
      }}]  
    })
  }
  openSupplierDetails() {
    this.isSupplierSummaryVisible = true;
    this.alert.showAlert({
      size: 'large',
      templateRef: this.supplierSummary,
      buttons: [{ 
        text: "common.actions.close",
        background: "",
        color: "",
        onClick: () => {
          this.alert.dismissAlert();
          this.isSupplierSummaryVisible = false;
      }}]  
    })
  }
  //#endregion

  //#region submit 
  submitForm() {
    this.isPageLoading = true;
    const project = this.step2Form.value;
    const request = this.step1Form.controls.request.value;
    const supplier = this.step3Form.controls.supplier.value;

    this.api.editProject({
      requestId: request.id,
      supplierId: supplier.id,
      title: project.title,
      tribeId: request.tribeId,
      tpoId: project.tpoId,
      externalFTE: project.externalFTE,
      contractId: project.contractId,
      serviceTypeId: request.serviceTypeId,
      fteRoleId: project.fteRoleId,
      technologyId: project.technologyId,
      contractOwnerId: project.contractOwnerId,
      contractManagerId: project.contractManagerId,
      startDate: this.internationalizationService.ignoreDateTimezone( project.startDate ).toISOString(),
      endDate: this.internationalizationService.ignoreDateTimezone( project.endDate ).toISOString(),
      dueDate: this.internationalizationService.ignoreDateTimezone( project.dueDate ).toISOString(),
      miscellous: project.miscellous,
    }).subscribe({
      next: (proj) => {
        this.isPageLoading = false;

        this.step1Form.markAsUntouched();
        this.step2Form.markAsUntouched();
        this.step3Form.markAsUntouched();
  
        this.router.navigate(['/projectsAndSuppliers/project-panel/', proj.id]);
      },
      error: (err: IError) => {
        this.alert.showAlert({
        type: 'error',
        title: 'common.labels.error',
        message: err.messages.map(e => e.field + "_" + e.key).join(', '),
        acceptText: 'Ok',
        noCancel: true,
        onConfirm: () => {
          this.alert.dismissAlert();
        },
      });

      this.isPageLoading = false;
      }
    })
  }
  //#endregion

  get hasUnsavedChanges() {
    return this.step1Form.touched || this.step2Form.touched; 
  }

  onNavigationOut() {
    if (this.hasUnsavedChanges) {
      return this.sharedService.showBackNavigationAlertAsync();
    }
    return true;
  }


}

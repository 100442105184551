import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IEditSupplier, IPreferredSuppliers, IPreferredSuppliersResponse, ISupplier } from 'src/app/models/supplier';
import { ERole, IBusinessLine, IEditUser, IUser } from 'src/app/models/user';
import { Paged } from 'src/app/models/paged';
import { ICodeListItem, IGraphItem, IListItem } from 'src/app/models/listItem';
import { IRatingHistory } from 'src/app/models/ratingHistory';
import { EDocumentType, EProjectStatus, IEditProject, IProject, IProjectCondensed, IProjectDocInfo } from 'src/app/models/project';
import { ERequestStatus, IEditRequest, IRequest } from 'src/app/models/request';
import { IDocument } from 'src/app/models/document';
import { IRatingGraphData, IRatingTableItem, IRatingValue } from 'src/app/models/rating';
import { ISODateString } from 'src/app/types/ISODateString';
import { IProjectKpi, IRequestKpi, IScorecardKpi } from 'src/app/models/kpi';
import { IFile } from 'src/app/models/file';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  private apiUrl = environment.apiURL;

  // s -----------------------------

  getPagedUsers(
    filters: {
      email?: string;
      businessLine?: string;
      tribe?: string;
      status?: boolean;
    },
    pageIndex: number,
    pageSize: number,
    sort?: Sort
  ) {
    let params = new HttpParams()
      .set('page', pageIndex.toString())
      .set('size', pageSize.toString());

    if (sort) {
      params = params
        .set('orderBy', sort.active)
        .set('orderType', sort.direction);
    }

    return this.http.post<Paged<IUser>>(`${this.apiUrl}/pagedUsers`, filters, {
      params,
    });
  }

  getUser(id: number): Observable<IUser> {
    return this.http.get<IUser>(`${this.apiUrl}/getUser/${id}`);
  }

  editUser(user: IEditUser): Observable<IUser> {
    return this.http.post<IUser>(`${this.apiUrl}/user`, user);
  }

  getBusinessLines(): Observable<IBusinessLine[]> {
    return this.http.get<IBusinessLine[]>(`${this.apiUrl}/getTribes`);
  }

  // Supplier -----------------------------

  getPagedSuppliers(filters: {
    name?: string,
    email?: string,
    address?: string,
    countryCode?: string,
    timeZoneId?: number,
    categories?: number[],
    isActive?: boolean,
    flagCsm?: boolean,
    contracts?: number[],
    minRating?: number,
    maxRating?: number
  }, pageIndex: number, pageSize: number, sort?: Sort) {
    let params = new HttpParams()
      .set('page', pageIndex.toString())
      .set('size', pageSize.toString());

    if (sort) {
      params = params
        .set('orderBy', sort.active)
        .set('orderType', sort.direction);
    }

    return this.http.post<Paged<ISupplier>>(
      `${this.apiUrl}/pagedSuppliers`,
      filters,
      {
        params,
      }
    );
  }
  exportSuppliers(filters: Parameters<typeof this.getPagedSuppliers>[0], sort?: Sort, totalElements: number = 0) {
    let params = new HttpParams()
      .set('page', '0')
      .set('size', totalElements.toString());
    
    if (sort) {
      params = params
        .set('orderBy', sort.active)
        .set('orderType', sort.direction);
    }

    return this.http.post<IFile>(
      `${this.apiUrl}/exportSuppliers`,
      filters, {
        params
      }
    );
  }

  getSupplier(id: number): Observable<ISupplier> {
    return this.http.get<ISupplier>(`${this.apiUrl}/getSupplier/${id}`);
  }
  
  getSupplierDetails(id: number) {
    return this.http.post<IPreferredSuppliersResponse>(`${this.apiUrl}/supplier/projectDetails?supplierId=${id}`, {});
  }

  editSupplier(supplier: IEditSupplier): Observable<ISupplier> {
    return this.http.post<ISupplier>(`${this.apiUrl}/supplier`, supplier);
  }

  getCountries() {
    return this.http.get<ICodeListItem[]>(`${this.apiUrl}/getCountries`);
  }
  getTimeZones() {
    return this.http.get<IListItem[]>(`${this.apiUrl}/getTimeZones`);
  }
  getSupplierCategories() {
    return this.http.get<IListItem[]>(`${this.apiUrl}/getSupplierCategories`);
  }
  getContractTypes() {
    return this.http.get<IListItem[]>(`${this.apiUrl}/getContractTypes`);
  }
  getServices() {
    return this.http.get<IListItem[]>(`${this.apiUrl}/getServiceCatalogs`);
  }
  getJobsDescription() {
    return this.http.get<IListItem[]>(`${this.apiUrl}/getJobsDescription`);
  }

  // Dashboards ----------------------------

  getTotalTechSupplier() {
    return this.http.get<IGraphItem[]>(`${this.apiUrl}/getTotalTechSupplier`);
  }
  getTopSupplier() {
    return this.http.get<(IGraphItem & { type: string })[]>(`${this.apiUrl}/getTopSupplier`);
  }
  getOutsourcingTechSupplier() {
    return this.http.get<IGraphItem[]>(
      `${this.apiUrl}/getOutsourcingTechSupplier`
    );
  }
  getHubsSupplier() {
    return this.http.get<IGraphItem[]>(
      `${this.apiUrl}/getHubsSupplier/15`
    );
  }
  getLocalSupplier() {
    return this.http.get<IGraphItem[]>(
      `${this.apiUrl}/getLocalSupplier/15`
    );
  }

  // requests -----------------------------

  getPagedRequests(filters: {
    title?: string;
    status?: ERequestStatus;
    servicesCatalog?: Array<{
      catalogId: number;
      jobs: number[];
    }>;
    startDate?: string;
    endDate?: string;
    seniorityId?: number;
    projectTypeId?: number;
    userId?: number;
    businessLineId?: number;
    tribeId?: number;
    supplierCategoryId?: number;
  }, pageIndex: number, pageSize: number, sort?: Sort) {
    let params = new HttpParams()
      .set('page', pageIndex.toString())
      .set('size', pageSize.toString());

    if (sort) {
      params = params
        .set('orderBy', sort.active)
        .set('orderType', sort.direction);
    }

    return this.http.post<Paged<IRequest>>(
      `${this.apiUrl}/pagedRequests`,
      filters,
      {
        params,
      }
    );
  }
  exportRequests(filters: Parameters<typeof this.getPagedRequests>[0], sort?: Sort, totalElements: number = 0) {
    let params = new HttpParams()
      .set('page', '0')
      .set('size', totalElements.toString());
    
    if (sort) {
      params = params
        .set('orderBy', sort.active)
        .set('orderType', sort.direction);
    }

    return this.http.post<IFile>(
      `${this.apiUrl}/exportRequests`,
      filters,
      {
        params
      }
    );
  }

  getRequest(id: number) {
    return this.http.get<IRequest>(`${this.apiUrl}/getRequest/${id}`);
  }

  editRequest(request: IEditRequest) {
    return this.http.post<IRequest>(`${this.apiUrl}/request`, request);
  }

  editRequestNote(requestId: number, notes: string) {
    return this.http.post<boolean>(`${this.apiUrl}/request/insertNotes`, {
      id: requestId,
      notes,
    });
  }
  cancelRequest(requestId: number) {
    let params = new HttpParams()
      .set('id', requestId.toString())

    return this.http.post<boolean>(`${this.apiUrl}/request/cancel`, {}, {
      params,
    });
  }

  // -----------------------------

  getSupplierRating(supplierId: number) {
    return this.http.get<IRatingHistory[]>(
      `${this.apiUrl}/supplier/getRatingDetails/${supplierId}`
    );
  }

  getSupplierProjects(supplierId: number, flagMissingDocuments: boolean) {
    return this.http.post<{ projects: IProjectCondensed[], businessLines: IBusinessLine[] }>(
      `${this.apiUrl}/supplier/projectDetails`,
      {
        supplierId: supplierId,
        flagMissingDocuments: flagMissingDocuments,
      }
    );
  }

  getServiceTypes() {
    return this.http.get<IListItem[]>(
      `${this.apiUrl}/getServiceTypes`
    );
  }

  getSeniorities() {
    return this.http.get<IListItem[]>(
      `${this.apiUrl}/getSeniorities`
    );
  }

  getProjectTypes() {
    return this.http.get<IListItem[]>(
      `${this.apiUrl}/getProjectTypes`
    );
  }

  getSuggestedSuppliers(catalogId?: number, jobId?: number) {
    return this.http.post<IPreferredSuppliers[]>(
      `${this.apiUrl}/request/suggestedSuppliers`,
      {
        catalogId,
        jobId,
      }
    );
  }

  // projects -----------------------------

  getPagedProjects(filters: {
    title?: string;
    status?: EProjectStatus;
    servicesCatalog?: Array<{
      catalogId: number;
      jobs: number[];
    }>;
    startDate?: string;
    endDate?: string;
    seniorityId?: number;
    projectTypeId?: number;
    userId?: number;
    businessLineId?: number;
    tribeId?: number;
    supplierId?: number;
    supplierCategoryId?: number;
  }, pageIndex: number, pageSize: number, sort?: Sort) {
    let params = new HttpParams()
      .set('page', pageIndex.toString())
      .set('size', pageSize.toString());

    if (sort) {
      params = params
        .set('orderBy', sort.active)
        .set('orderType', sort.direction);
    }

    return this.http.post<Paged<IProject>>(
      `${this.apiUrl}/pagedProjects`,
      filters,
      {
        params,
      }
    );
  }
  exportProjects(filters: Parameters<typeof this.getPagedProjects>[0], sort?: Sort, totalElements: number = 0) {
    let params = new HttpParams()
      .set('page', '0')
      .set('size', totalElements.toString());
    
    if (sort) {
      params = params
        .set('orderBy', sort.active)
        .set('orderType', sort.direction);
    }

    return this.http.post<IFile>(
      `${this.apiUrl}/exportProjects`,
      filters,
      {
        params
      }
    );
  }
  getProject(id: number) {
    return this.http.get<IProject>(`${this.apiUrl}/getProject/${id}`);
  }
  editProject(project: IEditProject) {
    return this.http.post<IProject>(`${this.apiUrl}/project`, project);
  }
  
  getTPOList() {
    return this.http.get<IListItem[]>(
      `${this.apiUrl}/getTpo`
    );
  }

  closeProject(projectId: number) {
    return this.http.post<boolean>(
      `${this.apiUrl}/project/close?id=${projectId}`,
      {}
    );
  }
  getContractOwners() {
    return this.http.get<IListItem[]>(
      `${this.apiUrl}/getContractOwners`
    );
  }
  getContractManagers() {
    return this.http.get<IListItem[]>(
      `${this.apiUrl}/getContractManagers`
    );
  }

  addDocument(document: IDocument & { projectId: number }) {
    return this.http.post<IDocument[]>(
      `${this.apiUrl}/project/document`,
      document
    );
  }
  removeDocument(projectId: number, documentId: number) {
    let params = new HttpParams()
      .set('projectId', projectId.toString())
      .set('documentId', documentId.toString());

    return this.http.post<IDocument[]>(
      `${this.apiUrl}/project/deleteDocument`,
      {},
      {
        params,
      }
    );
  }
  getDocumentTypes() {
    return of([
      { id: EDocumentType['Decision Tree'], value: 'Decision Tree' },
      { id: EDocumentType.GDPR, value: 'GDPR' },
      { id: EDocumentType.ITSR, value: 'ITSR' },
      { id: EDocumentType.ODS, value: 'ODS' },
      { id: EDocumentType.Other, value: 'Other' },
    ]);
  }
  setFlagDocumentsComplete(projectId: number, value: boolean) {
    return this.http.post<boolean>(
      `${this.apiUrl}/project/updateFlagDocumentsComplete?projectId=${ projectId }&flagDocumentsComplete=${ value }`,
      {}
    );
  }


  addRating(projectId: number, scorecard: IRatingValue[], year: number, quarter: number) {
    return this.http.post<boolean>(
      `${this.apiUrl}/project/scorecard?projectId=${ projectId }&year=${ year }&quarter=${ quarter }`,
      scorecard
    );
  }
  removeRating(projectId: number, scorecardId: number) {
    return this.http.post<boolean>(
      `${this.apiUrl}/project/deleteScorecard?projectId=${ projectId }&scorecardId=${ scorecardId }`,
      {}
    );
  }
  getRatingDetail(projectId: number, scorecardId: number) {
    return this.http.get<IRatingValue[]>(
      `${this.apiUrl}/project/getScorecard/${ scorecardId }`);
  }

  getPagedProjectDocuments(filters: { projectId: number, name?: string, url?: string, type?: EDocumentType }, pageIndex: number, pageSize: number, sort?: Sort) {
    let params = new HttpParams()
      .set('page', pageIndex.toString())
      .set('size', pageSize.toString());

    if (sort) {
      params = params
        .set('orderBy', sort.active)
        .set('orderType', sort.direction);
    }

    return this.http.post<Paged<IDocument>>(
      `${this.apiUrl}/project/pagedDocuments`,
      filters,
      {
        params,
      }
    );
  }

  getProjectRatingGraphData(projectId: number) {
    return this.http.get<IRatingGraphData[]>(
      `${this.apiUrl}/project/getRatingDetails/${projectId}`
    );
  }

  getPagedProjectRatings(filters: { projectId: number, year?: number, quarter?: number, insertDateFrom?: ISODateString, insertDateTo?: ISODateString }, pageIndex: number, pageSize: number, sort?: Sort) {
    let params = new HttpParams()
      .set('page', pageIndex.toString())
      .set('size', pageSize.toString());

    if (sort) {
      params = params
        .set('orderBy', sort.active)
        .set('orderType', sort.direction);
    }

    return this.http.post<Paged<IRatingTableItem>>(
      `${this.apiUrl}/project/pagedScorecard`,
      filters,
      {
        params,
      }
    );
  }

  requestsKpi() {
    return this.http.get<IRequestKpi>(
      `${this.apiUrl}/home/alertsAndKpi/requests`
    );
  }
  projectsKpi() {
    return this.http.get<IProjectKpi>(
      `${this.apiUrl}/home/alertsAndKpi/projects`
    );
  }
  scorecardKpi() {
    return this.http.get<IScorecardKpi>(
      `${this.apiUrl}/home/alertsAndKpi/scorecard`
    );
  }
  getPagedProjectsDocumentsStatus(pageIndex: number, pageSize: number, sort?: Sort) {
    let params = new HttpParams()
    .set('page', pageIndex.toString())
    .set('size', pageSize.toString());

    if (sort) {
      params = params
        .set('orderBy', sort.active)
        .set('orderType', sort.direction);
    }

    return this.http.post<Paged<IProjectDocInfo>>(
      `${this.apiUrl}/home/documentsCheckList`,
      {},
      {
        params,
      }
    );
  }
  

}

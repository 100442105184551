<app-page-container
  [pageTitleParts]="[ 'procedures.title', (procedures && procedures[procedureIndex].title) || '' ]"
  [actions]="contextActions"
  [bottomActions]="bottomActions"
  (actionClicked)="onActionClicked($event)"
>
  <app-panel legend class="legend" [class.sidebar-closed]="isFullscreen" [open]="showIndex" openDirection="horizontal">
    @for (procedure of procedures; track index; let index = $index) {
      <div class="preview" [class.selected]="procedureIndex === index" (click)="setIndex(index)">
        <h4>{{ procedure.title }}</h4>
      </div>
    }
  </app-panel>  
  <app-procedure-view [procedure]="procedures[procedureIndex]"></app-procedure-view>
</app-page-container>
<div class="slides-count">
  {{ procedureIndex + 1 }} / {{ procedures.length }}
</div>
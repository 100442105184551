<div class="alert" *ngIf="showAlert || isAnimating" [class.custom-alert]="customAlertData.templateRef != null">
    <div class="card" [@toggleAlert]="alertStatus" (@toggleAlert.start)="animationStart()" (@toggleAlert.done)="animationEnd()" [attr.size]="customAlertData && customAlertData.size">
        <div class="alert-container">
            <ng-container *ngTemplateOutlet="customAlertData.templateRef ? customTemplate : defaultTemplate"></ng-container>  
        </div>
    </div>
</div>


<ng-template #defaultTemplate>
    <div class="alert-icon">
        <div class="icon big" [class]="data.type"></div>
    </div>
    <div class="alert-title" *ngIf="data.title">
        <h2>{{ data.title | translate }}</h2>
    </div>
    <div class="alert-message" *ngIf="data.message">
        <p>
            {{ data.message | translate }}
        </p>
    </div>
    <div class="alert-buttons-container">
        <button mat-flat-button color="accent" *ngIf="!data.noCancel" class="button btn" (click)="data.onCancel ? data.onCancel() : this.closeAlert()">
            {{ (data.cancelText ||  'common.actions.cancel') | translate}}
        </button>

        <button mat-flat-button color="primary" class="button btn btn-warning btn-save" [class.btn-green]="data.type == 'success'" [class.btn-red]="data.type =='error'" (click)="data.onConfirm()" >
            {{ data.acceptText  | translate }}
        </button>
    </div>
</ng-template>

<ng-template #customTemplate>
    <ng-container *ngTemplateOutlet="customAlertData.templateRef; context: customAlertData.templateContext"></ng-container>  
    <div class="alert-buttons-container" *ngIf="customAlertData.buttons && customAlertData.buttons.length">
        <div *ngFor="let button of customAlertData.buttons" 
            class="button btn btn-grey btn-no-icon" 
            [style.color]="button.color"
            [style.background]="button.background"
            (click)="button.onClick()" >
            {{ button.text | translate }}
        </div>
    </div>
</ng-template>
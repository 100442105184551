import { throwDialogContentAlreadyAttachedError } from '@angular/cdk/dialog';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IAction, IActionEvent, IBottomAction } from 'src/app/components';
import { IError } from 'src/app/models/error';
import { ICodeListItem, IListItem } from 'src/app/models/listItem';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiService } from 'src/app/services/api/api.service';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-supplier-edit',
  templateUrl: './supplier-edit.component.html',
  styleUrls: ['./supplier-edit.component.scss']
})
export class SupplierEditComponent {

  isLoading = true;
  supplierForm = this.fb.group({
   name: ['', [Validators.required]],
   email: ['', [Validators.required, Validators.email]],
   address: [''],
   country: [ '' ],
   timeZoneId: [ null ],
   categoryId: [ null ],
   contracts: [[ null as number ]],
   isActive: [ true ],
   flagCsm: [ false ],
   notes: [''],
   services: this.fb.array([
      this.fb.group({
        catalogId: [ null as number | null ],
        jobs: [ [ null as number | null], ],
      })
    ]),
  });
  public countryFilter: FormControl = new FormControl();

  contextActions: IBottomAction[] = [
    { key: 'save', name: "supplier.actions.add", side: 'right', style: 'primary' },
  ];
  
  supplierId?: number = null;
  beErrors: { [key: string]: string } = {};
  timeZones: IListItem[] = [];
  countries: ICodeListItem[] = [];
  categories: IListItem[] = [];
  contracts: IListItem[] = [];
  services: IListItem[] = [];
  jobDescriptions: IListItem[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private api: ApiService,
    private alertService: AlertService,
    private location: Location,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.api.getTimeZones().subscribe((res) => {
      this.timeZones = res.sort((a, b) => a.value.localeCompare(b.value));
    });
    this.api.getSupplierCategories().subscribe((res) => {
      this.categories = res.sort((a, b) => a.value.localeCompare(b.value));
    });
    this.api.getContractTypes().subscribe((res) => {
      this.contracts = res.sort((a, b) => a.value.localeCompare(b.value));
    });
    this.api.getCountries().subscribe((res) => {
      this.countries = res.sort((a, b) => a.value.localeCompare(b.value));
    });
    this.api.getServices().subscribe((res) => {
      this.services = res.sort((a, b) => a.value.localeCompare(b.value));
    });
    this.api.getJobsDescription().subscribe((res) => {
      this.jobDescriptions = res.sort((a, b) => a.value.localeCompare(b.value));
    });

    const id = this.route.snapshot.paramMap.get('id');
    if (id) 
      this.loadData(Number(id));
    else {
      this.removeService(0);
      this.isLoading = false;
    }
  }

  onActionClicked(action: IActionEvent) {
    switch (action.actionKey) {
      case 'delete':
        this.alertService.showAlert({
          type: "warning",
          acceptText: "common.actions.delete",
          message: this.translateService.instant('suppliers.alerts.delete', { supplier: this.supplierForm.controls.name.value }),
          onConfirm: () => {
            
          },
        });
        break;
      case 'cancel':
        this.location.back();
        break;
      case 'save':
        this.supplierForm.markAllAsTouched();
        const errors = this.sharedService.getFormErrors(this.supplierForm);
        if (this.supplierForm.valid || Object.values(errors).filter(e => e['beError'] !== true).length === 0) 
          this.submitForm();
        else {
          this.sharedService.focusOnInvalidInput(this.supplierForm)
        }
        break;
    }
  }

  loadData(supplierId: number) {
    this.isLoading = true;
    this.api.getSupplier(supplierId).subscribe({ next: (supplier) => {
      this.supplierId = supplierId;
      this.contextActions = [
        /* { key: 'delete', name: 'supplier.actions.delete', side: 'left', style: 'warn' }, */
        { key: 'cancel', name: 'common.actions.cancel', side: 'right' },
        { key: 'save', name: "supplier.actions.save", side: 'right', style: 'primary' },
      ];
      
      this.isLoading = false;
      this.supplierForm.controls.services.controls = [];
      this.supplierForm.setValue({
        name: supplier.name || '',
        email: supplier.email || '',
        address: supplier.address || '',
        country: supplier.countryCode || '',
        timeZoneId: supplier.timeZoneId || null,
        categoryId: supplier.categoryId || null,
        contracts: supplier.contracts.map((c) => c.id),
        isActive: supplier.isActive,
        flagCsm: supplier.flagCsm,
        notes: supplier.notes || '',
        services: [],
      });

      supplier.servicesCatalog.forEach((ser) => {
        this.addService({
          catalogId: ser.catalogId,
          jobs: ser.jobs.map((jd) => jd.id),
        })
      });

      this.supplierForm.markAsPristine();

    }, error: () => {
      this.isLoading = false;
    }});
  }

  submitForm() {
    this.api.editSupplier({
      id: this.supplierId || null,
      name: this.supplierForm.controls.name.value,
      email: this.supplierForm.controls.email.value,
      address: this.supplierForm.controls.address.value,
      countryCode: this.supplierForm.controls.country.value,
      timeZoneId: this.supplierForm.controls.timeZoneId.value,
      categoryId: this.supplierForm.controls.categoryId.value,
      contracts: this.supplierForm.controls.contracts.value.filter((c) => c != null),
      isActive: this.supplierForm.controls.isActive.value,
      flagCsm: this.supplierForm.controls.flagCsm.value,
      notes: this.supplierForm.controls.notes.value,
      servicesCatalog: this.supplierForm.controls.services.value.reduce((p, c) => {
        if (c.catalogId != null && c.jobs != null && c.jobs.length)
          p.push({
            id: c.catalogId,
            jobs: c.jobs,
          })
          
        return p;
      }, []),
    }).subscribe({
      next: (supp) => {
        this.supplierForm.markAsUntouched();
        this.router.navigate([ '/backoffice/supplier-panel/', supp.id ]);
      },
      error: (err: IError) => {
        this.supplierForm.markAsTouched();
        err.messages.forEach((m) => {
          this.beErrors[m.field] = m.key;

          //@ts-ignore
          const control = this.supplierForm.controls[m.field];
          if (control) {
            control.setErrors({ beError: true });
            this.sharedService.focusOnInvalidInput(this.supplierForm);
          }
        });
      }
    });
  }

  onServiceValueChanged(serviceFormIndex: number, value: number) {
    if (value != null) {
      this.supplierForm.controls.services.controls[serviceFormIndex].controls.jobs.enable();
      this.supplierForm.controls.services.controls[serviceFormIndex].controls.jobs.setValidators(Validators.required);
    } else {
      this.supplierForm.controls.services.controls[serviceFormIndex].controls.jobs.disable()
      this.supplierForm.controls.services.controls[serviceFormIndex].controls.jobs.setValidators(null);
    }
  }

  get hasUnsavedChanges() {
    return this.supplierForm.touched;
  }

  onNavigationOut() {
    if (this.hasUnsavedChanges) {
      return this.sharedService.showBackNavigationAlertAsync();
    }
    return true;
  }


  onTitleClick() {
    this.router.navigate(['/backoffice/manage-suppliers']);
  }


  getFieldError(controlName: keyof typeof this.supplierForm.controls): string {
    const formControl = this.supplierForm.controls[controlName];

    if (formControl.hasError('required')) {
      return 'common.errors.requiredField';
    }

    if (formControl.hasError('email')){
      switch(controlName) {
        case "email": return 'common.errors.email';
      } 
    }

    if (this.beErrors[controlName]) {
      return 'supplier.errors.' + controlName + '.' + this.beErrors[controlName];
    }

    return ''
  }

  getFormErrors(): string {
    if (this.beErrors['form']) {
      return 'supplier.errors.form.' + this.beErrors['form'];
    }

    return ''
  }


  getAvaibleServices(serviceFormIndex: number): any[] {
    const selectedServices = this.supplierForm.controls.services.value
    const controlValue = selectedServices[serviceFormIndex];
    return this.services.filter((s) => {
      if (controlValue.catalogId === s.id) return true;
      return !selectedServices.some((ss) => ss.catalogId === s.id)
    });
  }
  removeService(serviceFormIndex: number) {
    const service = this.supplierForm.controls.services.controls[serviceFormIndex].value;
    if (service.jobs != null && service.jobs.length && service.catalogId != null) {
      const sr = this.services.find(b => b.id === service.catalogId);

      this.alertService.showAlert({
        type: "warning",
        acceptText: "common.actions.delete",
        message: this.translateService.instant('supplier.alerts.deleteService', { service: sr.value }),
        onConfirm: () => {
          this.alertService.dismissAlert();
          this.supplierForm.controls.services.removeAt(serviceFormIndex);
        },
      });
      return;
    }
    this.supplierForm.controls.services.removeAt(serviceFormIndex);
  }
  addService(data: ReturnType<typeof this.supplierForm.controls.services.at>['value'] = {
    catalogId: null,
    jobs: [ null ],
  }) {
    const service = this.fb.group({
      catalogId: [data.catalogId],
      jobs: [{ value: data.jobs, disabled: data.catalogId == null }],
    })
    this.supplierForm.controls.services.push(service);
  }
}

import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IAction, IActionEvent, IBottomAction } from 'src/app/components';
import { IProcedure } from 'src/app/models/procedure';
import { SharedService } from 'src/app/services/shared/shared.service';

const PROCEDURES: IProcedure[] = [
  {
    title: "New process for Work Orders",
    hideTitle: true,
    type: 'image',
    imageUrl: "assets/procedures/Diapositiva2.PNG",
  },
  {
    title: "Tech Sourcing Guidelines",
    hideTitle: true,
    type: 'image',
    imageUrl: "assets/procedures/Diapositiva4.PNG",
  },
  {
    title: "Tech Sourcing Strategy - Drivers Suppliers Rationalisation",
    hideTitle: true,
    type: 'image',
    imageUrl: "assets/procedures/Diapositiva5.PNG",
  },
  {
    title: "Tech Sourcing Strategy - Drivers Suppliers Selection",
    hideTitle: true,
    type: 'image',
    imageUrl: "assets/procedures/Diapositiva6.PNG",
  },
  {
    title: "IBI Tools High Level Design",
    hideTitle: true,
    type: 'image',
    imageUrl: "assets/procedures/Diapositiva7.PNG",
  },
  {
    title: "Structure of contract & relationship with external party define reporting requirements",
    hideTitle: true,
    type: 'image',
    imageUrl: "assets/procedures/Diapositiva8.PNG",
  },
  {
    title: "NSE reporting: new calculated account & its definitions",
    hideTitle: true,
    type: 'image',
    imageUrl: "assets/procedures/Diapositiva9.PNG",
  },
  /* {
    title: "Example of composite procedure",
    hideTitle: false,
    type: 'composite',
    paragraphs: [
      {
        type: 'text',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin odio nunc, sodales ac semper euismod, vestibulum sit amet neque. Sed id lorem orci. Pellentesque porttitor egestas dolor, dignissim tempus mauris tempor quis. Aliquam ut nisi sagittis, tincidunt libero commodo, pharetra purus. Pellentesque tempus nec felis in porttitor. Aliquam aliquet tempor massa nec congue. Donec sit amet tempus odio, vitae mollis neque. Etiam vehicula mi ac leo finibus scelerisque.'
      },
      {
        type: 'text',
        text: 'Proin pellentesque et nulla sit amet fermentum. Vestibulum vel aliquam diam. Phasellus vestibulum ligula eget libero hendrerit, sit amet vulputate orci cursus. Phasellus tincidunt ac quam placerat ultrices. Proin et massa imperdiet, rhoncus mauris ut, ullamcorper ante. Nulla eu quam sodales, gravida est eget, accumsan arcu. Mauris vestibulum ultrices risus sit amet tincidunt. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis ac volutpat quam, vitae faucibus nibh. Curabitur non orci vel libero ultricies sollicitudin. Sed sapien ligula, luctus non augue pharetra, faucibus congue mi. Curabitur nec lacinia diam. Sed laoreet cursus lectus, ac molestie tortor interdum sit amet.'
      },
      {
        type: 'image',
        imageUrl: "assets/procedures/Diapositiva9.PNG",
      },
      {
        type: 'text',
        text: 'Nullam a justo vitae est viverra semper. Morbi ut condimentum est, vitae dignissim mauris. Nunc porta tincidunt egestas. Praesent eget urna at enim tempor ultricies. Donec nec lorem id tortor hendrerit commodo sit amet vitae elit. Suspendisse lacinia pretium diam eget bibendum. Aenean aliquet arcu vel enim fringilla, vitae sodales nunc sollicitudin.'
      },
      {
        type: 'image',
        imageUrl: "assets/procedures/Diapositiva9.PNG",
      },
    ]
  }, */
]

@Component({
  selector: 'app-procedures',
  templateUrl: './procedures.component.html',
  styleUrl: './procedures.component.scss'
})
export class ProceduresComponent implements OnInit, OnDestroy {

  procedureIndex = 0;
  procedures = PROCEDURES;
  showIndex = true;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private sharedService: SharedService,
  ) { }

  get isFullscreen() {
    return this.sharedService.isFullScreen;
  }

  private subscription: Subscription;
  ngOnInit(): void {
    this.subscription = this.route.queryParams.subscribe(params => {
      let name = params['procedureName'];
      if (name) {
        name = name.trim();
        const index = PROCEDURES.findIndex(p => p.title === name);
        if (index !== -1) {
          this.procedureIndex = index;
          return;
        }
      } 

      this.setPageUrl();
    })
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setPageUrl() {
    const procedure = this.procedures[this.procedureIndex];
    this.location.replaceState("/procedures", new URLSearchParams({ procedureName: procedure.title }).toString());
  }
  setIndex(index: number) {
    this.procedureIndex = index;
    this.setPageUrl();
  }

  contextActions: IAction[] = [
    { key: 'toggleIndex', icon: 'eye-closed.svg', name: 'procedures.actions.hideIndex', activeIcon: 'eye-closed.svg', activeName: 'procedures.actions.showIndex' }
  ]
  bottomActions: IBottomAction[] = [
    { key: 'prev',  name: "common.actions.prev", side: 'right', style: 'accent'  },
    { key: 'next',  name: "common.actions.next", side: 'right', style: 'primary' },
  ];
  onActionClicked(action: IActionEvent) {
    switch (action.actionKey) {
      case 'prev':
        this.procedureIndex -= 1;
        if (this.procedureIndex < 0) this.procedureIndex = this.procedures.length - 1;
        break;
      case 'next':
        this.procedureIndex += 1;
        if (this.procedureIndex >= this.procedures.length) this.procedureIndex = 0;
        break;
      case 'toggleIndex':
        this.showIndex = !action.status;
        break;
    }
  }

}

<app-page-container
  [pageTitleParts]="[ 'suppliers.title', (supplierId != null ? supplierForm.controls.name.value : 'supplier.newSupplier') ]"
  (titleClick)="onTitleClick()"
  [bottomActions]="contextActions"
  (actionClicked)="onActionClicked($event)"
>
  <app-panel
    [style]="'default'"
    [isLoading]="isLoading"
  >
    <form class="vertical-form" [formGroup]="supplierForm" (ngSubmit)="submitForm()" autocomplete="off">

      <mat-form-field class="full-width">
        <mat-label>{{ "supplier.fields.name" | translate }}</mat-label>
        <input matInput formControlName="name" placeholder="Name" [autocomplete]="'supplier.fields.name' | translate">
        <mat-error>{{ getFieldError('name') | translate }}</mat-error>
      </mat-form-field>
      
      <mat-form-field class="full-width">
        <mat-label>{{ "supplier.fields.email" | translate }}</mat-label>
        <input matInput formControlName="email" placeholder="Email" [autocomplete]="'supplier.fields.email' | translate">
        <mat-error>{{ getFieldError('email') | translate }}</mat-error>
      </mat-form-field>
      
      <mat-form-field class="full-width">
        <mat-label>{{ "supplier.fields.address" | translate }}</mat-label>
        <input matInput formControlName="address" placeholder="Address" [autocomplete]="'supplier.fields.address' | translate">
      </mat-form-field>
      
      <mat-form-field class="full-width">
        <mat-label>{{ "supplier.fields.country" | translate }}</mat-label>
        <mat-select formControlName="country" placeholder="{{ 'supplier.fields.country' | translate }}" (opened)="countryFilter.value = ''">
          <div class="select-search">
            <mat-form-field>
              <mat-label>{{ "common.labels.search" | translate }}</mat-label>
              <input matInput [placeholder]="'common.labels.search' | translate" #countryFilter>
            </mat-form-field>
          </div>
          <mat-option [value]="null">{{ "common.labels.empty" | translate }}</mat-option>
          <ng-container *ngFor="let country of countries" >
            <mat-option [attr.data-hidden]="!country.value.toLowerCase().includes(countryFilter.value.toLowerCase())" [value]="country.code">
              {{ country.value }}
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-error>{{ getFieldError('country') | translate }}</mat-error>
      </mat-form-field>
      
      <mat-form-field class="full-width">
        <mat-label>{{ "supplier.fields.timeZone" | translate }}</mat-label>
        <mat-select formControlName="timeZoneId" placeholder="{{ 'supplier.fields.timeZone' | translate }}"  (opened)="timezoneFilter.value = ''">
          <div class="select-search">
            <mat-form-field>
              <mat-label>{{ "common.labels.search" | translate }}</mat-label>
              <input matInput [placeholder]="'common.labels.search' | translate" #timezoneFilter>
            </mat-form-field>
          </div>
          <mat-option [value]="null">{{ "common.labels.empty" | translate }}</mat-option>
          <mat-option *ngFor="let timeZone of timeZones" [attr.data-hidden]="!timeZone.value.toLowerCase().includes(timezoneFilter.value.toLowerCase())" [value]="timeZone.id">
            {{ timeZone.value }}
          </mat-option>
        </mat-select>
        <mat-error>{{ getFieldError('timeZoneId') | translate }}</mat-error>
      </mat-form-field>
      
      <mat-form-field class="full-width">
        <mat-label>{{ "supplier.fields.category" | translate }}</mat-label>
        <mat-select formControlName="categoryId" placeholder="{{ 'supplier.fields.category' | translate }}">
          <mat-option *ngFor="let category of categories" [value]="category.id">
            {{ category.value }}
          </mat-option>
        </mat-select>
        <mat-error>{{ getFieldError('categoryId') | translate }}</mat-error>
      </mat-form-field>
      
      <mat-form-field class="full-width">
        <mat-label>{{ "supplier.fields.contracts" | translate }}</mat-label>
        <mat-select formControlName="contracts" placeholder="{{ 'supplier.fields.contracts' | translate }}" multiple>
          <mat-option *ngFor="let contract of contracts" [value]="contract.id">
            {{ contract.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      
      <div class="mat-form-field span-2">
        <mat-label class="mat-button-toggle-label">{{ "supplier.fields.isActive" | translate }}</mat-label>
        <mat-button-toggle-group formControlName="isActive" aria-label="isActive">
          <mat-button-toggle [value]="true">{{ "common.labels.active" | translate }}</mat-button-toggle>
          <mat-button-toggle [value]="false">{{ "common.labels.disabled" | translate }}</mat-button-toggle>
        </mat-button-toggle-group>  
      </div>

      <div class="mat-form-field span-2">
        <mat-label class="mat-button-toggle-label">{{ "supplier.fields.flagCsm" | translate }}</mat-label>
        <mat-button-toggle-group formControlName="flagCsm" aria-label="flagCsm">
          <mat-button-toggle [value]="true">{{ "common.labels.active" | translate }}</mat-button-toggle>
          <mat-button-toggle [value]="false">{{ "common.labels.disabled" | translate }}</mat-button-toggle>
        </mat-button-toggle-group>  
      </div>
      
      <mat-form-field class="full-width">
        <mat-label>{{ "supplier.fields.notes" | translate }}</mat-label>
        <textarea matInput formControlName="notes" placeholder="{{ 'supplier.fields.notes' | translate }}"></textarea>
      </mat-form-field>

      <h3>{{ "supplier.services" | translate }}</h3>
      <span *ngIf="getFieldError('services')" class="error-label full-width mat-mdc-form-field-error mat-mdc-form-field-bottom-align">{{ getFieldError('services') | translate }}</span>

      <ng-container formArrayName="services">
        <ng-container *ngFor="let service of supplierForm.controls.services.controls; let s_i = index" [formGroupName]="s_i">
            <div class="service-group mat-form-field full-width">
              <div class="remove-button"> 
                <button mat-icon-button type="button" (click)="removeService(s_i)">
                  <img src="/assets/icons/delete.svg" alt="remove" >
                </button>
              </div>
              
              <mat-form-field>
                <mat-label>{{ "supplier.fields.service" | translate }}</mat-label>
                <mat-select formControlName="catalogId" (valueChange)="onServiceValueChanged(s_i, $event)" [placeholder]="'supplier.fields.service' | translate"  (opened)="catalogIdFilter.value = ''">
                  <div class="select-search">
                    <mat-form-field>
                      <mat-label>{{ "common.labels.search" | translate }}</mat-label>
                      <input matInput [placeholder]="'common.labels.search' | translate" #catalogIdFilter>
                    </mat-form-field>
                  </div>
                  <ng-container *ngFor="let catalog of getAvaibleServices(s_i)" >
                    <mat-option [attr.data-hidden]="!catalog.value.toLowerCase().includes(catalogIdFilter.value.toLowerCase())" [value]="catalog.id">
                      {{ catalog.value }}
                    </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error>{{ "common.errors.requiredField" | translate }}</mat-error>
              </mat-form-field>
              
              <mat-form-field>
                <mat-label>Job Descriptions</mat-label>
                <mat-select formControlName="jobs" placeholder="Job Descriptions" (opened)="jobDescriptionFilter.value = ''" multiple>
                  <div class="select-search">
                    <mat-form-field>
                      <mat-label>{{ "common.labels.search" | translate }}</mat-label>
                      <input matInput [placeholder]="'common.labels.search' | translate" #jobDescriptionFilter>
                    </mat-form-field>
                  </div>
                  <ng-container *ngFor="let jobDescription of jobDescriptions">
                    <mat-option [attr.data-hidden]="!jobDescription.value.toLowerCase().includes(jobDescriptionFilter.value.toLowerCase())" [value]="jobDescription.id">{{ jobDescription.value }}</mat-option>
                  </ng-container>
                </mat-select>
                <mat-error>{{ "common.errors.requiredField" | translate }}</mat-error>
              </mat-form-field>         
            </div>
        </ng-container>
      </ng-container>
      <div class="service-group mat-form-field full-width">
        <div class="add-button"> 
          <button mat-mini-fab type="button" color="primary" (click)="addService()">
            <img src="/assets/icons/add.svg" class="white-icon" alt="add" >
          </button>
        </div>
        <div class="fake-field"></div>
        <div class="fake-field"></div>
      </div>

    </form>
  </app-panel>
</app-page-container>

import { Component, Input, ViewChild, ElementRef, OnInit, TemplateRef } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrl: './image-viewer.component.scss'
})
export class ImageViewerComponent implements OnInit {

  constructor(
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {

  }

  /**
   * URL or base64 string of the image to be displayed
  */
 @Input() image: string;
 @Input() imageDescription?: string;
 
 @ViewChild('fullScreenImage') modalTemplate: TemplateRef<any>;
 @ViewChild('imageElement') imageElement: ElementRef<HTMLImageElement>;

  openFullScreenImage() {
    this.alertService.showAlert({
      size: 'large',
      templateRef: this.modalTemplate,
    });
    setTimeout(() => {
      this.imageElement.nativeElement.addEventListener('dblclick', (event: MouseEvent) => {
        this.zoom(
          event.altKey ? ( this.zoomMode === 'in' ? 'out' : 'in' ) : this.zoomMode
        );
      });
  
      this.imageElement.nativeElement.addEventListener('mousedown', (event: MouseEvent) => {
        if (this.scale === 1) return;
  
        this.dragging = true;
        this.startX = event.clientX - this.positionX;
        this.startY = event.clientY - this.positionY;
      });
  
      this.imageElement.nativeElement.addEventListener('mousemove', (event: MouseEvent) => {
        if (!this.dragging) return;
        this.positionX = event.clientX - this.startX;
        this.positionY = event.clientY - this.startY;
        this.applyChanges();
      });
      
      this.imageElement.nativeElement.addEventListener('mouseleave', (event: MouseEvent) => {
        this.dragging = false;
      });
  
      this.imageElement.nativeElement.addEventListener('mouseup', () => {
        this.dragging = false;
      });
    }, 100);
  }
  dismissAlert() {
    this.alertService.dismissAlert();
  }

  zoomModeControl: 'in' | 'out' = 'in'
  
  get zoomMode() {
    return this.zoomModeControl;
  }

  private dragging = false;
  private positionX = 0;
  private positionY = 0;
  
  private startX = 0;
  private startY = 0;

  public scale = 1;

  reset() {
    this.scale = 1;
    this.positionX = 0;
    this.positionY = 0;
    this.applyChanges();
  }

  applyChanges() {
    this.imageElement.nativeElement.style.transform = `translate(${this.positionX}px, ${this.positionY}px) scale(${this.scale})`;
  }

  clickedZoom(mode: 'in' | 'out') {
    if (this.zoomModeControl !== mode) {
      this.zoomModeControl = mode;
      return;
    }
    this.zoom(mode);
  }
  zoom(mode: 'in' | 'out') {
    if (mode === 'in' && this.scale < 2) {
      this.scale += 0.5;
    } else if (mode === 'out' && this.scale > 1) {
      this.scale -= 0.5;
    }

    if (this.scale === 1) 
      this.reset();
    else
      this.applyChanges();
  }
}

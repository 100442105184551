import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InternationalizationService } from 'src/app/services/internationalization/internationalization.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    rememberMe: [ true ]
  });
  loginFailedMessage = '';

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public internationalizationService: InternationalizationService,
  ) { }

  ngOnInit(): void {
    if (this.userService.isLoggedIn) {
      if (this.route.snapshot.paramMap.get('logout')) {
        this.userService.logout();
        return;
      }
      this.router.navigate(["/home"]);
    }
  }

  login() {
    this.userService.login(
      this.loginForm.controls.email.value, 
      this.loginForm.controls.password.value, 
      this.loginForm.controls.rememberMe.value
    ).subscribe(res => {
      switch (res) {
        case 0:
          this.router.navigate(["/home"]);
          break;
        case 1:
          this.loginFailedMessage = "login.errors.invalid_credentials";
          break;
        case 2:
          this.loginFailedMessage = "login.errors.user_disabled";
          break;
      } 
    });
  }

}

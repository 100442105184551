<app-page-container
  [pageTitleParts]="[ 'projects.title', projectTitle, 'rating.newRating' ]"
  (titleClick)="onTitleClick($event)"
  [bottomActions]="contextActions"
  (actionClicked)="onActionClicked($event)"
>
  <app-panel
    [style]="'default'"
  >
    <form [formGroup]="dateForm">
      <span *ngIf="getFormErrors() != ''" class="error-label full-width mat-mdc-form-field-error mat-mdc-form-field-bottom-align">
        {{ getFormErrors() | translate: { startDate: projectStartQuarter } }}
      </span>
      <mat-form-field>
        <mat-label>{{ 'rating.fields.year' | translate }}</mat-label>
        <input matInput readonly [matDatepicker]="picker3" formControlName="year" [min]="projectStartDate">
        <mat-hint>YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker3">
          <mat-icon matDatepickerToggleIcon>
            <img src="/assets/icons/calendar.svg" alt="calendar">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker3 startView="multi-year" panelClass="year-picker" (yearSelected)="setYear($event, picker3)" view></mat-datepicker>
        <mat-error>{{ getFieldError('year') | translate: { startDate: projectStartYear } }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'rating.fields.quarter' | translate }}</mat-label>
        <mat-select formControlName="quarter">
          <mat-option *ngFor="let quarter of quarters" [value]="quarter.value">{{ quarter.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <app-rating-form #ratingForm
      [(answers)]="answers"
    ></app-rating-form>
  </app-panel>
</app-page-container>

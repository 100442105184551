<div class="container">
  <div class="button-group" *ngIf="currentView !== 'year' && currentView !== 'multi-year'">
    <button mat-icon-button (click)="previousClicked('month')" [disabled]="!previousEnabled('month')">
      <img class="arrow" src="../../../assets/icons/arrow-left.svg" alt="previous month">
    </button>
    <button mat-button (click)="showView('year')" class="mat-calendar-period-button mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base">
      <span class="example-header-label">{{ monthLabel }}</span>
    </button>
    <button mat-icon-button (click)="nextClicked('month')" [disabled]="!nextEnabled('month')">
      <img class="arrow" src="../../../assets/icons/arrow-right.svg" alt="next month">
    </button>
  </div>

  <div class="button-group" *ngIf="currentView !== 'multi-year'"> 
    <button mat-icon-button (click)="previousClicked('year')" [disabled]="!previousEnabled('year')">
      <img class="arrow" src="../../../assets/icons/arrow-left.svg" alt="previous year">
    </button>
    <button mat-button (click)="showView('multi-year')" class="mat-calendar-period-button mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base">
      <span class="example-header-label">{{ yearLabel }}</span>
    </button>
    <button mat-icon-button (click)="nextClicked('year')" [disabled]="!nextEnabled('year')">
      <img class="arrow" src="../../../assets/icons/arrow-right.svg" alt="next year">
    </button>
  </div>

  <div class="button-group" *ngIf="currentView === 'multi-year'">
    <button mat-icon-button (click)="previousClicked('multi-year')" [disabled]="!previousEnabled('multi-year')">
      <img class="arrow" src="../../../assets/icons/arrow-left.svg" alt="previous year">
    </button>
    <button mat-button (click)="showView('multi-year')" class="mat-calendar-period-button mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base">
      <span class="example-header-label">{{ multiYearLabel }}</span>
    </button>
    <button mat-icon-button (click)="nextClicked('multi-year')" [disabled]="!nextEnabled('multi-year')">
      <img class="arrow" src="../../../assets/icons/arrow-right.svg" alt="next year">
    </button>
  </div>
</div>
<div class="underButtons" *ngIf="showDateRangeButtons">
  <button style="flex: 1 1 0;" mat-button (click)="selectMonth()">
    {{ "common.labels.selectMonth" | translate }}
  </button>
  <button style="flex: 1 1 0;" mat-button (click)="selectYear()">
    {{ "common.labels.selectYear" | translate }}
  </button>
</div>


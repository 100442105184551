<app-page-container
  [pageTitleParts]="[ 'projects.title', projectForm.value.title ]"
  (titleClick)="onTitleClick()"
  [bottomActions]="contextActions"
  (actionClicked)="onActionClicked($event)"
>
  <app-panel
    [style]="'default'"
    [isLoading]="isLoading"
  >
    <form class="vertical-form" [formGroup]="projectForm" (ngSubmit)="submitForm()" autocomplete="off">

      <mat-form-field class="full-width">
        <mat-label>{{ "project.fields.title" | translate }}</mat-label>
        <input matInput formControlName="title" [placeholder]="'project.fields.title' | translate">
        <mat-error>{{ getFieldError('title') | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "project.fields.tpo" | translate }}</mat-label>
        <mat-select formControlName="tpoId" [placeholder]="'project.fields.tpo' | translate" (opened)="tposFilter.value = ''" >
          <div class="select-search">
            <mat-form-field>
              <mat-label>{{ "common.labels.search" | translate }}</mat-label>
              <input matInput [placeholder]="'common.labels.search' | translate" #tposFilter>
            </mat-form-field>
          </div>
          <mat-option [value]="null">
            {{ "common.labels.empty" | translate }}
          </mat-option>
          <mat-option *ngFor="let tpo of tpoList" [value]="tpo.id" [attr.data-hidden]="!tpo.value.toLowerCase().includes(tposFilter.value.toLowerCase())" >
            {{ tpo.value }}
          </mat-option>
        </mat-select>
        <mat-error>{{ getFieldError('tpoId') | translate }}</mat-error>
      </mat-form-field>
      
      <mat-form-field>
        <mat-label>{{ "project.fields.contractManager" | translate }}</mat-label>
        <mat-select formControlName="contractManagerId" [placeholder]="'project.fields.contractManager' | translate" (opened)="contractManagersFilter.value = ''" >
          <div class="select-search">
            <mat-form-field>
              <mat-label>{{ "common.labels.search" | translate }}</mat-label>
              <input matInput [placeholder]="'common.labels.search' | translate" #contractManagersFilter>
            </mat-form-field>
          </div>
          <mat-option [value]="null">
            {{ "common.labels.empty" | translate }}
          </mat-option>
          <mat-option *ngFor="let contractManager of contractManagers" [value]="contractManager.id" [attr.data-hidden]="!contractManager.value.toLowerCase().includes(contractManagersFilter.value.toLowerCase())" >
            {{ contractManager.value }}
          </mat-option>
        </mat-select>
        <mat-error>{{ getFieldError('contractOwnerId') | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "project.fields.contractOwner" | translate }}</mat-label>
        <mat-select formControlName="contractOwnerId" [placeholder]="'project.fields.contractOwner' | translate" (opened)="contractOwnersFilter.value = ''" >
          <div class="select-search">
            <mat-form-field>
              <mat-label>{{ "common.labels.search" | translate }}</mat-label>
              <input matInput [placeholder]="'common.labels.search' | translate" #contractOwnersFilter>
            </mat-form-field>
          </div>
          <mat-option [value]="null">
            {{ "common.labels.empty" | translate }}
          </mat-option>
          <mat-option *ngFor="let contractOwner of contractOwners" [value]="contractOwner.id" [attr.data-hidden]="!contractOwner.value.toLowerCase().includes(contractOwnersFilter.value.toLowerCase())" >
            {{ contractOwner.value }}
          </mat-option>
        </mat-select>
        <mat-error>{{ getFieldError('contractOwnerId') | translate }}</mat-error>
      </mat-form-field>
      
      <mat-form-field class="full-width">
        <mat-label>{{ "project.fields.externalFTE" | translate }}</mat-label>
        <input matInput (keydown)="onRateKeyDown($event)" type="number" formControlName="externalFTE" [placeholder]="'project.fields.externalFTE' | translate">
        <mat-error>{{ getFieldError('externalFTE') | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field class="span-4">
        <mat-label>{{ "project.fields.technology" | translate }}</mat-label>
        <mat-select formControlName="technologyId" [placeholder]="'project.fields.technology' | translate" (opened)="technologiesFilter.value = ''" >
          <div class="select-search">
            <mat-form-field>
              <mat-label>{{ "common.labels.search" | translate }}</mat-label>
              <input matInput [placeholder]="'common.labels.search' | translate" #technologiesFilter>
            </mat-form-field>
          </div>
          <mat-option [value]="null">
            {{ "common.labels.empty" | translate }}
          </mat-option>
          <mat-option *ngFor="let technology of technologies" [value]="technology.id" [attr.data-hidden]="!technology.value.toLowerCase().includes(technologiesFilter.value.toLowerCase())" >
            {{ technology.value }}
          </mat-option>
        </mat-select>
        <mat-error>{{ getFieldError('technologyId') | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field class="span-4">
        <mat-label>{{ "project.fields.fteRole" | translate }}</mat-label>
        <mat-select formControlName="fteRoleId" [placeholder]="'project.fields.fteRole' | translate" (opened)="fteRolesFilter.value = ''" >
          <div class="select-search">
            <mat-form-field>
              <mat-label>{{ "common.labels.search" | translate }}</mat-label>
              <input matInput [placeholder]="'common.labels.search' | translate" #fteRolesFilter>
            </mat-form-field>
          </div>
          <mat-option [value]="null">
            {{ "common.labels.empty" | translate }}
          </mat-option>
          <mat-option *ngFor="let fteRole of FTERoles" [value]="fteRole.id" [attr.data-hidden]="!fteRole.value.toLowerCase().includes(fteRolesFilter.value.toLowerCase())" >
            {{ fteRole.value }}
          </mat-option>
        </mat-select>
        <mat-error>{{ getFieldError('fteRoleId') | translate }}</mat-error>
      </mat-form-field>


      <mat-form-field class="full-width">
        <mat-label>{{ "project.fields.serviceType" | translate }}</mat-label>
        <mat-select formControlName="serviceTypeId" [placeholder]="'project.fields.serviceType' | translate" (opened)="serviceTypesFilter.value = ''" >
          <div class="select-search">
            <mat-form-field>
              <mat-label>{{ "common.labels.search" | translate }}</mat-label>
              <input matInput [placeholder]="'common.labels.search' | translate" #serviceTypesFilter>
            </mat-form-field>
          </div>
          <mat-option [value]="null">
            {{ "common.labels.empty" | translate }}
          </mat-option>
          <mat-option *ngFor="let serviceType of serviceTypes" [value]="serviceType.id" [attr.data-hidden]="!serviceType.value.toLowerCase().includes(serviceTypesFilter.value.toLowerCase())" >
            {{ serviceType.value }}
          </mat-option>
        </mat-select>
        <mat-error>{{ getFieldError('serviceTypeId') | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>{{ "supplier.fields.contract" | translate }}</mat-label>
        <mat-select formControlName="contractId" placeholder="{{ 'project.fields.contract' | translate }}">
          <mat-option *ngFor="let contract of contracts" [value]="contract.id">
            {{ contract.value }}
          </mat-option>
        </mat-select>
        <mat-error>{{ getFieldError('contractId') | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "project.fields.startDate" | translate }}</mat-label>
        <input matInput [matDatepicker]="picker1" formControlName="startDate">
        <mat-hint>{{ internationalizationService.getDateFormatString() }}</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker1">
          <mat-icon matDatepickerToggleIcon>
            <img src="/assets/icons/calendar.svg" alt="calendar">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker1 [calendarHeaderComponent]="datepickerHeaderComponent">
        </mat-datepicker>
        <mat-error>{{ getFieldError('startDate') | translate }}</mat-error>
      </mat-form-field>
  
      <mat-form-field>
        <mat-label>{{ "project.fields.endDate" | translate }}</mat-label>
        <input matInput [matDatepicker]="picker2" formControlName="endDate" [min]="projectForm.controls.startDate.value">
        <mat-hint>{{ internationalizationService.getDateFormatString() }}</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker2">
          <mat-icon matDatepickerToggleIcon>
            <img src="/assets/icons/calendar.svg" alt="calendar">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker2 [calendarHeaderComponent]="datepickerHeaderComponent"></mat-datepicker>
        <mat-error>{{ getFieldError('endDate') | translate }}</mat-error>
      </mat-form-field>
  
      <mat-form-field>
        <mat-label>{{ "project.fields.dueDate" | translate }}</mat-label>
        <input matInput [matDatepicker]="picker3" formControlName="dueDate" [min]="projectForm.controls.startDate.value">
        <mat-hint>{{ internationalizationService.getDateFormatString() }}</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker3">
          <mat-icon matDatepickerToggleIcon>
            <img src="/assets/icons/calendar.svg" alt="calendar">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker3 [calendarHeaderComponent]="datepickerHeaderComponent"></mat-datepicker>
        <mat-error>{{ getFieldError('dueDate') | translate }}</mat-error>
      </mat-form-field>
          
      <mat-form-field class="full-width">
        <mat-label>{{ "project.fields.miscellous" | translate }}</mat-label>
        <textarea matInput formControlName="miscellous" [placeholder]="'project.fields.miscellous' | translate"></textarea>
        <mat-error>{{ getFieldError('miscellous') | translate }}</mat-error>
      </mat-form-field>
    </form>
  </app-panel>
</app-page-container>

import { Component, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-accordion-panel',
  templateUrl: './accordion-panel.component.html',
  styleUrls: ['./accordion-panel.component.scss'],
  animations: [
    trigger('panelState', [
      state('open', style({
        height: '*',
        opacity: 1,
      })),
      state('closed', style({
        height: "0px",
      })),
      transition('open <=> closed', [
        animate('0.2s ease-in-out')
      ]),
    ])
  ],
})
export class AccordionPanelComponent {
  get getToggleDrawer(): string {
    return this.open ? 'open' : 'closed';
  }

  @Input() title: string;

  @Input() isLoading: boolean = false;

  @Input() style: "default" | "secondary" = "default";

  @Input() open: boolean = true;
  @Output() openChange = new EventEmitter<boolean>();

  toggleDrawer() {
    this.open = !this.open;
    this.openChange.emit(this.open);
  }

}

export enum ERole {
  "Admin" = "AD",
  "CS" = "CS",
  "TechSourcing" = "TS",
  "Tribe" = "TR",
}

export interface ITribe {
  id: number,
  name: string,
  leadId: number,
  lead: string,
}
export interface IBusinessLine {
  id: number,
  name: string,
  tribes: ITribe[],
}

export interface IUser {
  id: number,
  email: string,
  role: ERole,
  isActive: boolean,
  tribes: Array<{
    businessLineId: number,
    businessLine: string,
    tribeId: number,
    tribe: string,
  }>,
} 

export interface IEditUser {
  id?: number,
  email: string,
  role: ERole,
  isActive: boolean,
  tribes: number[],
  password?: string,
} 


import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ERole, IUser } from 'src/app/models/user';
import { IError } from 'src/app/models/error';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userInfo?: {
    email: string,
    tribes: Array<{ tribeId: number, tribe: string, businessLineId: number, businessLine: string }>,
    role: ERole,
    token: string,
  }

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) { 
    localStorage.getItem("user") && (this.userInfo = JSON.parse(localStorage.getItem("user") as string));
  }

  public userChanged = new EventEmitter();

  get isLoggedIn() { 
    return (this.userInfo && this.userInfo.token) ? true : false;
  }

  get email() {  
    return (this.userInfo && this.userInfo.email) || "";
  }
  get username() {  
    return (this.userInfo && this.userInfo.email.split("@")[0]) || "";
  }
  get tribes() {  
    return this.userInfo && this.userInfo.tribes;
  }
  get role() {  
    return this.userInfo && this.userInfo.role;
  }

  /**
   * 
   * @param email 
   * @param password 
   * @param storeInfo 
   * @returns 0 if login successfull, 1 if login failed, 2 if login failed because user is disabled
   */
  login(email: string, password: string, storeInfo: boolean) {
    return this.httpClient.post<IUser & { token: string }>(environment.apiURL + "/login", { username: email, password: password }).pipe(
      map((res) => {
        this.userInfo = {
          email: email,
          tribes: res.tribes,
          role: res.role,
          token: res.token,
        }

        if (storeInfo) {
          localStorage.setItem("user", JSON.stringify(this.userInfo));
        }
        this.userChanged.emit();

        return 0;
      }), 
      catchError((err: IError) => {

        if (err.messages.some(e => e.key === "inactive_user")) return of(2);
        return of(1);
      })
    );
  }

  logout() {
    this.userInfo = undefined;
    this.userChanged.emit();
    localStorage.removeItem("user");
    this.router.navigate(["/login"]);
  }

  saveSetting(key: string, value: string, temporary?: boolean) {
    if (temporary) 
      sessionStorage.setItem(this.username.concat("_", key), value);
    else
      localStorage.setItem(this.username.concat("_", key), value);
  }

  deleteSetting(key: string, temporary?: boolean) {
    if (temporary) 
      sessionStorage.removeItem(this.username.concat("_", key));
    else
      localStorage.removeItem(this.username.concat("_", key));
  }

  getSetting(key: string, temporary?: boolean) {
    if (temporary) 
      return sessionStorage.getItem(this.username.concat("_", key));
    // else
    return localStorage.getItem(this.username.concat("_", key)) || sessionStorage.getItem(this.username.concat("_", key));
  }

  getToken() {
    return this.userInfo && this.userInfo.token;
  }
  
}


  <div class="stars-container">
    <img *ngFor="let star of stars; let i = index" (click)="clickedStar(i)" src="../../../assets/icons/star/{{ star }}.svg" alt="rating">
  </div>
  <span >
    {{ stars.length !== 5 ? ("rating.rating.n" | translate) : (ratingsNumber + " " + ("rating.rating" | pluralTranslate: ratingsNumber | translate)) }}
  </span>





import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IProject } from 'src/app/models/project';
import { InternationalizationService } from 'src/app/services/internationalization/internationalization.service';

const now = new Date();

@Component({
  selector: 'app-project-timing',
  templateUrl: './project-timing.component.html',
  styleUrl: './project-timing.component.scss'
})
export class ProjectTimingComponent {

  completeRatio = 0.5;

  constructor(
    public internationalizationService: InternationalizationService,
  ) { }

  timing = "";
  dates = {
    startDate: now,
    dueDate: now,
    endDate: now,
  }

  @Input() set project(project: IProject) {
    if (project) {
      this.timing = project.timing;

      this.dates.startDate = new Date(project.startDate);
      this.dates.dueDate = new Date(project.dueDate);
      this.dates.endDate = new Date(project.endDate);
      
      const maxDate = this.dates.endDate.getTime() > this.dates.dueDate.getTime() ? this.dates.endDate : this.dates.dueDate;
      const endTime = maxDate.getTime();
      const startTime = this.dates.startDate.getTime();
      const nowTime = new Date().getTime();
      
      if (nowTime > endTime)
        this.completeRatio = 1;
      else if (nowTime < startTime)
        this.completeRatio = 0;
      else
        this.completeRatio = (nowTime - startTime) / (endTime - startTime);
    }
  }


}

import { Location, NumberSymbol } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IGraphItem, ILineGraphItem, IListItem } from 'src/app/models/listItem';
import { EProjectStatus, IProject } from 'src/app/models/project';
import { IServiceCatalog, ISupplier } from 'src/app/models/supplier';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ERole, IBusinessLine } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api/api.service';
import { UserService } from 'src/app/services/user/user.service';
import { IRatingHistory } from 'src/app/models/ratingHistory';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { InternationalizationService } from 'src/app/services/internationalization/internationalization.service';
import * as moment from 'moment';

@Component({
  selector: 'app-supplier-panel',
  templateUrl: './supplier-panel.component.html',
  styleUrls: ['./supplier-panel.component.scss'],
  animations: [
    trigger('panelState', [
      state('expanded', style({
        height: '*',
        "flex-expand": 1,
        overflow: "auto",
      })),
      state('minimized', style({
        "flex-expand": 0,
        height: "220px",
        overflow: "hidden",
      })),
      transition('expanded <=> minimized', [
        animate('0.2s ease-in-out')
      ]),
    ]),
  ],
})
export class SupplierPanelComponent implements OnInit {

  isLoading = true;
  isSummaryExpanded = false;
  isSupplierDetailsLoading = true;

  supplier: ISupplier;

  tribesLenght = 0;
  businessLines: IBusinessLine[] = [];
  projects: IProject[] = [];

  isScorecardLoading = true;
  supplierRatingGraphData: IRatingHistory[] = []; 
  
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    public internationalizationService: InternationalizationService,
    private api: ApiService,
    private userService: UserService,
  ) { }

  @Input() supplierId: number;

  isCondensed = false;

  ngOnInit(): void {
    // check if component is not used as a route
    if (this.route.component !== this.constructor) {
      this.isCondensed = true;
    }

    const id = this.supplierId || this.route.snapshot.paramMap.get('id');
    if (id) {
      const numId = Number(id);
      this.loadData(numId);
      this.loadSupplierDetails(numId);
      this.api.getSupplierRating(numId).subscribe(data => {
        this.supplierRatingGraphData = data;
        this.isScorecardLoading = false;
      })
    } else {
      this.location.back();
    }
  }

  get canEdit() {
    return this.userService.role === ERole.Admin && !this.isCondensed;
  }
  editSupplier() {
    this.router.navigate(['/backoffice/supplier/', this.supplier.id]);
  }

  loadData(id: number) {
    this.isLoading = true;
    this.api.getSupplier(id).subscribe({
      next: (supplier) => {
        this.isLoading = false;
        this.supplier = supplier;
        this.supplier.servicesCatalog = this.supplier.servicesCatalog.sort((a, b) => a.catalog.localeCompare(b.catalog));

        this.changePagedProjects();
      }, 
      error: (error) => { 
        this.isLoading = false; 
      }
    });
  }

  loadSupplierDetails(id: number) {
    this.isSupplierDetailsLoading = true;
    this.api.getSupplierDetails(id).subscribe({
      next: (supplier) => {
        this.businessLines = supplier.businessLines; 
        this.projects = supplier.projects;

        this.tribesLenght = 0;
        this.businessLines.forEach((bl) => bl.tribes.forEach((t) => ++this.tribesLenght));

        this.isSupplierDetailsLoading = false;
      }, 
      error: (error) => {
      }
    });
  }

  
  projectsPageData = {
    index: 0,
    size: 10,
    sort: null as Sort,
    totalElements: 0,
  }
  projectsDisplayedColumns = [
    'title',
    'startDate',
    'endDate',
    'actions',
  ]
  isProjectsLoading = true;
  projectStatusFilter: EProjectStatus | 'ALL' = EProjectStatus.Open;
  projectsDatasource = new MatTableDataSource<IProject>([]);
  changePagedProjects(pageNumber?: number, pageSize?: number, sort?: Sort) {
    this.isProjectsLoading = true;
    
    if (pageSize != null) this.projectsPageData.size = pageSize;
    if (pageNumber != null) this.projectsPageData.index = pageNumber;
    if (sort) this.projectsPageData.sort = sort;
    
    this.api.getPagedProjects({ supplierId: this.supplier.id, status: this.projectStatusFilter !== 'ALL' ? this.projectStatusFilter : null }, 
      this.projectsPageData.index,
      this.projectsPageData.size,
      this.projectsPageData.sort 
    ).subscribe((projects) => {
      this.isProjectsLoading = false;
      this.projectsPageData.totalElements = projects.totalElements;
      this.projectsDatasource.data = projects.content;
    })
  }

  onTitleClick() {
    if (this.router.url.includes('backoffice')) {
      this.router.navigate(['/backoffice/manage-suppliers']);
    } else {
      this.router.navigate(['/projectsAndSuppliers/suppliers']);
    }
    
  }
  
  getListItemValue(items: IListItem[]) {
    return items && items.map((i) => i.value).join(', ');
  }

  getServiceText(services: IServiceCatalog[]) {
    return services && services.map((s) => s.catalog).join(', ');
  }

  getSupplierTime() { 
    if (!this.supplier || !this.supplier.timeZone) return "-"

    return new Date().toLocaleString(undefined, { 
      timeZone: this.supplier.timeZoneLocale,
      formatMatcher: 'basic'
    });
  }

  goToProjectPanel(project: IProject) {
    this.router.navigate(['/projectsAndSuppliers/project-panel/', project.id]);
  }
  goToProjectsList() {
    const filters: { [key: string]: any } = {
      supplierId: this.supplier.id,
      supplierNameFilter: this.supplier.name,
    }
    if (this.projectStatusFilter) filters['statusCode'] = this.projectStatusFilter;

    this.router.navigate(['/projectsAndSuppliers/projects'], {
      queryParams: {
        filters: JSON.stringify(filters),
      }
    });
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { DateRange, MatCalendar, MatDatepickerIntl } from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
} from '@angular/material/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

function euclideanModulo(a: number, b: number) {
  return (a % b + b) % b;
}

function getActiveOffset<D>(dateAdapter: DateAdapter<D, any>, activeDate: D, minDate: D, maxDate: D) {
  const activeYear = dateAdapter.getYear(activeDate);
  return euclideanModulo(activeYear - getStartingYear(dateAdapter, minDate, maxDate), yearsPerPage);
}

function isSameMultiYearView<D>(dateAdapter: DateAdapter<D, any>, date1: D, date2: D, minDate: D, maxDate: D) {
  const year1 = dateAdapter.getYear(date1);
  const year2 = dateAdapter.getYear(date2);
  const startingYear = getStartingYear(dateAdapter, minDate, maxDate);
  return Math.floor((year1 - startingYear) / yearsPerPage) === Math.floor((year2 - startingYear) / yearsPerPage);
}

function getStartingYear<D>(dateAdapter: DateAdapter<D, any>, minDate: D, maxDate: D,) {
  let startingYear = 0;
  if (maxDate) {
    const maxYear = dateAdapter.getYear(maxDate);
    startingYear = maxYear - yearsPerPage + 1;
  } else if (minDate) {
    startingYear = dateAdapter.getYear(minDate);
  }
  return startingYear;
}

const yearsPerPage = 24;

@Component({
  selector: 'app-datepicker-header',
  templateUrl: './datepicker-header.component.html',
  styleUrls: [ './datepicker-header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();
  public showDateRangeButtons = false;

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
    console.log(_calendar);

    const selectedDate = this._calendar.selected as DateRange<D>;
    if (selectedDate && selectedDate.start !== undefined && selectedDate.end !== undefined ) {
      this.showDateRangeButtons = true;
    }
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get monthLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel.split(" ")[0])
      .toLocaleUpperCase();
  }

  get yearLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel.split(" ")[1])
      .toLocaleUpperCase();
  }

  get multiYearLabel() {
    return this._formatMinAndMaxYearLabels().join(' \u2013 ');
  }

  get currentView() {
    return this._calendar.currentView
  }

  previousEnabled(view: 'month' | 'year' | 'multi-year') {
    if (!this._calendar.minDate) {
      return true;
    }
    return !this._calendar.minDate || !this._isSameView(view, this._calendar.activeDate, this._calendar.minDate);
  }

  nextEnabled(view: 'month' | 'year' | 'multi-year') {
    return !this._calendar.maxDate || !this._isSameView(view, this._calendar.activeDate, this._calendar.maxDate);
  }

  _isSameView(view: 'month' | 'year' | 'multi-year', date1: D, date2: D) {
    if (view == 'month') {
      return this._dateAdapter.getYear(date1) == this._dateAdapter.getYear(date2) && this._dateAdapter.getMonth(date1) == this._dateAdapter.getMonth(date2);
    }
    if (view == 'year') {
      return this._dateAdapter.getYear(date1) == this._dateAdapter.getYear(date2);
    }
    // Otherwise we are in 'multi-year' view.
    return isSameMultiYearView(this._dateAdapter, date1, date2, this._calendar.minDate, this._calendar.maxDate);
  }
  
  _formatMinAndMaxYearLabels(): [ string, string ] {
    const activeYear = this._dateAdapter.getYear(this._calendar.activeDate);
    const minYearOfPage = activeYear -
        getActiveOffset(this._dateAdapter, this._calendar.activeDate, this._calendar.minDate, this._calendar.maxDate);
    const maxYearOfPage = minYearOfPage + yearsPerPage - 1;
    const minYearLabel = this._dateAdapter.getYearName(this._dateAdapter.createDate(minYearOfPage, 0, 1));
    const maxYearLabel = this._dateAdapter.getYearName(this._dateAdapter.createDate(maxYearOfPage, 0, 1));
    return [minYearLabel, maxYearLabel];
  }

  showView(view: 'year' | 'multi-year') {
    if (this._calendar.currentView === 'month') {
      this._calendar.currentView = view;
    } else {
      this._calendar.currentView = 'month';
    }
  }

  previousClicked(mode: 'month' | 'year' | 'multi-year') {
    switch (mode) {
      case 'month':
        this._calendar.activeDate =
          this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
        break;
      case 'year':
        this._calendar.activeDate =
        this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
        break;
      case 'multi-year':
        this._calendar.activeDate =
          this._dateAdapter.addCalendarYears(this._calendar.activeDate, -yearsPerPage);
        break;
    }
  }

  nextClicked(mode: 'month' | 'year' | 'multi-year') {
    switch (mode) {
      case 'month':
        this._calendar.activeDate =
          this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
        break;
      case 'year':
        this._calendar.activeDate =
        this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
        break;
      case 'multi-year':
        this._calendar.activeDate =
          this._dateAdapter.addCalendarYears(this._calendar.activeDate, yearsPerPage);
        break;
    }
  }

  selectYear() {
    const selectedDate = this._calendar.selected as DateRange<D>;
    const year = this._dateAdapter.getYear(this._calendar.activeDate);
    const startDate = this._dateAdapter.createDate(year, 0, 1);

    // if the date is already selected, we don't need to select it again
    if (!selectedDate || !selectedDate.start || selectedDate.end || this._dateAdapter.format(selectedDate.start, 'YYYY-MM-DD') !== this._dateAdapter.format(startDate, 'YYYY-MM-DD'))
      this._calendar._dateSelected({
        event: new Event('click'),
        value: startDate as D,
      });

    this._calendar._dateSelected({
      event: new Event('click'),
      value: this._dateAdapter.createDate(year, 11, 1),
    });
    
  }
  selectMonth() {
    const selectedDate = this._calendar.selected as DateRange<D>;
    const year = this._dateAdapter.getYear(this._calendar.activeDate);
    const month = this._dateAdapter.getMonth(this._calendar.activeDate);
    const lastDay = new Date(year, month + 1, 0).getDate();
    const startDate = this._dateAdapter.createDate(year, month, 1);

    // if the date is already selected, we don't need to select it again
    if (!selectedDate || !selectedDate.start || selectedDate.end || this._dateAdapter.format(selectedDate.start, 'YYYY-MM-DD') !== this._dateAdapter.format(startDate, 'YYYY-MM-DD'))
      this._calendar._dateSelected({
        event: new Event('click'),
        value: startDate,
      });

    this._calendar._dateSelected({
      event: new Event('click'),
      value: this._dateAdapter.createDate(year, month, lastDay),
    });
  }
}
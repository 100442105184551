import { Component, Input, OnInit } from '@angular/core';
import { ISupplier } from 'src/app/models/supplier';
import { IRatingHistory } from 'src/app/models/ratingHistory';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ILineGraphItem } from 'src/app/models/listItem';
import { ApiService } from 'src/app/services/api/api.service';
import { UserService } from 'src/app/services/user/user.service';
import { max } from 'rxjs';

@Component({
  selector: 'app-rating-history-panel',
  templateUrl: './rating-history-panel.component.html',
  styleUrl: './rating-history-panel.component.scss',
  host: {
    class: 'table-container'
  },
  animations: [
    trigger('hidden', [
      state('false', style({
        height: '*',
        opacity: 1,
        'min-height': 0,
        "flex-expand": 1,
        overflow: "hidden",
      })),
      state('true', style({
        "flex-expand": 0,
        opacity: 0,
        height: "0px",
        'min-height': 0,
        overflow: "hidden",
      })),
      transition('false <=> true', [
        animate('0.2s ease-in-out')
      ]),
    ]),
  ]
})
export class RatingHistoryPanelComponent implements OnInit {

  constructor(
    private userService: UserService,
  ) { }

  currentQuarter = 0;
  ngOnInit(): void {
    this.currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
  }

  @Input() minRatingDate: Date | string;
  @Input() maxRatingDate: Date | string;

  @Input() isLoading = true;
  @Input() set data(data: IRatingHistory[]) {
    if (data) {
      this.loadRating(data);
    }
  }



  viewRatingGraph = this.userService.getSetting('ratingHistoryPanelComponent-viewRatingGraph', false) ? true : false;
  ratingTableData: IRatingHistory[] = [];
  ratingGraphData: ILineGraphItem[] = [];
  loadRating(data: IRatingHistory[]) {
    let currentYear: number =  new Date().getFullYear() - 1;

    if (this.maxRatingDate != null) {
      const dt = this.getDate(this.maxRatingDate);
      if (dt.getFullYear() <= currentYear) {
        currentYear = dt.getFullYear() - 1;
      }
    }

    this.ratingTableData = Array(2).fill({ year: currentYear, data: [] }).map((gi, i) => {
      const year = gi.year + i;
      const year_data = data.find((d) => d.year == year)?.details || [];
      const quarters: IRatingHistory['details'] = Array(4).fill({ quarter: 0, rating: 0 }).map((q, j) => {
        return {
          quarter: (j + 1),
          rating: year_data.find((d) => d.quarter == (j + 1))?.rating,
        }
      }); 
      return {
        year: year,
        details: quarters,
      };
    });
    
    this.ratingGraphData = [{
      name: 'Rating',
      series: this.ratingTableData.reduce((p, c) => {
        const year = c.year;
        p.push(...c.details.map((c) => {
          return {
            name: `${year}_Q${c.quarter}`,
            value: this.showRating(year, c.quarter) ? (c.rating != null ? c.rating : null) : 0.01,
          };
        }));
        return p;
      }, []),
    }];
  }

  private getDate(date: null | Date | string) {
    if (date) {
      if (typeof date == "string") return new Date(date);
      return date;
    }
    return null;
  }
  private showRating(year: number, quarter: number) {
    const minDate = this.getDate(this.minRatingDate);
    const maxDate = this.getDate(this.maxRatingDate);

    if (minDate && (minDate.getFullYear() > year || (
      minDate.getFullYear() == year && this.getDateQuarter(minDate) > quarter
    ))) {
      return false;
    }

    if (maxDate && (maxDate.getFullYear() < year || (
      maxDate.getFullYear() == year && this.getDateQuarter(maxDate) < quarter
    ))) {
      return false;
    }

    return true;
  }

  getDateQuarter(date: Date) {
    return Math.floor((date.getMonth() + 3) / 3);
  }

  removeDecimal(label: string) {
    return label.toString().split(".")[0];
  }
  getGraphLabel(label: string) {
    return label.split("_")[1];
  }

  setViewRatingGraph(value: boolean) {
    window.dispatchEvent(new Event('resize'));
    this.userService.saveSetting('ratingHistoryPanelComponent-viewRatingGraph', value ? "y" : "", false);
    this.viewRatingGraph = value;
  }

}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ViewChild, ElementRef, OnInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { IAction, IActionEvent, PageContainerComponent } from 'src/app/components';
import { IGraphItem } from 'src/app/models/listItem';
import { ApiService } from 'src/app/services/api/api.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('panelState', [
      state('open', style({
        height: '*',
        "flex-grow": '*',
        opacity: 1,
      })),
      state('closed', style({
        "flex-grow": 0,
        height: "0px",
      })),
      transition('open <=> closed', [
        animate('0.2s ease-in-out')
      ]),
    ])
  ],
})
export class DashboardComponent implements OnInit, OnDestroy {

  panelToggleMenuTrigger: HTMLElement;
  showLegend = true;
  autoUpdate = false;

  colorsType: { [key: string]: string } = {
    "IntraGroup Hubs": "#ff6200",
    "Third Parties - Global (SSI)": "#ffc000",
    "Third Parties - Local": "#00b050",
    "NSE": "#a6a6a6",
    "HO": "#2f5597",
  }
  legend = Object.keys(this.colorsType).map((key) => ({ name: key, value: this.colorsType[key] }));
  graphs: {
    [ key in 'q1' | 'q2' | 'q3' | 'q4' | 'q5' ]: { dataset: IGraphItem[], colors: IGraphItem[], total?: number, isVisible?: boolean, showPieChart: boolean, labelFormatting?: (label: string) => string, highlight?: boolean }
  } = {
    q1: { dataset: [], colors: [], showPieChart: false, total: 0, isVisible: true, },
    q2: { dataset: [], colors: [], showPieChart: false, isVisible: true },
    q3: { dataset: [], colors: [], showPieChart: false, total: 0, isVisible: true },
    q4: { dataset: [], colors: [], showPieChart: false, isVisible: true},
    q5: { dataset: [], colors: [], showPieChart: false, isVisible: true },
  }

  @ViewChild('pageContainer') pageContainer: PageContainerComponent;

  constructor(
    private api: ApiService,
    private userService: UserService,
  ) {}

  intervalID: NodeJS.Timeout;
  ngOnInit(): void {
    const s = this.userService.getSetting(`dashboardPage-settings`, false);
    if (s) {
      this.graphs = Object.assign(this.graphs, JSON.parse(s));
    }

    this.intervalID = setInterval(() => {
      this.updateTick()
    }, 60000 * 10 ); // each 10 minutes

    this.loadData();
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalID);
  }

  private loadData() {
    this.api.getTotalTechSupplier().subscribe((res) => {
      //res = [{"name":"Third Parties - Local","value":177},{"name":"NSE","value":100},{"name":"Third Parties - Global (SSI)","value":40},{"name":"IntraGroup Hubs","value":20}].sort((a, b) => b.value - a.value);
      this.graphs.q1.dataset = res.map(r => {
        r['series'] = r.name;
        //r.name += ` [${r.value}]`;
        return r;
      });
      this.graphs.q1.total = res.reduce((p, c) => p + c.value, 0);
      this.graphs.q1.colors = this.barCustomColors(res, this.colorsType, null, 'series');
      this.graphs.q1.labelFormatting = this.pieChartLabelFormatting.bind(this, res.reduce((p, c) => {
        p[c.name] = c.value;
        return p;
      }, {} as any))
    })
    this.api.getTopSupplier().subscribe((res) => {
      //res = [{"name":"Yellow","value":50,"type":"Third Parties - Local"},{"name":"Brown","value":49,"type":"Third Parties - Local"},{"name":"Green","value":25,"type":"Third Parties - Local"},{"name":"Red","value":24,"type":"Third Parties - Local"},{"name":"Purple","value":21,"type":"NSE"}] as any;
      this.graphs.q2.dataset = res;
      this.graphs.q2.colors = this.barCustomColors(res, this.colorsType, 'white', 'type');
    })
    this.api.getOutsourcingTechSupplier().subscribe((res) => {
      //res = [{"name":"Third Parties - Local","value":12},{"name":"HO","value":10},{"name":"IntraGroup Hubs","value":5}];
      this.graphs.q3.dataset = res;
      this.graphs.q3.total = res.reduce((p, c) => p + c.value, 0);
      this.graphs.q3.colors = this.barCustomColors(res, this.colorsType);
      this.graphs.q3.labelFormatting = this.pieChartLabelFormatting.bind(this, res.reduce((p, c) => {
        p[c.name] = c.value;
        return p;
      }, {} as any))
    })
    this.api.getHubsSupplier().subscribe((res) => {
      //res = [{"name":"Blue","value":20},{"name":"Pink","value":15}];
      this.graphs.q4.dataset = res;
      this.graphs.q4.colors = this.barCustomColors(res, {}, this.colorsType["IntraGroup Hubs"]);
    })
    this.api.getLocalSupplier().subscribe((res) => {
      //res = Array(15).fill('').map((o, i) => ({name: 'Company ' + i, value: 100 - i}));
      this.graphs.q5.dataset = res;
      this.graphs.q5.colors = this.barCustomColors(res, {}, this.colorsType["Third Parties - Local"]);
    })
  }

  autoUpdateAction: IAction = { key: 'autoUpdate', icon: 'autoUpdate.svg', name: 'dashboard.actions.autoUpdate', activeIcon: 'stopAutoUpdate.svg', activeName: 'dashboard.actions.disableAutoUpdate' };
  ngAfterViewInit() {
    window.dispatchEvent(new Event('resize'));

    const au = this.userService.getSetting(`dashboardPage-autoUpdate`, false);
    if (au) {
      this.autoUpdate = true;
      this.pageContainer.toggleAction(this.autoUpdateAction);
    }

  }

  onActionClicked(action: IActionEvent) {
    switch (action.actionKey) {
      case 'toggleLegend': 
        this.showLegend = !action.status;
        window.dispatchEvent(new Event('resize'));
        break;
      case 'togglePanel': 
        this.showLegend = !action.status;
        window.dispatchEvent(new Event('resize'));
        break;
      case 'autoUpdate': 
        this.autoUpdate = action.status;
        if (this.autoUpdate) {
          this.userService.saveSetting(`dashboardPage-autoUpdate`, "true", false);
          this.updateTick();
        } else {
          this.userService.deleteSetting(`dashboardPage-autoUpdate`, false);
        }
        break;
    }
  }

  pieChartLabelFormatting(data: {[key: string]: number}, label: string) {
    return `${label} (${data[label]})`;
  }

  barCustomColors<T extends IGraphItem>(dataset: T[], colors = this.colorsType, defaultColor = '#000', colorKeyProp = 'name' as keyof T) {
    let result: any[] = [];
    for (let i = 0; i < dataset.length; i++) {
      const dt = dataset[i];
      if (dt.value < 200) {
        result.push({name: dt.name, value: colors[dt[colorKeyProp].toString()] || defaultColor });
      }
    }
    return result;
  }

  togglePanel(panel: keyof typeof this.graphs, value?: boolean) {
    this.graphs[panel].isVisible = value !== undefined ? value : !this.graphs[panel].isVisible;

    this.userService.saveSetting(`dashboardPage-settings`, JSON.stringify({
      q1: { isVisible: this.graphs.q1.isVisible, showPieChart: this.graphs.q1.showPieChart },
      q2: { isVisible: this.graphs.q2.isVisible, showPieChart: this.graphs.q2.showPieChart },
      q3: { isVisible: this.graphs.q3.isVisible, showPieChart: this.graphs.q3.showPieChart },
      q4: { isVisible: this.graphs.q4.isVisible, showPieChart: this.graphs.q4.showPieChart },
      q5: { isVisible: this.graphs.q5.isVisible, showPieChart: this.graphs.q5.showPieChart },
    }), false);
    window.dispatchEvent(new Event('resize'));
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }
  toggleHighlight(panel: keyof typeof this.graphs, value?: boolean) {
    this.graphs[panel].highlight = value !== undefined ? value : !this.graphs[panel].highlight;
  }

  private updateTick() {
    if (this.autoUpdate) {
      this.loadData();
    }
  }
}
